let timelineElements = [
  {
    id: 1,
    title: "Dicovery",
    location: "Requirement Gathering",
    description:
      "We begin by understanding your specific business needs and goals. This includes identifying the desired features, functionality, and target audience for your centralized crypto exchange.",
    buttonText: "View Frontend Projects",
    date: "August 2016 - present",
    icon: "work",
    count: "01",
  },
  {
    id: 2,
    title: "Backend Developer",
    location: "Architecture Design Process",
    description:
    "Our team creates a comprehensive architecture design that outlines the system's components, modules, and their interactions. This includes database design, security measures, and integration with external systems, if applicable.     ",
    date: "June 2013 - August 2016",
    icon: "school",
    count: "02",
  },
  {
    id: 3,
    title: "Development Phase",
    location: "Development Phase",
    description:
    "Using cutting-edge technologies and coding best practices, we develop the centralized crypto exchange platform. This involves frontend and backend development, implementing features.",    buttonText: "Company Website",
    date: "September 2011 - June 2013",
    icon: "development",
    count: "03",
  },
  {
    id: 4,
    title: "Oak Ridge College",
    location: "Security Implementation",
    description:
    "We incorporate robust security measures such as encryption, two-factor authentication, and regular security audits to ensure the safety of user funds and data.    ",    buttonText: "Course Certificate",
    date: "September 2011",
    icon: "security",
    count: "04",
  },
  {
    id: 5,
    title: "Hawking College",
    location: "Testing and Quality Assurance",
    description:
    "Rigorous testing is conducted to identify and fix any bugs or performance issues. This includes functional testing, load testing, and security testing to ensure the platform performs seamlessly and securely under various conditions.    ",    buttonText: "College Projects",
    date: "2007 - 2011",
    icon: "testing",
    count: "05",
  },
  {
    id: 6,
    title: "Hawking College",
    location: "Deployment and Launch",
    description:
    "Once the development and testing phases are completed, the centralized crypto exchange is deployed to a production environment. We assist with the setup of servers, configuring necessary integrations, and ensuring a smooth launch.    ",    buttonText: "College Projects",
    date: "2007 - 2011",
    icon: "launch",
    count: "06",
  },
  {
    id: 7,
    title: "Hawking College",
    location: "Ongoing Support and Maintenance",
    description:
    "We provide continuous support and maintenance services to ensure the platform runs smoothly post-launch. This includes monitoring system performance, addressing any technical issues, and implementing updates or enhancements as needed.    ",    buttonText: "College Projects",
    date: "2007 - 2011",
    icon: "maintainance",
    count: "07",
  },
];

export default timelineElements;
