import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import FillForm from "../Components/FillForm";
import Contactbar from "../Components/Contactbar";


import "../Pages/Style.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";
import TechStackSlider from "../Components/TechStackSlider"; // Import the new component

export default function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [isScrolled]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isScrolled]);

  return (
    <>
      <Helmet>
        <title>Web3 development</title>
        <meta property="title" content="Rarible clone script" />
        <meta
          name="description"
          content="Block Intelligence is a premier NFT Marketplace development company that helps you launch your NFT Marketplace like Rarible with Rarible clone script."
        />
        <meta
          name="keywords"
          content="p2p crypto exchange development company,  p2p cryptocurrency exchange development company, p2p crypto exchange development, p2p crypto exchange software development, p2p exchange development, p2p cryptocurrency exchange development, p2p exchange development company, p2p cryptocurrency exchange software, crypto p2p exchange application development, p2p crypto exchange development solutions, p2p crypto exchange development services"
        />
        <link
          rel="canonical"
          href="https://www.blockintelligence.io/rarible-clone-script"
        />
      </Helmet>
      <Header />
      <Contactbar />
      <div className="rarible_head">
        <section className="p2p rarible">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 banner_con simple-video mb-sx-2">
                <h1 className="wow fadeInUp text-black h1tag">
                  <>Web3 Development Solutions</>
                </h1>
                <p className="wow fadeInUp text-black mt-4">
                  Embrace the future of the internet with our Web3 development services. We provide comprehensive solutions to help you build decentralized applications and platforms that empower users and enhance security.
                </p>
                <div className="p2pbt mt-5">
                  <a href="#contact" className="button">
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whatsmartcontract  wahtrarible py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-5 mx-auto d-flex justify-content-center align-items-center">
                <div className="rotateimg">
                  <img
                    src={require("../Images/openseaa5.webp")}
                    width="95%"
                    className="mx-auto d-block"
                    alt="p2p crypto exchange development company"
                  />
                </div>
              </div>
              <div className="col-lg-7 ">
                <div className="p2pcontent">
                  <h2 className="text-black">
                    What is <span>Web3 Development</span> Solutions?{" "}
                  </h2>
                  <p className="text-black">
                    Web3 development encompasses the creation of decentralized applications and platforms that leverage blockchain technology. With Web3 solutions, users can interact with decentralized networks, ensuring enhanced security, transparency, and user empowerment. This development approach includes features for smart contract integration, decentralized storage, and user-friendly interfaces that facilitate seamless interactions within the decentralized ecosystem.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whatsmartcontract wahtrarible py-5">
          <div className="container py-5">
            <div className="row mb-5">
              <div className="col-lg-12 ">
                <div className="p2pcontent text-center">
                  <h2 className="text-black">Our Web3 Development Process</h2> {/* Updated title */}
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-sm-3 sixnone p-0">
                <img
                  src={require("../Images/NFT/1.webp")}
                  width="100%"
                  className="mx-auto d-block"
                  alt="web3 development process"
                />
              </div>
              <div className="col-sm-9 rightradius  border-right border-top p-5">
                <h5>
                  <span className="h3"> Planning </span>
                </h5>
                <h5>
                  We identify the decentralized application goals and outline the steps to achieve them. {/* Updated content */}
                </h5>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-9 leftradius border-left border-top p-5">
                <h5 className="mt-4">
                  <span className="h3"> Design </span>
                </h5>
                <h5>
                  We create a user-centric design for our Web3 application, focusing on usability and functionality. {/* Updated content */}
                </h5>
              </div>
              <div className="col-sm-3 sixnone p-0">
                <img
                  src={require("../Images/NFT/2&4.webp")}
                  width="95%"
                  className="mx-auto d-block"
                  alt="web3 design process"
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-3 sixnone p-0">
                <img
                  src={require("../Images/NFT/3&5.webp")}
                  width="100%"
                  className="mx-auto d-block"
                  alt="web3 development"
                />
              </div>
              <div className="col-sm-9 p-5 rightradius border-right border-top">
                <h5>
                  <span className="h3"> Development </span>
                </h5>
                <h5>
                  Our team builds the decentralized application, implementing smart contracts and necessary components. {/* Updated content */}
                </h5>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-9 p-5 leftradius border-left border-top">
                <h5 className="mt-4">
                  <span className="h3 "> Testing </span>
                </h5>
                <h5>
                  We rigorously test the project to find and fix any issues or bugs. {/* Updated content */}
                </h5>
              </div>
              <div className="col-sm-3 sixnone p-0">
                <img
                  src={require("../Images/NFT/2&4.webp")}
                  width="95%"
                  className="mx-auto d-block"
                  alt="web3 testing process"
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-3 sixnone p-0">
                <img
                  src={require("../Images/NFT/3&5.webp")}
                  width="100%"
                  className="mx-auto d-block"
                  alt="web3 deployment"
                />
              </div>
              <div className="col-sm-9 p-5 rightradius border-right border-top">
                <h5 className="mt-4">
                  <span className="h3 mt-4"> Deployment </span>
                </h5>
                <h5>
                  Once satisfied, we deploy the project to the public or intended audience. {/* Updated content */}
                </h5>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-9 p-5 border-top leftradius border-bottom border-left">
                <h5>
                  <span className="h3"> Maintenance </span>
                </h5>
                <h5>
                  We continue to monitor and update the project to ensure it stays functional and up-to-date. {/* Updated content */}
                </h5>
              </div>
              <div className="col-sm-3 sixnone p-0">
                <img
                  src={require("../Images/NFT/6.webp")}
                  width="100%"
                  className="mx-auto d-block"
                  alt="web3 maintenance process"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract wahtrarible py-5">
          <div className="container py-5">
            <div className="p2pcontent">
              <h2 className="mb-3 text-black">
                Why Choose Block Intelligence for <span>Web3 Development</span>{" "}
                Solutions?{" "}
              </h2>
              <p className="text-black">
                Block Intelligence is a leading choice for Web3 development solutions. We specialize in building decentralized applications that harness the power of blockchain technology, ensuring enhanced security and user empowerment. Our team is dedicated to delivering user-friendly features, making our solutions accessible and easy to navigate for users of all technical backgrounds. With a seamless and intuitive interface, we guarantee a smooth experience for both developers and end-users.
              </p>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract wahtrarible py-5">
          <div className="container py-5">
            <div className="p2pcontent">
              <h2 className="mb-5 text-black text-center ">
                Advantages of <span>Web3 Development</span> Solutions
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-7 ">
                <div className="p2pcontent">
                  <p className="d-flex text-black">
                    <img
                      src={require("../Images/nfticon.webp")}
                      width="30px"
                      className="signpen"
                      alt="web3 development advantages"
                    />{" "}
                    <div>
                      <span className="h5">Effortless DApp Creation</span> The
                      process of building decentralized applications is simplified, allowing developers to create unique and functional DApps with ease.
                    </div>
                  </p>
                  <p className="d-flex text-black">
                    <img
                      src={require("../Images/nfticon.webp")}
                      width="30px"
                      className="signpen"
                      alt="web3 development advantages"
                    />{" "}
                    <div>
                      <span className="h5">Decentralization and Security</span>{" "}
                      Enjoy the benefits of a decentralized platform while
                      ensuring the highest level of security for transactions
                      and user data.
                    </div>
                  </p>
                  <p className="d-flex text-black">
                    <img
                      src={require("../Images/nfticon.webp")}
                      width="30px"
                      className="signpen"
                      alt="web3 development advantages"
                    />{" "}
                    <div>
                      <span className="h5">Customization</span> Develop the
                      platform to your brand and vision, creating a unique user
                      interface that enhances the overall experience for
                      creators and collectors.
                    </div>
                  </p>

                  <p className="d-flex text-black">
                    <img
                      src={require("../Images/nfticon.webp")}
                      width="30px"
                      className="signpen"
                      alt="web3 development advantages"
                    />{" "}
                    <div>
                      <span className="h5">Community Building</span> Enabling a
                      thriving community around your platform, encouraging
                      interaction and engagement among artists, collectors, and
                      enthusiasts.
                    </div>
                  </p>
                  <p className="d-flex text-black">
                    <img
                      src={require("../Images/nfticon.webp")}
                      width="30px"
                      className="signpen"
                      alt="web3 development advantages"
                    />{" "}
                    <div>
                      <span className="h5">Smart Contract Integration</span>{" "}
                      Rarible clone script comes with smart contract
                      capabilities, automating and securing transactions on the
                      blockchain.
                    </div>
                  </p>

                  <p className="d-flex text-black">
                    <img
                      src={require("../Images/nfticon.webp")}
                      width="30px"
                      className="signpen"
                      alt="web3 development advantages"
                    />{" "}
                    <div>
                      <span className="h5">Cost-Effectiveness</span> Save time
                      and resources by opting for a clone script rather than
                      starting from scratch, ensuring a quicker entry into the
                      NFT marketplace.
                    </div>
                  </p>
                </div>
              </div>

              <div className="col-lg-5 mt-5">
                <div className="rotateimg">
                  <img
                    src={require("../Images/raribleclone.webp")}
                    width="95%"
                    className="mx-auto rounded d-block"
                    alt="p2p crypto exchange development company"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

       
        <section className="whatsmartcontract wahtrarible">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="p2pcontent text-center">
                  <h2 className="text-black">Takeaway</h2>
                  <p className="text-center text-black my-4">
                    Web3 development is revolutionizing the way we interact with the internet. By leveraging decentralized technologies, it empowers users with greater control over their data and digital assets. Whether you are looking to build decentralized applications or explore blockchain solutions, embracing Web3 development ensures a future-proof approach that prioritizes security, transparency, and user empowerment. Block Intelligence is committed to guiding you through this transformative journey, making it easier than ever to harness the potential of Web3.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

      

        <section className="techstack2 stacz">
          <div className="container">
            <h2 className="tech-h2">Blockchain Tech Stacks</h2>
            <div className="stack">
              <p>
                Our team of blockchain developers are experts at building
                innovative blockchain solutions on the best available blockchain
                platform.
              </p>
            </div>
            <TechStackSlider /> 
          </div>
        </section>
      </div>

      <FillForm />

      <Footer />
    </>
  );
}
