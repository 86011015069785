import React from "react";
import "../Pages/Style.css";
import { ReactComponent as WorkIcon } from "../Images/requirement.svg";
import { ReactComponent as SchoolIcon } from "../Images/design.svg";
import { ReactComponent as DevelopmentIcon } from "../Images/build.svg";
import { ReactComponent as SecurityIcon } from "../Images/security.svg";
import { ReactComponent as TestingIcon } from "../Images/test.svg";
import { ReactComponent as LaunchIcon } from "../Images/launch.svg";
import { ReactComponent as MaintenanceIcon } from "../Images/maintainance.svg";
import timelineElements from "./gametimeline";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

function Contact() {
  let workIconStyles = { background: "#06D6A0" };
  let schoolIconStyles = { background: "#f9c74f" };

  return (
    <div className="flow-body1">
        <VerticalTimeline>
          {timelineElements.map((element) => {
            let iconComponent;

            switch (element.icon) {
              case "work":
                iconComponent = <WorkIcon />;
                break;
              case "school":
                iconComponent = <SchoolIcon />;
                break;
              case "development":
                iconComponent = <DevelopmentIcon />;
                break;
              case "security":
                iconComponent = <SecurityIcon />;
                break;
              case "testing":
                iconComponent = <TestingIcon />;
                break;
              case "launch":
                iconComponent = <LaunchIcon />;
                break;
              default:
                iconComponent = null;
            }

            return (
              <VerticalTimelineElement
                key={element.id}
                iconStyle={
                  element.icon === "work" ? workIconStyles : schoolIconStyles
                }
                icon={iconComponent}
              >
                <div className="card-time">
                  <div>
                    <h5 className="vertical-timeline-element-subtitle mb-3">
                      {element.location}
                    </h5>
                    <h6 id="description">{element.description}</h6>
                  </div>
                  <div>
                    <h3 className="vertical-timeline-element-title">
                      {element.count}
                    </h3>
                  </div>
                </div>
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline>
    </div>
  );
}

export default Contact;
