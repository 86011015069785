import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import FillForm from "../Components/FillForm";
import TechStackSlider from '../Components/TechStackSlider';
import Contactbar from "../Components/Contactbar";

import "../Pages/Style.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";

export default function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [isScrolled]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isScrolled]);

  return (
    <>
      <Helmet>
        <title>Rarible clone script</title>
        <meta property="title" content="Rarible clone script" />
        <meta
          name="description"
          content="Block Intelligence is a premier NFT Marketplace development company that helps you launch your NFT Marketplace like Rarible with Rarible clone script."
        />
        <meta
          name="keywords"
          content="p2p crypto exchange development company,  p2p cryptocurrency exchange development company, p2p crypto exchange development, p2p crypto exchange software development, p2p exchange development, p2p cryptocurrency exchange development, p2p exchange development company, p2p cryptocurrency exchange software, crypto p2p exchange application development, p2p crypto exchange development solutions, p2p crypto exchange development services"
        />
        <link
          rel="canonical"
          href="https://www.blockintelligence.io/rarible-clone-script"
        />
      </Helmet>
      <Header />
      <Contactbar />
      <div className="rarible_head">
        <section className="p2p rarible">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 banner_con simple-video mb-sx-2">
                <h1 className="wow fadeInUp text-black h1tag">
                  <>Rarible Clone Script</>
                </h1>
                <p className="wow fadeInUp text-black mt-4">
                  Create, sell, and collect digital art on the blockchain with
                  Block Intelligence. We offer Rarible clone script development
                  to help you launch your own NFT marketplace like Rarible in no
                  time. Join the revolution of decentralized creativity and
                  innovation
                </p>
                <div className="p2pbt mt-5">
                  <a href="#contact" className="button">
                    Get Demo
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whatsmartcontract  wahtrarible py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-5 mx-auto d-flex justify-content-center align-items-center">
                <div className="rotateimg">
                  <img
                    src={require("../Images/openseaa5.webp")}
                    width="95%"
                    className="mx-auto d-block"
                    alt="p2p crypto exchange development company"
                  />
                </div>
              </div>
              <div className="col-lg-7 ">
                <div className="p2pcontent">
                  <h2 className="text-black">
                    What is <span>Rarible Clone</span> Script?{" "}
                  </h2>
                  <p className="text-black">
                    The Rarible clone script is a powerful tool that replicates
                    the innovative features of the Rarible NFT marketplace. With
                    a Rarible Clone Script, users can mint, list, and trade NFTs
                    on their platform. Minting refers to the process of creating
                    a unique digital asset, and listing involves putting it up
                    for sale or auction. The script also typically includes
                    features for managing user accounts, handling transactions,
                    and showcasing NFT collections.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whatsmartcontract wahtrarible py-5">
          <div className="container py-5">
            <div className="row mb-5">
              <div className="col-lg-12 ">
                <div className="p2pcontent text-center">
                  <h2 className="text-black">Our Development Process</h2>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-sm-3 sixnone p-0">
                <img
                  src={require("../Images/NFT/1.webp")}
                  width="100%"
                  className="mx-auto d-block"
                  alt="p2p crypto exchange development company"
                />
              </div>
              <div className="col-sm-9 rightradius  border-right border-top p-5">
                <h5>
                  {" "}
                  <span className="h3"> Planning </span>
                </h5>
                <h5>
                  First, we decide what we want to create and outline the steps
                  to achieve it.
                </h5>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-9 leftradius border-left border-top p-5">
                <h5 className="mt-4">
                  {" "}
                  <span className="h3"> Design </span>
                </h5>
                <h5>
                  We create an advanced design for our project, deciding how it
                  will look and function.
                </h5>
              </div>
              <div className="col-sm-3 sixnone p-0">
                <img
                  src={require("../Images/NFT/2&4.webp")}
                  width="95%"
                  className="mx-auto d-block"
                  alt="p2p crypto exchange development company"
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-3 sixnone p-0">
                <img
                  src={require("../Images/NFT/3&5.webp")}
                  width="100%"
                  className="mx-auto d-block"
                  alt="p2p crypto exchange development company"
                />
              </div>
              <div className="col-sm-9 p-5 rightradius border-right border-top">
                <h5>
                  {" "}
                  <span className="h3"> Development </span>
                </h5>
                <h5>
                  Our team starts building the project, writing the code and
                  creating the necessary components.
                </h5>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-9 p-5 leftradius border-left border-top">
                <h5 className="mt-4">
                  {" "}
                  <span className="h3 "> Testing </span>
                </h5>
                <h5>
                  {" "}
                  We thoroughly test the project to find and fix any issues or
                  bugs.
                </h5>
              </div>
              <div className="col-sm-3 sixnone p-0">
                <img
                  src={require("../Images/NFT/2&4.webp")}
                  width="95%"
                  className="mx-auto d-block"
                  alt="p2p crypto exchange development company"
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-3 sixnone p-0">
                <img
                  src={require("../Images/NFT/3&5.webp")}
                  width="100%"
                  className="mx-auto d-block"
                  alt="p2p crypto exchange development company"
                />
              </div>
              <div className="col-sm-9 p-5 rightradius border-right border-top">
                <h5 className="mt-4">
                  {" "}
                  <span className="h3 mt-4"> Deployment </span>
                </h5>
                <h5>
                  Once satisfied, we deploy the project to the public or
                  intended audience.
                </h5>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-9 p-5 border-top leftradius border-bottom border-left">
                <h5>
                  {" "}
                  <span className="h3"> Maintenance </span>
                </h5>
                <h5>
                  We continue to monitor and update the project to ensure it
                  stays functional and up-to-date.
                </h5>
              </div>
              <div className="col-sm-3 sixnone p-0">
                <img
                  src={require("../Images/NFT/6.webp")}
                  width="100%"
                  className="mx-auto d-block"
                  alt="p2p crypto exchange development company"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract wahtrarible py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-7 ">
                <div className="p2pcontent">
                  <h2 className="mb-3 text-black">
                    Why Choose Block Intelligence for <span>Rarible Clone</span>{" "}
                    Script?{" "}
                  </h2>
                  <p className="text-black">
                    Block Intelligence stands out as a premier choice for
                    Rarible clone script development. It stays ahead of the
                    competition in rarible clone script development for several
                    compelling reasons. First and foremost, Block Intelligence
                    prioritizes user-friendly features, making the script easily
                    accessible and navigable for users of all levels of tech
                    proficiency. The platform has a seamless and intuitive
                    interface, ensuring a smooth experience for both creators
                    and buyers
                  </p>
                </div>
              </div>

              <div className="col-lg-5 ">
                <div className="rotateimg">
                  <img
                    src={require("../Images/opensea7.webp")}
                    className="mx-auto d-block"
                    width="95%"
                    alt="p2p crypto exchange development company"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract wahtrarible py-5">
          <div className="container py-5">
            <div className="p2pcontent">
              <h2 className="mb-5 text-black text-center ">
                Advantages of <span>Rarible Clone</span> Script
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-7 ">
                <div className="p2pcontent">
                  <p className="d-flex text-black">
                    <img
                      src={require("../Images/nfticon.webp")}
                      width="30px"
                      className="signpen"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    <div>
                      <span className="h5"> Effortless Token Creation</span> The
                      script simplifies the process of minting tokens, allowing
                      artists to effortlessly transform their digital creations
                      into unique and tradable assets.
                    </div>
                  </p>
                  <p className="d-flex text-black">
                    <img
                      src={require("../Images/nfticon.webp")}
                      width="30px"
                      className="signpen"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    <div>
                      <span className="h5">Decentralization and Security</span>{" "}
                      Enjoy the benefits of a decentralized platform while
                      ensuring the highest level of security for transactions
                      and user data.
                    </div>
                  </p>
                  <p className="d-flex text-black">
                    <img
                      src={require("../Images/nfticon.webp")}
                      width="30px"
                      className="signpen"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    <div>
                      <span className="h5">Customization</span> Develop the
                      platform to your brand and vision, creating a unique user
                      interface that enhances the overall experience for
                      creators and collectors.
                    </div>
                  </p>

                  <p className="d-flex text-black">
                    <img
                      src={require("../Images/nfticon.webp")}
                      width="30px"
                      className="signpen"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    <div>
                      <span className="h5">Community Building</span> Enabling a
                      thriving community around your platform, encouraging
                      interaction and engagement among artists, collectors, and
                      enthusiasts.
                    </div>
                  </p>
                  <p className="d-flex text-black">
                    <img
                      src={require("../Images/nfticon.webp")}
                      width="30px"
                      className="signpen"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    <div>
                      <span className="h5">Smart Contract Integration</span>{" "}
                      Rarible clone script comes with smart contract
                      capabilities, automating and securing transactions on the
                      blockchain.
                    </div>
                  </p>

                  <p className="d-flex text-black">
                    <img
                      src={require("../Images/nfticon.webp")}
                      width="30px"
                      className="signpen"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    <div>
                      <span className="h5">Cost-Effectiveness</span> Save time
                      and resources by opting for a clone script rather than
                      starting from scratch, ensuring a quicker entry into the
                      NFT marketplace.
                    </div>
                  </p>
                </div>
              </div>

              <div className="col-lg-5 mt-5">
                <div className="rotateimg">
                  <img
                    src={require("../Images/raribleclone.webp")}
                    width="95%"
                    className="mx-auto rounded d-block"
                    alt="p2p crypto exchange development company"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract wahtrarible py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-12">
                <div className="p2pcontent">
                  <h2 className="text-center text-black mb-5">
                    Script Development <span>VS</span> Custom Development
                  </h2>
                </div>
              </div>
            </div>

            <div className="row justify-content-center vsrariblehead">
              <div className="col-md-5 vsscript rariscript p-0 px-4  py-5">
                <h5 className="pt-5 text-black">
                  Choosing between a script and custom development hinges on
                  factors like budget, time constraints, and specific
                  requirements. A Rarible clone script offers a quicker, more
                  cost-effective solution, providing essential features for a
                  decentralized NFT marketplace. If you're looking for a rapid
                  entry into the market with proven features, a script is the
                  way to go.{" "}
                </h5>
              </div>
              <div className="col-md-2 vsimage p-0">
                <img src={require("../Images/rariblevs.webp")} width="100%" />
              </div>

              <div className="col-md-5 raricustom py-5 p-0 px-4">
                <h5 className="pt-5 text-black">
                  Custom development provides unparalleled flexibility, allowing
                  you to develop every aspect of your platform. For those with
                  unique ideas or specific functionalities in mind, custom
                  development might be the better route, Besides with a
                  potentially longer timeline and higher costs.
                </h5>
              </div>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract wahtrarible">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="p2pcontent text-center">
                  <h2 className="text-black">Takeaway</h2>
                  <p className="text-center text-black my-4">
                    The Rarible clone script is a game-changer for entrepreneurs
                    venturing into the NFT space. Whether you opt for a script
                    or custom development, the key lies in using the script's
                    benefits for a quicker market entry or utilizing custom
                    development for a development, standout platform. Overall,
                    Block Intelligence Rarible Clone Script excels in
                    simplicity, security, and scalability, making it a top
                    choice for those seeking a reliable and user-friendly
                    platform to enter the world of decentralized digital asset
                    creation and trading
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="rariblrfaq">
          <div id="main">
            <div class="container">
              <div className="faq-content">
                <p className="p-mobile">Have a Question ?</p>
                <h3 className="h2-mobile h2-md">Frequently Asked Questions</h3>
              </div>
              <div class="accordion" id="faq">
                <div class="card">
                  <div class="card-header" id="faqhead1">
                    <a
                      href="#"
                      class="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq1"
                      aria-expanded="true"
                      aria-controls="faq1"
                    >
                      Is the script customizable?
                    </a>
                  </div>

                  <div
                    id="faq1"
                    class="collapse"
                    aria-labelledby="faqhead1"
                    data-parent="#faq"
                  >
                    <div class="card-body p-md">
                      Absolutely! Our Rarible clone script is designed to be
                      easily customizable to suit your brand and business
                      requirements.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead2">
                    <a
                      href="#"
                      class="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq2"
                      aria-expanded="true"
                      aria-controls="faq2"
                    >
                      What blockchain does it support?
                    </a>
                  </div>

                  <div
                    id="faq2"
                    class="collapse"
                    aria-labelledby="faqhead2"
                    data-parent="#faq"
                  >
                    <div class="card-body p-md">
                      {" "}
                      Our script is blockchain-agnostic, supporting various
                      blockchains like Ethereum, Binance Smart Chain, and more.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead3">
                    <a
                      href="#"
                      class="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq3"
                      aria-expanded="true"
                      aria-controls="faq3"
                    >
                      How secure are transactions on the platform?
                    </a>
                  </div>

                  <div
                    id="faq3"
                    class="collapse"
                    aria-labelledby="faqhead3"
                    data-parent="#faq"
                  >
                    <div class="card-body p-md">
                      {" "}
                      Security is our priority. The script integrates robust
                      encryption and decentralized technologies to ensure secure
                      transactions.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead3">
                    <a
                      href="#"
                      class="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq3"
                      aria-expanded="true"
                      aria-controls="faq3"
                    >
                      {" "}
                      Can I add additional features later?
                    </a>
                  </div>

                  <div
                    id="faq3"
                    class="collapse"
                    aria-labelledby="faqhead3"
                    data-parent="#faq"
                  >
                    <div class="card-body p-md">
                      {" "}
                      Yes, the script is flexible. You can integrate additional
                      features as your platform grows.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="faqhead3">
                    <a
                      href="#"
                      class="btn btn-header-link collapsed"
                      data-toggle="collapse"
                      data-target="#faq3"
                      aria-expanded="true"
                      aria-controls="faq3"
                    >
                      {" "}
                      How long does it take to launch a platform with your
                      script?
                    </a>
                  </div>

                  <div
                    id="faq3"
                    class="collapse"
                    aria-labelledby="faqhead3"
                    data-parent="#faq"
                  >
                    <div class="card-body p-md">
                      {" "}
                      The timeline varies but generally takes less time compared
                      to custom development, ensuring a quicker market entry.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="techstack2 stacz">
          <div className="container">
            <h2 className="tech-h2">Blockchain Tech Stacks</h2>
            <div className="stack">
              <p>
                Our team of blockchain developers are experts at building
                innovative blockchain solutions on the best available blockchain
                platform.
              </p>
            </div>
            <TechStackSlider />
          </div>
        </section>
      </div>

      <FillForm />

      <Footer />
    </>
  );
}
