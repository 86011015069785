import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import FillForm from "../Components/FillForm";
import Contactbar from "../Components/Contactbar";
import "../Pages/Style.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";

export default function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [isScrolled]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isScrolled]);

  return (
    <>
      <Helmet>
        <title>Decentralized Exchange (DEX) Development Company</title>
        <meta
          property="title"
          content="Decentralized Exchange (DEX) Development Company"
        />

        <meta
          property="og:description"
          content="Block intelligence is a leading DEX development company offering the best decentralized exchange development service with high security and advanced features."
        />

        <meta
          property="og:title"
          content="Decentralized Exchange DEX Development Company"
        />
        <meta
          property="og:url"
          content="https://www.blockintelligence.io/dex-development-company"
        />
        <meta
          property="og:site_name"
          content="Decentralized Exchange Development Company"
        />

        <meta
          name="description"
          content="We are experts in Decentralized Exchange (DEX) platform development with secure solutions, and also offer advanced derivative and spot trading features."
        />
        <meta
          name="keywords"
          content="DEX development, decentralized exchange development, decentralized exchange platform development, decentralized exchange development company, decentralized exchange platform development, dex development, dex development company, dex development services"
        />
        <link
          rel="canonical"
          href="https://www.blockintelligence.io/dex-development-company"
        />
      </Helmet>
      <Header />
      <Contactbar />
      <div className="dex_head">
        <section className="dex">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <h1 className="wow fadeInUp h1tag">Blockchain Marketing</h1>

                <p className="wow fadeInUp mt-4">
                  Elevate your brand with our innovative blockchain marketing
                  strategies. As a leading provider in the industry, we aim to
                  enhance your visibility, engagement, and growth in the
                  blockchain space.
                </p>
                <div className="mt-4">
                  <a href="#contact" className="button">
                    Get Demo
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sec2dex">
          <div className="container dex2">
            <h2 className="text-center">What is Blockchain Marketing?</h2>

            <div className="row">
              <div className="p2pcontent col-lg-7">
                <p className="mt-5">
                  Blockchain marketing refers to the strategies and techniques
                  used to promote products, services, or brands within the
                  blockchain and cryptocurrency space. It leverages the unique
                  characteristics of blockchain technology, such as
                  decentralization, transparency, and security, to build trust
                  and engage with target audiences. This type of marketing often
                  includes content marketing, social media engagement, community
                  building, and educational initiatives to inform potential
                  users about the benefits of blockchain solutions, while also
                  addressing concerns related to security and regulatory
                  compliance. By effectively communicating the value proposition
                  of blockchain technology, businesses can enhance their
                  visibility, attract investors, and foster a loyal customer
                  base in a rapidly evolving digital landscape.
                </p>
              </div>
              <div className="col-lg-5">
                <img
                  src={require("../Images/whatdex.webp")}
                  className="deximg d-block mx-auto"
                  width="80%"
                  alt="DEX development"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="sec4dex">
          <div className="container">
            <div className="feature-content pb-3">
              <h2 className="mb-2">
                Features of Decentralized Blockchain Marketing
              </h2>
            </div>
            <div className="feature-card-row">
              <div className="row ">
                <div className="col-lg-4 col-md-6">
                  <div class="flex-container dexf">
                    <div class="feature-card-dex">
                      <img
                        src={require("../Images/decentralized.webp")}
                        class="feature-img-dex"
                        alt="Decentralized"
                      />
                      <h5 class="feature-card-dextitle">Transparency</h5>
                      <p class="dexfeature-card-para">
                        Blockchain technology allows for transparent
                        transactions and interactions, enabling users to verify
                        the authenticity of information and claims made by
                        brands.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div class="flex-container dexf">
                    <div class="feature-card-dex">
                      <img
                        src={require("../Images/secured.webp")}
                        class="feature-img-dex"
                        alt="Security Mechanisms"
                      />
                      <h5 class="feature-card-dextitle"> Decentralization</h5>
                      <p class="dexfeature-card-para">
                        Unlike traditional marketing, which often relies on
                        centralized platforms, decentralized marketing leverages
                        peer-to-peer networks, reducing reliance on
                        intermediaries and enhancing user control.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div class="flex-container dexf">
                    <div class="feature-card-dex">
                      <img
                        src={require("../Images/liquidity2.webp")}
                        class="feature-img-dex"
                        alt="Liquidity Pools"
                      />
                      <h5 class="feature-card-dextitle"> Security</h5>
                      <p class="dexfeature-card-para">
                        Blockchain's inherent security features protect user
                        data and transactions, fostering trust between brands
                        and consumers.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div class="flex-container dexf">
                    <div class="feature-card-dex">
                      <img
                        src={require("../Images/third-party (1).webp")}
                        class="feature-img-dex"
                        alt="User-Friendly Interfaces"
                      />
                      <h5 class="feature-card-dextitle">
                        Community Engagement
                      </h5>
                      <p class="dexfeature-card-para">
                        Decentralized marketing encourages community involvement
                        and participation, allowing users to contribute to brand
                        narratives and campaigns.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div class="flex-container dexf">
                    <div class="feature-card-dex">
                      <img
                        src={require("../Images/money-transfer.webp")}
                        class="feature-img-dex"
                        alt="Atomic Swaps"
                      />
                      <h5 class="feature-card-dextitle">Tokenization</h5>
                      <p class="dexfeature-card-para">
                        Brands can create their own tokens to incentivize
                        customer engagement, reward loyalty, and facilitate
                        transactions within their ecosystems.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div class="flex-container dexf">
                    <div class="feature-card-dex">
                      <img
                        src={require("../Images/block.webp")}
                        class="feature-img-dex"
                        alt="Multi-Chain Compatibility"
                      />
                      <h5 class="feature-card-dextitle">Smart Contracts</h5>
                      <p class="dexfeature-card-para">
                        Automated agreements can streamline marketing processes,
                        ensuring that terms are met without the need for
                        intermediaries, thus reducing costs and increasing
                        efficiency.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div class="flex-container dexf">
                    <div class="feature-card-dex">
                      <img
                        src={require("../Images/tfa.webp")}
                        class="feature-img-dex"
                        alt="Two factor authentication"
                      />
                      <h5 class="feature-card-dextitle">Data Ownership</h5>
                      <p class="dexfeature-card-para">
                        Users have greater control over their data, deciding how
                        and when to share it, which can lead to more
                        personalized marketing experiences.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div class="flex-container dexf">
                    <div class="feature-card-dex">
                      <img
                        src={require("../Images/wallet.webp")}
                        class="feature-img-dex"
                        alt="Digital Wallet"
                      />
                      <h5 class="feature-card-dextitle"> educed Costs:</h5>
                      <p class="dexfeature-card-para">
                        By eliminating intermediaries and streamlining
                        processes, decentralized marketing can lower costs for
                        brands, allowing for more efficient allocation of
                        resources.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div class="flex-container dexf">
                    <div class="feature-card-dex">
                      <img
                        src={require("../Images/supply-chain.webp")}
                        class="feature-img-dex"
                        alt="Multi-lingual support"
                      />
                      <h5 class="feature-card-dextitle">
                        Multi-lingual support
                      </h5>
                      <p class="dexfeature-card-para">
                        Our DEX platform supports multiple language support,
                        which helps global users to access easily.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sec3dex">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 pleft fade-up">
                <div className="d-flex flex-column align-items-end">
                  <h2 className="mb-5">Blockchain Marketing Company</h2>

                  <p>
                    Block Intelligence is a leader in providing innovative
                    Blockchain Marketing services. Our comprehensive strategies
                    are designed to enhance your brand's visibility and
                    engagement in the blockchain space. We utilize cutting-edge
                    marketing techniques and technologies to ensure your message
                    reaches the right audience. With our expertise, you can
                    effectively communicate your value proposition, build trust,
                    and foster a loyal customer base. Join us in revolutionizing
                    your marketing approach in the blockchain industry.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 pright fade-up">
                <div className="">
                  <img
                    src={require("../Images/dexdevelop.webp")}
                    className="dexdevelopimg mx-auto d-block"
                    alt="Decentralized Exchange"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sec3dex">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 pleft fade-up">
                <div className="d-flex whatsmartcontract flex-column align-items-end">
                  <h2>
                    Why Choose Block Intelligence for Blockchain Marketing?
                  </h2>

                  <p>
                    Choose Block Intelligence for blockchain marketing because
                    we combine deep industry expertise with innovative,
                    data-driven strategies tailored to the unique needs of the
                    blockchain ecosystem. Our end-to-end marketing solutions
                    from branding and community engagement to PR, influencer
                    outreach, and targeted digital campaigns ensure your project
                    gains maximum visibility, credibility, and growth. With a
                    focus on fostering trust and adoption, we leverage our
                    extensive network and experience to position your blockchain
                    venture as a market leader, delivering measurable results
                    for your success.
                    <div className="mt-3">
                      <a
                        href="https://wa.me/7738479381"
                        target="_blank"
                        className="button"
                      >
                        Talk with expert
                      </a>
                    </div>
                  </p>
                </div>
              </div>
              <div className="col-lg-4 pright fade-up">
                <div className="align-items-center justify-content-start">
                  <img
                    src={require("../Images/ques.webp")}
                    className="whydeximg"
                    alt="nft marketplace development services"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <FillForm />
      <Footer />
    </>
  );
}
