import React from "react";
import "../Pages/Style.css";
import { ReactComponent as WorkIcon } from "../Images/requirement.svg";
import { ReactComponent as SchoolIcon } from "../Images/design.svg";
import { ReactComponent as DevelopmentIcon } from "../Images/build.svg";
import { ReactComponent as SecurityIcon } from "../Images/security.svg";
import { ReactComponent as TestingIcon } from "../Images/test.svg";
import { ReactComponent as LaunchIcon } from "../Images/launch.svg";
import { ReactComponent as MaintenanceIcon } from "../Images/maintainance.svg";
import timelineElements from "./timelineElements";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

function Contact() {
  let workIconStyles = { background: "#06D6A0" };
  let schoolIconStyles = { background: "#f9c74f" };

  return (
    <div className="flow-body">
      <div className="container">
        <h2 className="title h2-mobile h2-md">
        Process of Our Centralized Crypto Exchange Development
        </h2>
        <p className="title-down p-md">
        Here's how our expert team of crypto enthusiasts immerses themselves in crafting your ideal platform, ensuring an engaging and seamless experience for your users. Let's dive into the world of centralized crypto exchange development together!
</p>
        <VerticalTimeline>
          {timelineElements.map((element) => {
            let iconComponent;

            switch (element.icon) {
              case "work":
                iconComponent = <WorkIcon />;
                break;
              case "school":
                iconComponent = <SchoolIcon />;
                break;
              case "development":
                iconComponent = <DevelopmentIcon />;
                break;
              case "security":
                iconComponent = <SecurityIcon />;
                break;
              case "testing":
                iconComponent = <TestingIcon />;
                break;
              case "launch":
                iconComponent = <LaunchIcon />;
                break;
              case "maintainance":
                iconComponent = <MaintenanceIcon />;
                break;
              default:
                iconComponent = null;
            }

            return (
              <VerticalTimelineElement
                key={element.id}
                iconStyle={
                  element.icon === "work" ? workIconStyles : schoolIconStyles
                }
                icon={iconComponent}
              >
                <div className="card-time">
                  <div>
                    <h5 className="vertical-timeline-element-subtitle">
                      {element.location}
                    </h5>
                    <p id="description">{element.description}</p>
                  </div>
                  <div>
                    <h3 className="vertical-timeline-element-title">
                      {element.count}
                    </h3>
                  </div>
                </div>
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline>
      </div>
    </div>
  );
}

export default Contact;
