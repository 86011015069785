import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import FillForm from "../Components/FillForm";
import Contactbar from "../Components/Contactbar";

import "../Pages/Style.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";
import TechStackSlider from '../Components/TechStackSlider'; // Import the TechStackSlider component

export default function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [isScrolled]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isScrolled]);

  return (
    <>
      <Helmet>
        <title>Rarible clone script</title>
        <meta property="title" content="Rarible clone script" />
        <meta
          name="description"
          content="Block Intelligence is a premier NFT Marketplace development company that helps you launch your NFT Marketplace like Rarible with Rarible clone script."
        />
        <meta
          name="keywords"
          content="p2p crypto exchange development company,  p2p cryptocurrency exchange development company, p2p crypto exchange development, p2p crypto exchange software development, p2p exchange development, p2p cryptocurrency exchange development, p2p exchange development company, p2p cryptocurrency exchange software, crypto p2p exchange application development, p2p crypto exchange development solutions, p2p crypto exchange development services"
        />
        <link
          rel="canonical"
          href="https://www.blockintelligence.io/rarible-clone-script"
        />
      </Helmet>
      <Header />
      <Contactbar />
      <div className="rarible_head">
        <section className="p2p rarible">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 banner_con simple-video mb-sx-2">
                <h1 className="wow fadeInUp text-black h1tag">
                  <>Betfury Clone Script</>
                </h1>
                <p className="wow fadeInUp text-black mt-4">
                  Create, manage, and enjoy a thrilling gaming experience on the blockchain with
                  Block Intelligence. We offer Betfury clone script development
                  to help you launch your own online gaming platform like Betfury in no
                  time. Join the revolution of decentralized gaming and
                  entertainment.
                </p>
                <div className="p2pbt mt-5">
                  <a href="#contact" className="button">
                    Get Demo
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whatsmartcontract  wahtrarible py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-5 mx-auto d-flex justify-content-center align-items-center">
                <div className="rotateimg">
                  <img
                    src={require("../Images/openseaa5.webp")}
                    width="95%"
                    className="mx-auto d-block"
                    alt="p2p crypto exchange development company"
                  />
                </div>
              </div>
              <div className="col-lg-7 ">
                <div className="p2pcontent">
                  <h2 className="text-black">
                    What is <span>Betfury Clone</span> Script?{" "}
                  </h2>
                  <p className="text-black">
                    The Betfury clone script is a comprehensive solution that replicates
                    the exciting features of the Betfury gaming platform. With
                    a Betfury Clone Script, users can create, manage, and enjoy
                    a thrilling gaming experience on the blockchain. This script
                    allows for seamless integration of various games, user account
                    management, and secure transactions, providing a robust platform
                    for online gaming and entertainment.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whatsmartcontract wahtrarible py-5">
          <div className="container py-5">
            <div className="row mb-5">
              <div className="col-lg-12 ">
                <div className="p2pcontent text-center">
                  <h2 className="text-black">Our Development Process for Betfury Clone Script</h2>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-sm-3 sixnone p-0">
                <img
                  src={require("../Images/NFT/1.webp")}
                  width="100%"
                  className="mx-auto d-block"
                  alt="p2p crypto exchange development company"
                />
              </div>
              <div className="col-sm-9 rightradius  border-right border-top p-5">
                <h5>
                  {" "}
                  <span className="h3"> Planning </span>
                </h5>
                <h5>
                  First, we decide what we want to create and outline the steps
                  to achieve it.
                </h5>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-9 leftradius border-left border-top p-5">
                <h5 className="mt-4">
                  {" "}
                  <span className="h3"> Design </span>
                </h5>
                <h5>
                  We create an advanced design for our project, deciding how it
                  will look and function.
                </h5>
              </div>
              <div className="col-sm-3 sixnone p-0">
                <img
                  src={require("../Images/NFT/2&4.webp")}
                  width="95%"
                  className="mx-auto d-block"
                  alt="p2p crypto exchange development company"
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-3 sixnone p-0">
                <img
                  src={require("../Images/NFT/3&5.webp")}
                  width="100%"
                  className="mx-auto d-block"
                  alt="p2p crypto exchange development company"
                />
              </div>
              <div className="col-sm-9 p-5 rightradius border-right border-top">
                <h5>
                  {" "}
                  <span className="h3"> Development </span>
                </h5>
                <h5>
                  Our team starts building the project, writing the code and
                  creating the necessary components.
                </h5>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-9 p-5 leftradius border-left border-top">
                <h5 className="mt-4">
                  {" "}
                  <span className="h3 "> Testing </span>
                </h5>
                <h5>
                  {" "}
                  We thoroughly test the project to find and fix any issues or
                  bugs.
                </h5>
              </div>
              <div className="col-sm-3 sixnone p-0">
                <img
                  src={require("../Images/NFT/2&4.webp")}
                  width="95%"
                  className="mx-auto d-block"
                  alt="p2p crypto exchange development company"
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-3 sixnone p-0">
                <img
                  src={require("../Images/NFT/3&5.webp")}
                  width="100%"
                  className="mx-auto d-block"
                  alt="p2p crypto exchange development company"
                />
              </div>
              <div className="col-sm-9 p-5 rightradius border-right border-top">
                <h5 className="mt-4">
                  {" "}
                  <span className="h3 mt-4"> Deployment </span>
                </h5>
                <h5>
                  Once satisfied, we deploy the project to the public or
                  intended audience.
                </h5>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-9 p-5 border-top leftradius border-bottom border-left">
                <h5>
                  {" "}
                  <span className="h3"> Maintenance </span>
                </h5>
                <h5>
                  We continue to monitor and update the project to ensure it
                  stays functional and up-to-date.
                </h5>
              </div>
              <div className="col-sm-3 sixnone p-0">
                <img
                  src={require("../Images/NFT/6.webp")}
                  width="100%"
                  className="mx-auto d-block"
                  alt="p2p crypto exchange development company"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract wahtrarible py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-7 ">
                <div className="p2pcontent">
                  <h2 className="mb-3 text-black">
                    Why Choose Block Intelligence for <span>Betfury Clone</span>{" "}
                    Script?{" "}
                  </h2>
                  <p className="text-black">
                    Block Intelligence stands out as a premier choice for
                    Betfury clone script development. It stays ahead of the
                    competition in Betfury clone script development for several
                    compelling reasons. First and foremost, Block Intelligence
                    prioritizes user-friendly features, making the script easily
                    accessible and navigable for users of all levels of tech
                    proficiency. The platform has a seamless and intuitive
                    interface, ensuring a smooth experience for both gamers
                    and developers.
                  </p>
                </div>
              </div>

              <div className="col-lg-5 ">
                <div className="rotateimg">
                  <img
                    src={require("../Images/opensea7.webp")}
                    className="mx-auto d-block"
                    width="95%"
                    alt="p2p crypto exchange development company"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract wahtrarible py-5">
          <div className="container py-5">
            <div className="p2pcontent">
              <h2 className="mb-5 text-black text-center ">
                Advantages of <span>Betfury Clone</span> Script
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-7 ">
                <div className="p2pcontent">
                  <p className="d-flex text-black">
                    <img
                      src={require("../Images/nfticon.webp")}
                      width="30px"
                      className="signpen"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    <div>
                      <span className="h5"> Effortless Game Integration</span> The
                      script simplifies the process of integrating various games, allowing
                      developers to effortlessly create a diverse gaming experience.
                    </div>
                  </p>
                  <p className="d-flex text-black">
                    <img
                      src={require("../Images/nfticon.webp")}
                      width="30px"
                      className="signpen"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    <div>
                      <span className="h5">Decentralization and Security</span>{" "}
                      Enjoy the benefits of a decentralized platform while
                      ensuring the highest level of security for transactions
                      and user data.
                    </div>
                  </p>
                  <p className="d-flex text-black">
                    <img
                      src={require("../Images/nfticon.webp")}
                      width="30px"
                      className="signpen"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    <div>
                      <span className="h5">Customization</span> Tailor the
                      platform to your brand and vision, creating a unique user
                      interface that enhances the overall experience for
                      gamers and developers.
                    </div>
                  </p>

                  <p className="d-flex text-black">
                    <img
                      src={require("../Images/nfticon.webp")}
                      width="30px"
                      className="signpen"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    <div>
                      <span className="h5">Community Engagement</span> Foster a
                      vibrant community around your platform, encouraging
                      interaction and engagement among players and enthusiasts.
                    </div>
                  </p>
                  <p className="d-flex text-black">
                    <img
                      src={require("../Images/nfticon.webp")}
                      width="30px"
                      className="signpen"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    <div>
                      <span className="h5">Smart Contract Integration</span>{" "}
                      The Betfury clone script comes with smart contract
                      capabilities, automating and securing transactions on the
                      blockchain.
                    </div>
                  </p>

                  <p className="d-flex text-black">
                    <img
                      src={require("../Images/nfticon.webp")}
                      width="30px"
                      className="signpen"
                      alt="p2p crypto exchange development company"
                    />{" "}
                    <div>
                      <span className="h5">Cost-Effectiveness</span> Save time
                      and resources by opting for a clone script rather than
                      starting from scratch, ensuring a quicker entry into the
                      gaming marketplace.
                    </div>
                  </p>
                </div>
              </div>

              <div className="col-lg-5 mt-5">
                <div className="rotateimg">
                  <img
                    src={require("../Images/raribleclone.webp")}
                    width="95%"
                    className="mx-auto rounded d-block"
                    alt="p2p crypto exchange development company"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

       

     

      

        <section className="techstack2 stacz">
          <div className="container">
            <h2 className="tech-h2">Blockchain Tech Stacks</h2>
            <div className="stack">
              <p>
                Our team of blockchain developers are experts at building
                innovative blockchain solutions on the best available blockchain
                platform.
              </p>
            </div>
            <TechStackSlider /> 
          
          </div>
        </section>
      </div>

      <FillForm />

      <Footer />
    </>
  );
}
