import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import FillForm from "../Components/FillForm";
import Contactbar from "../Components/Contactbar";

import "../Pages/Style.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import app from "../Images/cyber-security.webp";
import smartcontract from "../Images/settings.webp";
import stake from "../Images/flexibility.webp";
import farm from "../Images/browser.webp";
import global from "../Images/blockchain.webp";
import secure from "../Images/resolution.webp";
import { Helmet } from "react-helmet";

export default function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [isScrolled]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isScrolled]);

  return (
    <>
      <Helmet>
        <title>
          Leading NFT Marketplace Development Company - Block Intelligence
        </title>
        <link
          rel="canonical"
          href="https://www.blockintelligence.io/nft-marketplace-development-company"
        />
        <meta
          property="title"
          content="Leading NFT Marketplace Development Company - Block Intelligence"
        />
        <meta
          name="description"
          content="Block Intelligence, the top NFT Marketplace Development Company, pioneering digital collectibles. Explore our NFT Marketplace development services and innovative solutions for unique digital assets."
        />
        <meta
          name="keywords"
          content="NFT marketplace development company, nft marketplace development services, nft marketplace development solutions, create your own nft marketplace, nft marketplace development services, White label nft marketplace development company, Create your own NFT marketplace, non fungible token development company"
        />
      </Helmet>
      <Header />
      <Contactbar />
      <div className="nft_head">
        <section className="nft">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 banner_con simple-video mb-sx-2">
                <h1 className="wow fadeInUp h1tag">
                  NFT Marketplace Development Company
                </h1>
                <p className="wow fadeInUp">
                  At Block Intelligence, we're pioneers in NFT Marketplace
                  development, leading in digital collectibles and unique
                  assets. In the era of digital ownership transformation, NFTs
                  are our frontier, bridging the physical and digital realms. We
                  craft platforms that empower creators, collectors, and
                  businesses in this exciting digital landscape.
                </p>

                <a href="#contact" className="button">
                  Get Demo
                </a>
              </div>
              <div className="col-lg-6 mx-auto mt-4 d-flex ">
                <div className="wow zoomIn">
                  <div className="banner_i">
                    <img
                      src={require("../Images/card1.webp")}
                      alt="NFT marketplace development services"
                      data-wow-delay="0.2s"
                      data-wow-iteration="infinite"
                      data-wow-duration="8s"
                      className="nft-banner1 img-fluid mx-auto"
                    />
                    <img
                      src={require("../Images/card2.webp")}
                      alt="NFT marketplace development services"
                      className="nft-banner2 img-fluid"
                    />
                    <img
                      src={require("../Images/card3.webp")}
                      alt="NFT marketplace development services"
                      className="nft-banner3 img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="Overview">
          <div className="container">
            <div className="Overview">
              <div className="row">
                <div className="col-lg-5 mx-auto mt-4 d-flex justify-content-center align-items-center">
                  <img
                    src={require("../Images/overvieew.webp")}
                    className="overimg"
                    alt="NFT Marketplace"
                  />
                </div>
                <div className="col-lg-7 ">
                  <h2>Overview of NFT Marketplace</h2>

                  <p>
                    Imagine a digital marketplace where unique things are bought
                    and sold, just like collectibles in the real world. It's
                    called the NFT Marketplace. Instead of physical items, these
                    are digital, like special art, music, and virtual stuff.
                    What's cool is that they come with a certificate saying
                    they're one-of-a-kind, and this certificate is on the
                    computer, so it's super secure. Artists can sell their
                    digital creations here, people can buy them, and businesses
                    can find new ways to make money. It's like a magical world
                    where digital things become valuable and everyone can be a
                    part of it.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className=" Marketplace">
          <div className="container">
            <h2 className="text-center">
              {" "}
              Our NFT Marketplace Development Services
            </h2>

            <div className="row mt-2">
              <div className="col-lg-8">
                <p className="p-5">
                  OurAt Block Intelligence, we build special online stores for
                  unique digital items. These stores are called NFT
                  Marketplaces. If you have cool digital stuff to sell, like art
                  or music, we can create a fantastic place for you. People can
                  easily buy your digital goodies, and you can earn money from
                  them. We make sure everything works smoothly and looks great,
                  so you can focus on your creativity. Choose us to bring your
                  digital treasures to the world! Fundamental Benefits in NFT
                  Marketplace Development
                </p>
              </div>
              <div className="col-lg-4 mx-auto d-block">
                <img
                  src={require("../Images/nftimg.webp")}
                  className="ourimg"
                  alt="NFT marketplace development services"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="nft-usecase">
          <div className="container">
            <div className="dev head">
              <h3>NFT Marketplace Development - Prime Use Cases</h3>
            </div>

            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6 mt-5">
                <div className="">
                  <div className="card-img-tops">
                    <img
                      src={require("../Images/Dart.webp")}
                      alt="Digital Art"
                      className="img-fluid d-block mx-auto"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 mt-5">
                <div className="">
                  <div className="card-img-tops">
                    <img
                      src={require("../Images/collectibles.webp")}
                      alt="Collectibles"
                      className="img-fluid d-block mx-auto"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 mt-5">
                <div className="">
                  <div className="card-img-tops">
                    <img
                      src={require("../Images/Realestate.webp")}
                      alt="Virtual Real Estate"
                      className="img-fluid d-block mx-auto"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 mt-5">
                <div className="">
                  <div className="card-img-tops">
                    <img
                      src={require("../Images/sports.webp")}
                      alt="Sports"
                      className="img-fluid d-block mx-auto"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 mt-5">
                <div className="">
                  <div className="card-img-tops">
                    <img
                      src={require("../Images/NFT/fashion.webp")}
                      alt="Fashion"
                      className="img-fluid d-block mx-auto"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 mt-5">
                <div className="">
                  <div className="card-img-tops">
                    <img
                      src={require("../Images/Ticketing.webp")}
                      alt="Ticketing"
                      className="img-fluid d-block mx-auto"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 mt-5">
                <div className="">
                  <div className="card-img-tops">
                    <img
                      src={require("../Images/Gamings.webp")}
                      alt="Gaming"
                      className="img-fluid d-block mx-auto"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 mt-5">
                <div className="">
                  <div className="card-img-tops">
                    <img
                      src={require("../Images/contentListen.webp")}
                      alt="Content Licensing"
                      className="img-fluid d-block mx-auto"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 mt-5">
                <div className="">
                  <div className="card-img-tops">
                    <img
                      src={require("../Images/Entertainment.webp")}
                      alt="Entertainment"
                      className="img-fluid d-block mx-auto"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="nftbenfit">
          <div className="container">
            <div className="ben-head">
              <h2>Our Fundamental Benefits in NFT Marketplace Development</h2>
            </div>
            <div className="row ">
              <div className="col-lg-4 mt-3">
                <div className="card ben-card">
                  <div className="bencard-img-tops">
                    <img src={app} alt="Security First" className="img-fluid" />
                  </div>
                  <div className="usetitle">
                    <h5 className="use-title">Security First</h5>
                    <p>
                      {" "}
                      Security First We prioritize the security of your digital
                      assets and transactions, ensuring your NFT Marketplace is
                      safe and secure.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-3">
                <div className="card ben-card">
                  <div className="bencard-img-tops">
                    <img
                      src={smartcontract}
                      alt="Customization"
                      className="img-fluid"
                    />
                  </div>
                  <div className="usetitle">
                    <h5 className="use-title"> Customization </h5>
                    <p>
                      {" "}
                      we specialize in developing NFT Marketplaces that your
                      brand identity also strategically designed to resonate
                      with your specific target audience
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-3">
                <div className="card ben-card">
                  <div className="bencard-img-tops">
                    <img src={stake} alt="Scalability" className="img-fluid" />
                  </div>
                  <div className="usetitle">
                    <h5 className="use-title"> Scalability </h5>
                    <p>
                      {" "}
                      As your NFT Marketplace grows, our solutions seamlessly
                      scale to accommodate increased traffic and demand.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-3">
                <div className="card ben-card">
                  <div className="bencard-img-tops">
                    <img
                      src={farm}
                      alt="User-Centric Design"
                      className="img-fluid"
                    />
                  </div>
                  <div className="usetitle">
                    <h5 className="use-title"> User-Centric Design</h5>
                    <p>
                      {" "}
                      Our user-friendly interfaces make navigating your NFT
                      Marketplace a breeze for both creators and collectors.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-3">
                <div className="card ben-card">
                  <div className="bencard-img-tops">
                    <img
                      src={global}
                      alt="Blockchain Integration"
                      className="img-fluid"
                    />
                  </div>
                  <div className="usetitle">
                    <h5 className="use-title">Blockchain Integration</h5>
                    <p>
                      {" "}
                      We leverage blockchain technology for transparent,
                      immutable ownership records, instilling trust in your
                      users.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 mt-3">
                <div className="card ben-card">
                  <div className="bencard-img-tops">
                    <img
                      src={secure}
                      alt="Community Engagement"
                      className="img-fluid"
                    />
                  </div>
                  <div className="usetitle">
                    <h5 className="use-title"> Community Engagement </h5>
                    <p>
                      {" "}
                      Foster vibrant NFT communities with features that
                      encourage engagement and collaboration.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="nft-features">
          <div className="container">
            <div className="cex-cry-serv">
              <div className="cex-cry-serv-content">
                <h2 className="h2-mobile h2-md">
                  Key Feature of Our NFT Marketplace Development Solutions
                </h2>
              </div>
              <div className="cex-cry-serv-card-row ">
                <div className="row ">
                  <div className="col-lg-4 col-md-4">
                    <div className="cex-cry-serv-card servcs-1 token">
                      <div className="serv-card-content">
                        <p
                          className="serv-card-content-title"
                          style={{ fontSize: "24px" }}
                        >
                          {" "}
                          Tokenization{" "}
                        </p>
                        <p className="serv-card-content-desc">
                          Transform digital assets, art, music, videos, and more
                          into NFTs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="cex-cry-serv-card servcs-2 smart">
                      <div className="serv-card-content">
                        <p
                          className="serv-card-content-title"
                          style={{ fontSize: "24px" }}
                        >
                          Smart Contracts{" "}
                        </p>
                        <p className="serv-card-content-desc">
                          Secure and automate transactions with blockchain-based
                          smart contracts.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="cex-cry-serv-card servcs-3 multi">
                      <div className="serv-card-content">
                        <p
                          className="serv-card-content-title"
                          style={{ fontSize: "22px" }}
                        >
                          Multi-Platform Compatibility{" "}
                        </p>
                        <p className="serv-card-content-desc">
                          Reach a wider audience with NFT Marketplaces
                          accessible across devices and blockchains.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cex-cry-serv-card-row ">
                <div className="row ">
                  <div className="col-lg-4 col-md-4">
                    <div className="cex-cry-serv-card servcs-4 liquid">
                      <div className="serv-card-content">
                        <p
                          className="serv-card-content-title"
                          style={{ fontSize: "24px" }}
                        >
                          Liquidity Management{" "}
                        </p>
                        <p className="serv-card-content-desc">
                          Facilitate trading and liquidity for NFTs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="cex-cry-serv-card servcs-5 nft-min">
                      <div className="serv-card-content">
                        <p
                          className="serv-card-content-title"
                          style={{ fontSize: "24px" }}
                        >
                          NFT Minting
                        </p>
                        <p className="serv-card-content-desc">
                          Simplify NFT creation and minting processes for
                          creators.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <div className="cex-cry-serv-card servcs-6 analysis">
                      <div className="serv-card-content">
                        <p
                          className="serv-card-content-title"
                          style={{ fontSize: "24px" }}
                        >
                          Comprehensive Analytics
                        </p>
                        <p className="serv-card-content-desc">
                          Gain insights into user behavior and asset
                          performance.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="Overview">
          <div className="container">
            <div className="Overview">
              <div className="row">
                <div className="col-lg-7 ">
                  <h2>
                    Why Choose the Block Intelligence As NFT Marketplace
                    Development?
                  </h2>
                  <p>
                    With Block Intelligence, you get unmatched expertise in NFTs
                    and blockchain, tailored solutions, top-notch security, and
                    a commitment to building vibrant communities. Our
                    user-friendly interfaces and scalable designs ensure your
                    NFT Marketplace excels. Join us to bring your NFT vision to
                    life with confidence and innovation.
                  </p>
                  <ul className="list wow fadeInDown" data-wow-delay="0.5s">
                    <li className="list">
                      Expertise NFT and Blockchain solution
                    </li>
                    <li className="list">Customization</li>
                    <li className="list">High Security</li>
                    <li className="list">Enhanced User Experience</li>
                    <li className="list">Blockchain Integration</li>
                    <li className="list">Scalability</li>
                  </ul>
                </div>

                <div className="col-lg-5 mx-auto ">
                  <img
                    src={require("../Images/whynft.webp")}
                    className="think d-block mx-auto"
                    alt="create your own nft marketplace"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="process">
          <div className="container">
            <h3> Process of our NFT Marketplace Development Service</h3>
            <ul class="timeline-with-icons list-unstyled mt-3">
              <li class="timeline-item mb-3">
                <div className="card-img-fiance nft-process-img">
                  <img
                    src={require("../Images/concept.webp")}
                    alt="Planning"
                    className="img-fluid"
                  />
                </div>
                <div class="timeline-content">
                  <h5 class="fw-bold">Concept & Planning</h5>{" "}
                  <p class="text-muted">
                    We begin by understanding your vision and goals for the NFT
                    Marketplace. Together, we plan the features, design, and
                    functionality.
                  </p>
                </div>
              </li>

              <li class="timeline-item mb-3">
                <div className="card-img-fiance nft-process-img">
                  <img
                    src={require("../Images/ui-design.webp")}
                    alt="Design & Development"
                    className="img-fluid"
                  />
                </div>
                <div class="timeline-content">
                  <h5 class="fw-bold"> Design & Development</h5>
                  <p class="text-muted">
                    Our team creates the marketplace, designing user-friendly
                    interfaces and integrating blockchain technology for
                    security and authenticity.
                  </p>
                </div>
              </li>

              <li class="timeline-item mb-3">
                <div className="card-img-fiance nft-process-img">
                  <img
                    src={require("../Images/contract2.webp")}
                    alt="Smart Contracts"
                    className="img-fluid"
                  />
                </div>
                <div class="timeline-content">
                  <h5 class="fw-bold">Smart Contracts</h5>
                  <p class="text-muted">
                    We develop smart contracts that power the NFTs, ensuring
                    transparent and immutable ownership records.
                  </p>
                </div>
              </li>

              <li class="timeline-item mb-3">
                <div className="card-img-fiance nft-process-img">
                  <img
                    src={require("../Images/checklist.webp")}
                    alt="Testing & Quality Assurance"
                    className="img-fluid"
                  />
                </div>
                <div class="timeline-content">
                  <h5 class="fw-bold"> Testing & Quality Assurance</h5>
                  <p class="text-muted">
                    Rigorous testing ensures the marketplace functions
                    flawlessly, with a focus on security, performance, and
                    usability.
                  </p>
                </div>
              </li>
              <li class="timeline-item mb-3">
                <div className="card-img-fiance nft-process-img">
                  <img
                    src={require("../Images/rocket2.webp")}
                    alt="Launch & Deployment"
                    className="img-fluid"
                  />
                </div>
                <div class="timeline-content">
                  <h5 class="fw-bold"> Launch & Deployment </h5>
                  <p class="text-muted">
                    We deploy the NFT Marketplace, making it accessible to your
                    users, and provide support during the launch phase.
                  </p>
                </div>
              </li>
              <li class="timeline-item mb-3">
                <div className="card-img-fiance nft-process-img">
                  <img
                    src={require("../Images/plan.webp")}
                    alt="Maintenance & Updates"
                    className="img-fluid"
                  />
                </div>
                <div class="timeline-content">
                  <h5 class="fw-bold"> Maintenance & Updates</h5>
                  <p class="text-muted">
                    We offer ongoing maintenance, updates, and improvements to
                    keep your NFT Marketplace current and secure
                  </p>
                </div>
              </li>
              <li class="timeline-item mb-3">
                <div className="card-img-fiance nft-process-img">
                  <img
                    src={require("../Images/growth.webp")}
                    alt="Marketing & Growth"
                    className="img-fluid"
                  />
                </div>
                <div class="timeline-content">
                  <h5 class="fw-bold"> Marketing & Growth </h5>
                  <p class="text-muted">
                    Our services extend to marketing strategies and growth
                    initiatives to help you reach a broader audience and
                    maximize your NFT sales.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </section>

        <section className="faq2">
          <div id="main">
            <div class="container">
              <div className="faq-content">
                <p className="p-mobile">Have a Question ?</p>
                <h3 className="h2-mobile h2-md">
                  Frequently Asked Questions ?
                </h3>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <img
                    src={require("../Images/nftfaq.webp")}
                    className="faqimg d-block mx-auto"
                    alt="FAQ Image"
                  />
                </div>
                <div className="col-lg-8">
                  <div class="accordion" id="faq">
                    <div class="card">
                      <div class="card-header" id="faqhead1">
                        <a
                          href="#"
                          class="btn btn-header-link collapsed"
                          data-toggle="collapse"
                          data-target="#faq1"
                          aria-expanded="true"
                          aria-controls="faq1"
                        >
                          1. What is an NFT Marketplace, and how does it work?
                        </a>
                      </div>

                      <div
                        id="faq1"
                        class="collapse"
                        aria-labelledby="faqhead1"
                        data-parent="#faq"
                      >
                        <div class="card-body p-md">
                          An NFT Marketplace is an online platform where unique
                          digital assets, represented as Non-Fungible Tokens
                          (NFTs), are bought, sold, and traded. Each NFT is
                          one-of-a-kind and is powered by blockchain technology
                          to ensure ownership and provenance. Users can create,
                          list, and purchase NFTs, often using cryptocurrency.
                          It's a digital marketplace for unique digital
                          collectibles, art, virtual items, and more.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="faqhead2">
                        <a
                          href="#"
                          class="btn btn-header-link collapsed"
                          data-toggle="collapse"
                          data-target="#faq2"
                          aria-expanded="true"
                          aria-controls="faq2"
                        >
                          2. How can Block Intelligence help me launch my NFT
                          Marketplace?
                        </a>
                      </div>

                      <div
                        id="faq2"
                        class="collapse"
                        aria-labelledby="faqhead2"
                        data-parent="#faq"
                      >
                        <div class="card-body p-md">
                          Block Intelligence specializes in NFT Marketplace
                          development. We provide end-to-end solutions, from
                          concept to deployment. Our services include custom
                          marketplace design, smart contract development,
                          security implementation, and ongoing support. We work
                          closely with you to bring your vision to life,
                          ensuring your NFT Marketplace is secure,
                          user-friendly, and tailored to your needs.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="faqhead3">
                        <a
                          href="#"
                          class="btn btn-header-link collapsed"
                          data-toggle="collapse"
                          data-target="#faq3"
                          aria-expanded="true"
                          aria-controls="faq3"
                        >
                          3. What sets Block Intelligence apart in NFT
                          Marketplace development?
                        </a>
                      </div>

                      <div
                        id="faq3"
                        class="collapse"
                        aria-labelledby="faqhead3"
                        data-parent="#faq"
                      >
                        <div class="card-body p-md">
                          Block Intelligence stands out due to its extensive
                          experience in blockchain technology and NFTs. We offer
                          highly customizable solutions, prioritize security,
                          and create user-friendly interfaces. Our blockchain
                          integration ensures transparency and trust, and we
                          focus on building vibrant communities within your
                          marketplace. We also provide scalability to
                          accommodate growth. With Block Intelligence, your NFT
                          Marketplace is in expert hands, ready to thrive in the
                          digital world.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <FillForm />

      <Footer />
    </>
  );
}
