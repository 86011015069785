let timelineElements = [
    {
      id: 1,
      title: "Game Design",
      location: "Game Design",
      description:"We create web3 game concepts, storylines, characters, levels, UI, UX, and gameplay mechanics that suit your vision and goals. We also create game design documents, prototypes, and wireframes to visualize and validate your web3 game ideas.",
      buttonText: "View Frontend Projects",
      date: "August 2016 - present",
      icon: "work",
      count: "01",
    },
    {
      id: 2,
      title: "Game Development",
      location: "Game Development",
      description:
      " We code and program your web3 games using the best practices and standards of web3 game development. We use various programming languages, frameworks, and libraries.",
      date: "June 2013 - August 2016",
      icon: "school",
      count: "02",
    },
    {
      id: 3,
      title: "Game Art",
      location: "Game Art",
      description:
      "We create web3 game art assets, such as 2D and 3D models, textures, animations, effects, and backgrounds, that bring your web3 games to life. We use various tools and software.",
      date: "September 2011 - June 2013",
      icon: "development",
      count: "03",
    },
    {
      id: 4,
      title: "Game Audio",
      location: "Game Audio",
      description:
      "We create web3 game audio assets, such as music, sound effects, voice-overs, and dialogues, that enhance the mood and atmosphere of your web3 games. We use various tools and software.",
      date: "September 2011",
      icon: "security",
      count: "04",
    },
    {
      id: 5,
      title: "Game Testing",
      location: "Game Testing",
      description:
      "We test your web3 games for functionality, usability, compatibility, performance, security, and quality. We use various tools and methods.",
      date: "2007 - 2011",
      icon: "testing",
      count: "05",
    },
    {
      id: 6,
      title: "Game Support",
      location: "Game Support",
      description:
      "We provide ongoing support and maintenance for your web3 games. We also provide web3 game analytics, marketing, and monetization services to help you grow and retain your player base and revenue.",
      date: "2007 - 2011",
      icon: "launch",
      count: "06",
    },
  ];
  
  export default timelineElements;
  