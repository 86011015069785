import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import FillForm from "../Components/FillForm";
import Contactbar from "../Components/Contactbar";

import "../Pages/Style.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import app from "../Images/cyber-security.webp";
import smartcontract from "../Images/settings.webp";
import stake from "../Images/flexibility.webp";
import farm from "../Images/browser.webp";
import global from "../Images/blockchain.webp";
import secure from "../Images/resolution.webp";
import { Helmet } from "react-helmet";

export default function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [isScrolled]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isScrolled]);

  return (
    <>
      <Helmet>
        <title>
           NFT Game Development Company - Block Intelligence
        </title>
        <link
          rel="canonical"
          href="https://www.blockintelligence.io/nft-game-development-company"
        />
        <meta
          property="title"
          content=" NFT Game Development Company - Block Intelligence"
        />
        <meta
          name="description"
          content="Block Intelligence, the top NFT Game Development Company, pioneering digital collectibles. Explore our NFT Game Development services and innovative solutions for unique digital assets."
        />
        <meta
          name="keywords"
          content="NFT game development company, nft game development services, nft game development solutions, create your own nft game, nft game development services, White label nft game development company, Create your own NFT game, non fungible token development company"
        />
      </Helmet>
      <Header />
      <Contactbar />
      <div className="nft_head">
        <section className="nft">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 banner_con simple-video mb-sx-2">
                <h1 className="wow fadeInUp h1tag">
                  NFT Game Development Company
                </h1>
                <p className="wow fadeInUp">
                  At Block Intelligence, we're pioneers in NFT Game
                  development, leading in digital collectibles and unique
                  gaming assets. In the era of digital ownership transformation, NFTs
                  are our frontier, enhancing the gaming experience. We
                  craft games that empower creators, players, and
                  businesses in this exciting digital landscape.
                </p>

                <a href="#contact" className="button">
                  Get Demo
                </a>
              </div>
              <div className="col-lg-6 mx-auto mt-4 d-flex ">
                <div className="wow zoomIn">
                  <div className="banner_i">
                    <img
                      src={require("../Images/card1.webp")}
                      alt="NFT game development services"
                      data-wow-delay="0.2s"
                      data-wow-iteration="infinite"
                      data-wow-duration="8s"
                      className="nft-banner1 img-fluid mx-auto"
                    />
                    <img
                      src={require("../Images/card2.webp")}
                      alt="NFT game development services"
                      className="nft-banner2 img-fluid"
                    />
                    <img
                      src={require("../Images/card3.webp")}
                      alt="NFT game development services"
                      className="nft-banner3 img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="Overview">
          <div className="container">
            <div className="Overview">
              <div className="row">
                <div className="col-lg-5 mx-auto mt-4 d-flex justify-content-center align-items-center">
                  <img
                    src={require("../Images/overvieew.webp")}
                    className="overimg"
                    alt="NFT Game Development"
                  />
                </div>
                <div className="col-lg-7 ">
                  <h2>Overview of NFT Game Development</h2>

                  <p>
                    Imagine a digital gaming platform where unique items are created,
                    traded, and owned, just like collectibles in the real world. It's
                    called the NFT Game Development. Instead of physical items, these
                    are digital, like special characters, skins, and virtual assets.
                    What's cool is that they come with a certificate saying
                    they're one-of-a-kind, and this certificate is on the
                    blockchain, so it's super secure. Developers can create their
                    games here, players can buy and trade their assets, and businesses
                    can find new ways to monetize. It's like a magical world
                    where digital assets become valuable and everyone can be a
                    part of it.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className=" Marketplace">
          <div className="container">
            <h2 className="text-center">
              Our NFT Game Development Services
            </h2>

            <div className="row mt-2">
              <div className="col-lg-8">
                <p className="p-5">
                  OurAt Block Intelligence, we build special online games for
                  unique digital items. These games are called NFT
                  Games. If you have cool digital assets to sell, like characters
                  or skins, we can create a fantastic platform for you. Players can
                  easily buy your digital goodies, and you can earn money from
                  them. We make sure everything works smoothly and looks great,
                  so you can focus on your creativity. Choose us to bring your
                  digital treasures to the gaming world! Fundamental Benefits in NFT
                  Game Development
                </p>
              </div>
              <div className="col-lg-4 mx-auto d-block">
                <img
                  src={require("../Images/nftimg.webp")}
                  className="ourimg"
                  alt="NFT game development services"
                />
              </div>
            </div>
          </div>
        </section>


        <section className="nftbenfit">
          <div className="container">
            <div className="ben-head">
              <h2>Our Fundamental Benefits in NFT Game Development</h2>
            </div>
            <div className="row ">
              <div className="col-lg-4 mt-3">
                <div className="card ben-card">
                  <div className="bencard-img-tops">
                    <img src={app} alt="Security First" className="img-fluid" />
                  </div>
                  <div className="usetitle">
                    <h5 className="use-title">Security First</h5>
                    <p>
                      {" "}
                      Security First We prioritize the security of your digital
                      assets and transactions, ensuring your NFT Game is
                      safe and secure.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-3">
                <div className="card ben-card">
                  <div className="bencard-img-tops">
                    <img
                      src={smartcontract}
                      alt="Customization"
                      className="img-fluid"
                    />
                  </div>
                  <div className="usetitle">
                    <h5 className="use-title"> Customization </h5>
                    <p>
                      {" "}
                      we specialize in developing NFT Games that your
                      brand identity also strategically designed to resonate
                      with your specific target audience
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-3">
                <div className="card ben-card">
                  <div className="bencard-img-tops">
                    <img src={stake} alt="Scalability" className="img-fluid" />
                  </div>
                  <div className="usetitle">
                    <h5 className="use-title"> Scalability </h5>
                    <p>
                      {" "}
                      As your NFT Game grows, our solutions seamlessly
                      scale to accommodate increased traffic and demand.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-3">
                <div className="card ben-card">
                  <div className="bencard-img-tops">
                    <img
                      src={farm}
                      alt="User-Centric Design"
                      className="img-fluid"
                    />
                  </div>
                  <div className="usetitle">
                    <h5 className="use-title"> User-Centric Design</h5>
                    <p>
                      {" "}
                      Our user-friendly interfaces make navigating your NFT
                      Game a breeze for both creators and players.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-3">
                <div className="card ben-card">
                  <div className="bencard-img-tops">
                    <img
                      src={global}
                      alt="Blockchain Integration"
                      className="img-fluid"
                    />
                  </div>
                  <div className="usetitle">
                    <h5 className="use-title">Blockchain Integration</h5>
                    <p>
                      {" "}
                      We leverage blockchain technology for transparent,
                      immutable ownership records, instilling trust in your
                      users.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 mt-3">
                <div className="card ben-card">
                  <div className="bencard-img-tops">
                    <img
                      src={secure}
                      alt="Community Engagement"
                      className="img-fluid"
                    />
                  </div>
                  <div className="usetitle">
                    <h5 className="use-title"> Community Engagement </h5>
                    <p>
                      {" "}
                      Foster vibrant NFT communities with features that
                      encourage engagement and collaboration.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
       
        <section className="Overview">
          <div className="container">
            <div className="Overview">
              <div className="row">
                <div className="col-lg-7 ">
                  <h2>
                    Why Choose Block Intelligence As NFT Game
                    Development?
                  </h2>
                  <p>
                    With Block Intelligence, you get unmatched expertise in NFTs
                    and blockchain, tailored solutions, top-notch security, and
                    a commitment to building vibrant communities. Our
                    user-friendly interfaces and scalable designs ensure your
                    NFT Game excels. Join us to bring your NFT vision to
                    life with confidence and innovation.
                  </p>
                  <ul className="list wow fadeInDown" data-wow-delay="0.5s">
                    <li className="list">
                      Expertise NFT and Blockchain solution
                    </li>
                    <li className="list">Customization</li>
                    <li className="list">High Security</li>
                    <li className="list">Enhanced User Experience</li>
                    <li className="list">Blockchain Integration</li>
                    <li className="list">Scalability</li>
                  </ul>
                </div>

                <div className="col-lg-5 mx-auto ">
                  <img
                    src={require("../Images/whynft.webp")}
                    className="think d-block mx-auto"
                    alt="create your own nft game"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="process">
          <div className="container">
            <h3> Process of our NFT Game Development Service</h3>
            <ul class="timeline-with-icons list-unstyled mt-3">
              <li class="timeline-item mb-3">
                <div className="card-img-fiance nft-process-img">
                  <img
                    src={require("../Images/concept.webp")}
                    alt="Planning"
                    className="img-fluid"
                  />
                </div>
                <div class="timeline-content">
                  <h5 class="fw-bold"> Blockchain Integration</h5>{" "}
                  <p class="text-muted">
                  Our solution integrates seamlessly with popular blockchains like Ethereum, Binance Smart Chain, and Solana, ensuring transparency, security, and decentralized control of in-game assets.
                  </p>
                </div>
              </li>

              <li class="timeline-item mb-3">
                <div className="card-img-fiance nft-process-img">
                  <img
                    src={require("../Images/ui-design.webp")}
                    alt="Design & Development"
                    className="img-fluid"
                  />
                </div>
                <div class="timeline-content">
                  <h5 class="fw-bold">       Customizable NFTs</h5>
                  <p class="text-muted">
             We enable the creation of unique, fully customizable NFTs for in-game assets such as characters, items, and skins, enhancing player ownership and value.
                  </p>
                </div>
              </li>

              <li class="timeline-item mb-3">
                <div className="card-img-fiance nft-process-img">
                  <img
                    src={require("../Images/contract2.webp")}
                    alt="Smart Contracts"
                    className="img-fluid"
                  />
                </div>
                <div class="timeline-content">
                  <h5 class="fw-bold">Smart Contracts</h5>
                  <p class="text-muted">
                    We develop smart contracts that power the NFTs, ensuring
                    transparent and immutable ownership records.
                  </p>
                </div>
              </li>

              <li class="timeline-item mb-3">
                <div className="card-img-fiance nft-process-img">
                  <img
                    src={require("../Images/checklist.webp")}
                    alt="Testing & Quality Assurance"
                    className="img-fluid"
                  />
                </div>
                <div class="timeline-content">
                  <h5 class="fw-bold">  Interoperability</h5>
                  <p class="text-muted">
                  Interoperability: Our NFTs can be used across different games or platforms, promoting cross-platform engagement and broadening player reach.
                  </p>
                </div>
              </li>
              <li class="timeline-item mb-3">
                <div className="card-img-fiance nft-process-img">
                  <img
                    src={require("../Images/rocket2.webp")}
                    alt="Launch & Deployment"
                    className="img-fluid"
                  />
                </div>
                <div class="timeline-content">
                  <h5 class="fw-bold">         Play-to-Earn Mechanism</h5>
                  <p class="text-muted">
           Players can earn real-world value through in-game achievements or the sale of NFTs, creating additional engagement and incentives for playing.
                  </p>
                </div>
              </li>
      
             
            </ul>
          </div>
        </section>

       
      </div>
      <FillForm />

      <Footer />
    </>
  );
}
