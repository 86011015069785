import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function FillForm() {

  const [formdata, setFormdata] = useState({
    Name: "",
    Mobile: "",
    Email: "",
    Message: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormdata((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  const validate = () => {
    let isValid = true;
    const errors = {};

    if (!formdata.Name) {
      errors.Name = "Name is required";
      isValid = false;
    } else if (!/^[a-zA-Z]+$/.test(formdata.Name)) {
      errors.Name = "Name should contain only alphabets";
      isValid = false;
    }

    if (!formdata.Email) {
      errors.Email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formdata.Email)) {
      errors.Email = "Invalid email format";
      isValid = false;
    }

    if (!formdata.Mobile) {
      errors.Mobile = "Phone no is required";
      isValid = false;
    }

    if (!formdata.Message) {
      errors.Message = "message is required";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate register form
    const isValid = validate();

    if (isValid) {
      const { Name, Mobile, Email, Message } = formdata;

      const postData = {
        userName: Name,
        mobileValue: Mobile,
        email: Email,
        comments: Message,
      };


      console.log(postData,"postData");


      try {
        await axios.post("https://beleaftechnologies.com:3001/contact/sendMessageBlockintelligence", postData);
        console.log("Message sent Successfully ");
        toast.success("Message sent Successfully ");
        // Reset form
        setFormdata({
          Name: "",
          Mobile: "",
          Email: "",
          Message: "",
        });
      } catch (error) {
        console.error("Failed to send message", error);
        toast.error("Failed to send message");
      }
    } else {
      console.log("form validation failed");
    }
  };

  return (
    <>
    <section id="contact" className="form-sec">
        <div className="form-section" ></div>
        <div className="container form-container">
          <div className="row form-card">
          <div className="col-lg-6 col-md-6 form-img"></div>
            <div className="col-lg-6 col-md-6 form-top">
              <h3>Take The First Step Towards Collaboration</h3>
              <p>
                Experience smooth and efficient communication, fill out the form
                for free demo and blockchain related queries.
              </p>
              <div className="form" id="error">
                <form>
                  <div className=" row input-top">
                    <div className="col-lg-6 col-md-6">
                      <input
                        type="text"
                        name="Name"
                        id="name"
                        placeholder="Name"
                        value={formdata.Name}
                        onChange={handleChange}
                      />
                     {formErrors.Name && (
                  <span>{formErrors.Name}</span>
                )}
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <input
                        type="number"
                        name="Mobile"
                        id="phno"
                        placeholder="Phone No."
                        value={formdata.Mobile}
                        onChange={handleChange}
                      />
                     {formErrors.Mobile && (
                  <span>{formErrors.Mobile}</span>
                )}
                    </div>
                  </div>
                  <div className="form-email">
                    <input
                      type="email"
                      name="Email"
                      id="email"
                      placeholder="Email Address"
                      value={formdata.Email}
                      onChange={handleChange}
                    />
                   {formErrors.Email && (
                <span>{formErrors.Email}</span>
              )}
                  </div>
                  <div className="form-down">
                    <input
                      type="textarea"
                      name="Message"
                      id="message"
                      placeholder="Message"
                      value={formdata.Message}
                      onChange={handleChange}
                    />
                   {formErrors.Message && (
                <span>{formErrors.Message}</span>
              )}
                  </div>
                  <button onClick={handleSubmit} >Submit</button>
                </form>
              </div>
            </div>
            
          </div>
        </div>
      </section>
    </>
  )
}
