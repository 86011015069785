import React from "react";

export default function Contactbar() {
  return (
    <>
     <div class="footer_menyu_sss">
          <div class="link_section">
            <a href="https://wa.me/7738479381" target="_blank">
              <i class="fab fa-whatsapp blob"></i>
            </a>
            <span>whatsapp</span>
          </div>
          <div class="link_section">
            <a
              href="https://join.skype.com/invite/tm3uaTeSeHpF"
              target="_blank"
            >
              <i class="fab fa-skype blob"></i>
            </a>
            <span>Skype</span>
          </div>
          <div class="link_section">
            <a href="tel:+91 77384 79381" target="_blank">
              <i class="fas fa-phone blob"></i>
            </a>
            <span>Call</span>
          </div>
          <div class="link_section">
            <a href="mailto:connect@blockchain.ai.in" target="_blank">
              <i class="fas fa-envelope blob"></i>
            </a>
            <span>Mail</span>
          </div>
        </div>
    </>
  );
}
