import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import FillForm from "../Components/FillForm";
import Contactbar from "../Components/Contactbar";
import TechStackSlider from '../Components/TechStackSlider';

import "../Pages/Style.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";

export default function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [isScrolled]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isScrolled]);

  return (
    <>
      <Helmet>
        <title>Cryptocurrency Wallet Development Company</title>
        <meta
          property="title"
          content="Cryptocurrency Wallet Development Company
"
        />
        <link
          rel="canonical"
          href="https://www.blockintelligence.io/crypto-wallet-development-company"
        />
        <meta
          name="description"
          content="Block Intelligence is a leading cryptocurrency wallet development company that offers secure and customized solutions for your digital assets. We can help you create and manage your own crypto wallet with ease."
        />
      </Helmet>
      <Header />
      <Contactbar />
      <div className="token_head cryptowallet">
        <section className="p2p tokens ">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 banner_con simple-video mb-sx-2">
                <h2 className="wow fadeInUp h1tag">
                  OTC <span>Platform Development</span>
                </h2>
                <p className="wow fadeInUp mt-4">
                  Block Intelligence specializes in OTC platform development, providing secure and efficient solutions for trading digital assets. Our expert team can design and implement customized OTC platforms tailored to your business needs.
                </p>
                <div className="p2pbt mt-5">
                  <a href="#contact" className="button">
                    Get Demo
                  </a>
                </div>
              </div>
              <div className="col-lg-6 mt-5">
                <div className="">
                  <img
                    src={require("../Images/walletbanner1.webp")}
                    className="d-block mx-auto mt-5"
                    width="100%"
                    alt="p2p crypto exchange development company"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whatsmartcontract py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-5 mx-auto d-flex justify-content-center align-items-center">
                <img
                  src={require("../Images/walletbanner.webp")}
                  width="100%"
                  alt="p2p crypto exchange development company"
                />
              </div>
              <div className="col-lg-7 ">
                <div className="p2pcontent">
                  <h2>
                    Overview of <span>OTC Platform</span> Development
                  </h2>
                  <p>
                    OTC platform development involves creating a secure and user-friendly environment for trading cryptocurrencies directly between parties. This includes features such as order matching, escrow services, and compliance with regulatory standards.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract py-5">
          <div className="container py-5">
            <h2 className="mb-3 text-center">
              Benefits of <span>OTC Platform</span> Development
            </h2>

            <div className="row">
              <div className="col-lg-7 ">
                <div className="p2pcontent">
                  <p className="d-flex">
                    <img
                      src={require("../Images/walleticon.webp")}
                      width="45px"
                      className="walletpen mr-3"
                      alt="OTC platform development company"
                    />{" "}
                    High Liquidity for Large Trades: OTC platforms facilitate large cryptocurrency trades without causing price fluctuations in the public market, ensuring seamless execution of high-volume transactions.
                  </p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/walleticon.webp")}
                      width="45px"
                      className="walletpen mr-3"
                      alt="OTC platform development company"
                    />{" "}
                    Privacy and Confidentiality: Traders can conduct large trades without revealing their identities or transaction details, ensuring enhanced privacy and confidentiality compared to traditional exchanges.
                  </p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/walleticon.webp")}
                      width="45px"
                      className="walletpen mr-3"
                      alt="OTC platform development company"
                    />{" "}
                    Reduced Slippage: OTC platforms minimize slippage by matching buyers and sellers directly, avoiding the volatility and liquidity issues found on public exchanges.
                  </p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/walleticon.webp")}
                      width="45px"
                      className="walletpen mr-3"
                      alt="OTC platform development company"
                    />{" "}
                    Customized Trading Solutions: OTC platforms can be tailored to meet the needs of institutional investors or high-net-worth individuals, offering personalized service and terms.
                  </p>
                </div>
              </div>

              <div className="col-lg-5 mt-5 pt-5">
                <img
                  src={require("../Images/cryptowallet2.png")}
                  className="mx-auto d-block"
                  width="100%"
                  alt="p2p crypto exchange development company"
                />
              </div>
            </div>
          </div>
        </section>
       

        <section className="whatsmartcontract py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-7 ">
                <div className="p2pcontent">
                  <h2 className="mb-3 text-center">
                    Why Choose Block Intelligence for <span>OTC Platform</span>{" "}
                    Development?
                  </h2>
                  <p className="">
                    Block Intelligence is the best choice for OTC platform
                    development because
                  </p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/signpen.webp")}
                      width="30px"
                      className="signpen"
                      alt="OTC platform development company"
                    />{" "}
                    Expertise in OTC Solutions: Block Intelligence has a proven track record in developing highly secure and scalable OTC trading platforms tailored to meet specific business needs.
                  </p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/signpen.webp")}
                      width="30px"
                      className="signpen"
                      alt="OTC platform development company"
                    />{" "}
                    Customized Features: We offer customizable OTC platforms, allowing you to integrate features like multi-signature wallets, cold storage, and KYC/AML compliance to ensure secure and compliant transactions.
                  </p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/signpen.webp")}
                      width="30px"
                      className="signpen"
                      alt="OTC platform development company"
                    />{" "}
                    High Liquidity Management: Our team of experts can manage liquidity pools efficiently, ensuring smooth trading and minimal slippage.
                  </p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/signpen.webp")}
                      width="30px"
                      className="signpen"
                      alt="OTC platform development company"
                    />{" "}
                    Secure and Scalable: We use the latest technologies and tools to create secure and scalable OTC platforms that can handle high volumes of transactions without compromising security or performance.
                  </p>
                  <p className="d-flex">
                    <img
                      src={require("../Images/signpen.webp")}
                      width="30px"
                      className="signpen"
                      alt="OTC platform development company"
                    />{" "}
                    User-Friendly Interface: Our OTC platforms are designed with a user-friendly interface, making it easy for traders to navigate and execute trades efficiently.
                  </p>
                </div>
              </div>

              <div className="col-lg-5 mt-5 pt-5">
                <img
                  src={require("../Images/wallet3.webp")}
                  className="mx-auto d-block"
                  width="100%"
                  alt="p2p crypto exchange development company"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="p2pcontent text-center">
                  <h2>
                    How to Monetize with <span>OTC Platform</span> Development?
                  </h2>
                  <p className="text-center mt-3 mb-5">
                    Monetizing OTC platform development involves
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 px-5 py-2">
                <div className="whysmartcontract howwallet">
                  <img
                    src={require("../Images/money-transfer.webp")}
                    className="mb-3"
                    width="12%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Transaction Fees</h3>
                  <p>
                    Charge a fee for each transaction processed on your OTC platform. Fees can be set as a percentage of the transaction value or a flat rate for each trade.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 px-5 py-2">
                <div className="whysmartcontract howwallet">
                  <img
                    src={require("../Images/icons/smartquality.webp")}
                    className="mb-3"
                    width="12%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Spread</h3>
                  <p>
                    Earn revenue by setting a price difference (spread) between the buying and selling prices on the platform. This allows you to profit from the difference in prices without directly charging users.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 px-5 py-2">
                <div className="whysmartcontract howwallet">
                  <img
                    src={require("../Images/third-party (1).webp")}
                    className="mb-3"
                    width="12%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Subscription Plans</h3>
                  <p>
                    Offer premium membership or subscription services to users, providing access to exclusive features like lower fees, priority support, or enhanced security tools.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 px-5 py-2">
                <div className="whysmartcontract howwallet">
                  <img
                    src={require("../Images/icons/smarttranspanrency.webp")}
                    className="mb-3"
                    width="12%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Market-Making Services</h3>
                  <p>
                    Act as a market maker, providing liquidity to buyers and sellers on the platform. By holding and trading assets, you can earn from the spread between buying and selling prices.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 px-5 py-2">
                <div className="whysmartcontract howwallet">
                  <img
                    src={require("../Images/icons/smartsupport.webp")}
                    className="mb-3"
                    width="12%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>White-Label Solutions</h3>
                  <p>
                    License your OTC platform as a white-label solution to other businesses or financial institutions, generating revenue through licensing fees or ongoing service agreements.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 px-5 py-2">
                <div className="whysmartcontract howwallet">
                  <img
                    src={require("../Images/icons/smartsupport.webp")}
                    className="mb-3"
                    width="12%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Advisory and Consulting Services</h3>
                  <p>
                    Offer advisory services to high-net-worth individuals or institutional clients on OTC trading strategies and best practices, creating an additional revenue stream.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-12">
                <div className="p2pcontent text-center">
                  <h2>Take Away</h2>
                  <p className="text-center my-4">
                    OTC platform development is crucial for facilitating large-scale cryptocurrency transactions. It provides users with a secure, efficient, and customizable trading experience, enabling businesses to thrive in the digital asset market.
                  </p>
                </div>
                <div className="p2pbt">
                  <a href="#contact" className="button d-block mx-auto">
                    Hire Experts
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

      

        <section className="techstack2 stacz">
          <div className="container">
            <h2 className="tech-h2">Blockchain Tech Stacks</h2>
            <div className="stack">
              <p>
                Our team of blockchain developers are experts at building
                innovative blockchain solutions on the best available blockchain
                platform.
              </p>
            </div>
        <TechStackSlider/>
          </div>
        </section>
      </div>

      <FillForm />

      <Footer />
    </>
  );
}
