import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import FillForm from "../Components/FillForm";
import Contactbar from "../Components/Contactbar";

import "../Pages/Style.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import app from "../Images/cyber-security.webp";
import smartcontract from "../Images/settings.webp";
import stake from "../Images/flexibility.webp";
import farm from "../Images/browser.webp";
import global from "../Images/blockchain.webp";
import secure from "../Images/resolution.webp";
import { Helmet } from "react-helmet";

export default function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [isScrolled]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isScrolled]);

  return (
    <>
      <Helmet>
        <title>Incubation & Funding - Block Intelligence</title>
        <link
          rel="canonical"
          href="https://www.blockintelligence.io/incubation-funding"
        />
        <meta
          property="title"
          content="Leading Incubation & Funding Services - Block Intelligence"
        />
        <meta
          name="description"
          content="Block Intelligence, the top Incubation & Funding service provider, pioneering innovative solutions for startups. Explore our incubation and funding services."
        />
        <meta
          name="keywords"
          content="incubation services, funding solutions, startup funding, business incubation, venture capital, startup support"
        />
      </Helmet>
      <Header />
      <Contactbar />
      <div className="nft_head">
        <section className="nft">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 banner_con simple-video mb-sx-2">
                <h1 className="wow fadeInUp h1tag">
                  Incubation & Funding Services
                </h1>
                <p className="wow fadeInUp">
                  At Block Intelligence, we specialize in providing
                  comprehensive incubation and funding services for startups.
                  Our mission is to support innovative ideas and help
                  entrepreneurs navigate the complexities of launching their
                  ventures. With our expertise, we empower businesses to thrive
                  in a competitive landscape.
                </p>

                <a href="#contact" className="button">
                  Get Demo
                </a>
              </div>
              <div className="col-lg-6 mx-auto mt-4 d-flex ">
                <div className="wow zoomIn">
                  <div className="banner_i">
                    <img
                      src={require("../Images/card1.webp")}
                      alt="NFT marketplace development services"
                      data-wow-delay="0.2s"
                      data-wow-iteration="infinite"
                      data-wow-duration="8s"
                      className="nft-banner1 img-fluid mx-auto"
                    />
                    <img
                      src={require("../Images/card2.webp")}
                      alt="NFT marketplace development services"
                      className="nft-banner2 img-fluid"
                    />
                    <img
                      src={require("../Images/card3.webp")}
                      alt="NFT marketplace development services"
                      className="nft-banner3 img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="Overview">
          <div className="container">
            <div className="Overview">
              <div className="row">
                <div className="col-lg-5 mx-auto mt-4 d-flex justify-content-center align-items-center">
                  <img
                    src={require("../Images/overvieew.webp")}
                    className="overimg"
                    alt="NFT Marketplace"
                  />
                </div>
                <div className="col-lg-7 ">
                  <h2>Overview of Incubation & Funding</h2>

                  <p>
                    At Block Intelligence, we provide tailored incubation and
                    funding solutions designed to empower startups. Our approach
                    combines strategic guidance with financial support, ensuring
                    that innovative ideas have the resources they need to
                    thrive. We understand the challenges entrepreneurs face and
                    offer a comprehensive suite of services, from mentorship to
                    investment opportunities, to help navigate the complexities
                    of launching a successful venture. Join us in transforming
                    your vision into reality with our expert support in
                    incubation and funding.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className=" Marketplace">
          <div className="container">
            <h2 className="text-center">
              Our Metaverse NFT Development Services
            </h2>

            <div className="row mt-2">
              <div className="col-lg-8">
                <p className="p-5">
                  At Block Intelligence, we provide comprehensive incubation and
                  funding solutions tailored to empower startups. Our services
                  include mentorship, strategic guidance, and access to funding
                  opportunities that help entrepreneurs turn their innovative
                  ideas into successful businesses. We are dedicated to
                  fostering a supportive environment where creativity and growth
                  can flourish, ensuring that your venture has the resources it
                  needs to thrive in today's competitive landscape.
                </p>
              </div>
              <div className="col-lg-4 mx-auto d-block">
                <img
                  src={require("../Images/nftimg.webp")}
                  className="ourimg"
                  alt="NFT marketplace development services"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="Overview">
          <div className="container">
            <div className="Overview">
              <div className="row">
                <div className="col-lg-7 ">
                  <h2>
                    Why Choose Block Intelligence for Incubation & Funding?
                  </h2>
                  <p>
                    At Block Intelligence, we offer unparalleled expertise in
                    incubation and funding, providing tailored solutions, robust
                    support, and a commitment to nurturing innovative startups.
                    Our strategic guidance and financial backing ensure your
                    venture has the resources it needs to succeed. Join us to
                    transform your ideas into reality with confidence and
                    support.
                  </p>
                  <ul className="list wow fadeInDown" data-wow-delay="0.5s">
                    <li className="list">
                      Expert Guidance in Incubation and Funding
                    </li>
                    <li className="list">Tailored Solutions</li>
                    <li className="list">Robust Support</li>
                    <li className="list">Strategic Partnerships</li>
                    <li className="list">Access to Funding Opportunities</li>
                    <li className="list">Growth and Scalability</li>
                  </ul>
                </div>

                <div className="col-lg-5 mx-auto ">
                  <img
                    src={require("../Images/whynft.webp")}
                    className="think d-block mx-auto"
                    alt="Incubation and Funding Services"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="process">
          <div className="container">
            <h3> Process of our Incubation & Funding Services</h3>
            <ul class="timeline-with-icons list-unstyled mt-3">
              <li class="timeline-item mb-3">
                <div className="card-img-fiance nft-process-img">
                  <img
                    src={require("../Images/concept.webp")}
                    alt="Planning"
                    className="img-fluid"
                  />
                </div>
                <div class="timeline-content">
                  <h5 class="fw-bold">Concept & Planning</h5>{" "}
                  <p class="text-muted">
                    We begin by understanding your vision and goals for your
                    startup. Together, we plan the features, design, and
                    functionality of your business model.
                  </p>
                </div>
              </li>

              <li class="timeline-item mb-3">
                <div className="card-img-fiance nft-process-img">
                  <img
                    src={require("../Images/ui-design.webp")}
                    alt="Design & Development"
                    className="img-fluid"
                  />
                </div>
                <div class="timeline-content">
                  <h5 class="fw-bold">Funding Strategy Development</h5>
                  <p class="text-muted">
                    Our team helps you create a funding strategy, identifying
                    potential investors and crafting compelling pitches to
                    secure the necessary capital.
                  </p>
                </div>
              </li>

              <li class="timeline-item mb-3">
                <div className="card-img-fiance nft-process-img">
                  <img
                    src={require("../Images/contract2.webp")}
                    alt="Smart Contracts"
                    className="img-fluid"
                  />
                </div>
                <div class="timeline-content">
                  <h5 class="fw-bold">Investor Relations</h5>
                  <p class="text-muted">
                    We develop strong relationships with investors, ensuring
                    transparent communication and alignment of interests
                    throughout the funding process.
                  </p>
                </div>
              </li>

              <li class="timeline-item mb-3">
                <div className="card-img-fiance nft-process-img">
                  <img
                    src={require("../Images/checklist.webp")}
                    alt="Testing & Quality Assurance"
                    className="img-fluid"
                  />
                </div>
                <div class="timeline-content">
                  <h5 class="fw-bold">Due Diligence Support</h5>
                  <p class="text-muted">
                    We assist in preparing for due diligence, ensuring all
                    necessary documentation and information is ready for
                    potential investors.
                  </p>
                </div>
              </li>
              <li class="timeline-item mb-3">
                <div className="card-img-fiance nft-process-img">
                  <img
                    src={require("../Images/rocket2.webp")}
                    alt="Launch & Deployment"
                    className="img-fluid"
                  />
                </div>
                <div class="timeline-content">
                  <h5 class="fw-bold">Launch & Support</h5>
                  <p class="text-muted">
                    We help you launch your startup, providing ongoing support
                    and guidance to ensure your business thrives post-funding.
                  </p>
                </div>
              </li>
              <li class="timeline-item mb-3">
                <div className="card-img-fiance nft-process-img">
                  <img
                    src={require("../Images/plan.webp")}
                    alt="Maintenance & Updates"
                    className="img-fluid"
                  />
                </div>
                <div class="timeline-content">
                  <h5 class="fw-bold">Ongoing Support & Growth</h5>
                  <p class="text-muted">
                    We offer ongoing support, updates, and strategic advice to
                    help your startup grow and adapt in a changing market.
                  </p>
                </div>
              </li>
              <li class="timeline-item mb-3">
                <div className="card-img-fiance nft-process-img">
                  <img
                    src={require("../Images/growth.webp")}
                    alt="Marketing & Growth"
                    className="img-fluid"
                  />
                </div>
                <div class="timeline-content">
                  <h5 class="fw-bold">Marketing & Growth Strategies</h5>
                  <p class="text-muted">
                    Our services extend to developing marketing strategies and
                    growth initiatives to help you reach a broader audience and
                    maximize your startup's potential.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </section>

      </div>
      <FillForm />

      <Footer />
    </>
  );
}
