import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './Pages/Home'
import Blockchain from './Pages/Blockchain'
import AI from './Pages/AI'
import PageNotfound from './Pages/Page_Not_found'
import CentralizedCex from './Pages/Centralized cex'
import Defi from './Pages/Defi'
import Nft from './Pages/nft'
import P2p from './Pages/p2p'
import Dex from './Pages/Dex';
import Gamedevelopment from './Pages/game'
import Metaverse from './Pages/metaverse'
import Token from './Pages/Token'
import Smartcontract from './Pages/smartcontract'
import Comingsoon from './Pages/Coming_soon'
import Web3 from './Pages/web3'
import Arbitragebot from './Pages/Arbitragebot'
import CryptoWallet from './Pages/Cryptowallet'
import Paymentgateway from './Pages/Paymentgateway'
import Opensea from './Pages/Opensea'
import Rarible from './Pages/Rarible'
import Cryptoexchage from './Pages/CryptoExchange'
import Layer from './Pages/mainLayer.js'
import Layer0 from './Pages/Layer0'
import Layer1 from './Pages/Layer1.js'
import Layer2 from './Pages/Layer2.js'
import BinanceClone from './Pages/binanceClone'
import CoinbaseClone from './Pages/coinbaseClone.js'
import WazirxClone from './Pages/wazirxClone.js'
import PaxfulClone from './Pages/paxfulClonne.js'
import  UniClone from './Pages/uniswap.js'
import PancakeClone from './Pages/PanCakes.js'
import Memecoin from './Pages/MemeCoin.js'
import Otc from './Pages/otc.js'
import  Whitecrypto from './Pages/whitecrypto.js'
import Defiexchange from './Pages/DefiEchange.js'
import  Defistack from './Pages/DefiStake.js'
import  Defilennd from './Pages/defiLending'
import IOC from './Pages/Icotoken.js'
import IOE from './Pages/Ioe.js'
import STO  from './Pages/StoToken.js' // Corrected casing
import BEP20 from './Pages/BEP20.js' // Added missing file extension
import ERC20 from './Pages/ERC20.js'
import Cryptolist from './Pages/cryptolisting.js'
import  CryptoMarket from './Pages/cryptoMarket.js'
import  P2E from './Pages/P2e.js'
import  Graph from './Pages/Graphicdesign.js'
import Nfts from './Pages/nftgame.js'
import Nftverse from './Pages/MetaverseNft.js'
import Incubation from './Pages/Incubation.js'
import Betfury from './Pages/Betfury'
import Web3d from './Pages/web3dev'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={< Home />} />
          <Route path="/blockchain" element={< Blockchain />} />
          <Route path="/ai" element={< AI />} />
          <Route path="*" element={< PageNotfound />} />
          <Route path="/soon" element={< Comingsoon />} />
          <Route path="/rarible-clone-script" element={< Rarible />} />
          <Route path="/opensea-clone-script" element={< Opensea />} />
          <Route path="/crypto-trading-bot-development" element={< Arbitragebot />} />
          <Route path="/crypto-exchange-development-company" element={< Cryptoexchage />} />

          <Route path="/crypto-wallet-development-company" element={< CryptoWallet />} />
          <Route path="/crypto-paymentgateway-development-company" element={< Paymentgateway/>} />
          <Route path="/centralized-crypto-exchange-development-company" element={< CentralizedCex />} />
          <Route path="/defi-development-company" element={< Defi />} />
          <Route path="/nft-marketplace-development-company" element={< Nft />} />
          <Route path="/p2p-crypto-exchange-development" element={< P2p />} />
          <Route path="/dex-development-company" element={< Dex />} />
          <Route path="/metaverse-development-company" element={< Metaverse />} />
          <Route path="/web3-game-development-company" element={< Gamedevelopment />} />
          <Route path="/crypto-token-development" element={< Token />} />
          <Route path="/smart-contract-audit-company" element={< Smartcontract />} />
          <Route path="/Blockchain-Development-Company" element={<  Layer/>} />
          <Route path="/Layer-0-Blockchain-Development-Company" element={<  Layer0/>} />
          <Route path="/Layer-1-Blockchain-Development-Company" element={<  Layer1/>} />
          <Route path="/Layer-2-Blockchain-Development-Company" element={<  Layer2/>} />
          <Route path="/Binance-clone-script" element={<  BinanceClone/>} />
          <Route path="/coinbase-clone-script" element={<  CoinbaseClone/>} />
          <Route path="/wazirx-clone-script" element={< WazirxClone />} />
          <Route path="/Paxful-clone-script" element={< PaxfulClone />} />
          <Route path="/uniswap-clone-script" element={< UniClone />} />
          
          <Route path="/Pancakeswap-clone-script" element={< PancakeClone />} />
          <Route path="/Memecoin development" element={< Memecoin />} /> 
          <Route path="/Otc-development" element={< Otc />} /> 
          <Route path="/White-crypto" element={<  Whitecrypto />} /> 
          <Route path="/defi-exchange" element={<  Defiexchange/>} />
          <Route path="/defi-stacking" element={<  Defistack/>} />
          <Route path="/defi-lending" element={<  Defilennd />} />  
          <Route path="/Ico-token" element={< IOC />} />
          <Route path="/Ioe-token" element={< IOE />} />      
          <Route path="/STO-token" element={< STO />} />  
          <Route path="/ERC20-token" element={< ERC20 />} />  
          <Route path="/BEP20-token" element={<   BEP20/>} />  
          <Route path="/Crypto-list" element={<  Cryptolist />} />  
          <Route path="/Blockchainn-Market" element={<  CryptoMarket />} />  
          <Route path="/P2E-games" element={<  P2E />} />  
          <Route path="/Graphic-design" element={<  Graph />} /> 
          <Route path="/nft-games" element={<  Nfts />} /> 
          <Route path="/Metaverse" element={<  Nftverse />} /> 
          <Route path="/Incubation & Funding" element={<   Incubation />} /> 
          <Route path="/Betfury" element={<  Betfury />} /> 
          <Route path="/Web3-development" element={<  Web3d />} /> 
          
         
         

        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </div>
  )
}
