import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import FillForm from "../Components/FillForm";
import TechStackSlider from '../Components/TechStackSlider';
import Contactbar from "../Components/Contactbar";

import "../Pages/Style.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";

export default function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [isScrolled]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isScrolled]);

  return (
    <>
      <Helmet>
        <title>Crypto Listing Agency</title>
        <meta property="title" content="Crypto Listing Agency" />
        <meta
          name="description"
          content="Block Intelligence is a crypto listing agency that provides reliable services for listing your blockchain projects on various platforms. We help you enhance your project's visibility and reach."
        />
        <link
          rel="canonical"
          href="https://www.blockintelligence.io/crypto-listing-agency"
        />
      </Helmet>
      <Header />
      <Contactbar />
      <div className="token_head smartcontractaudit">
        <section className="p2p tokens ">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 banner_con simple-video mb-sx-2">
                <h1 className="wow fadeInUp h1tag">
                  <span>Crypto Listing</span> Agency
                </h1>
                <p className="wow fadeInUp mt-4">
                  Block Intelligence is a leading crypto listing agency that
                  provides comprehensive and reliable services for
                  blockchain-based projects. We help you enhance your project's
                  visibility and reach across various platforms.
                </p>
                <div className="p2pbt mt-5">
                  <a href="#contact" className="button">
                    Get Listed
                  </a>
                </div>
              </div>
              <div className="col-lg-6 mt-5">
                <div className="">
                  <img
                    src={require("../Images/smartcontractbg.webp")}
                    className="d-block mx-auto mt-5"
                    width="100%"
                    alt="p2p crypto exchange development company"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whatsmartcontract py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-5 mx-auto d-flex justify-content-center align-items-center">
                <img
                  src={require("../Images/smartcontract2.webp")}
                  className="p2pimg"
                  alt="p2p crypto exchange development company"
                />
              </div>
              <div className="col-lg-7 ">
                <div className="p2pcontent">
                  <h2>
                    Overview of <span>Crypto Listing Agency</span> by Block
                    Intelligence
                  </h2>
                  Block Intelligence’s Crypto Listing Agency is your trusted
                  partner for getting your token listed on the world’s top
                  cryptocurrency exchanges. We specialize in providing
                  end-to-end solutions that simplify the listing process and
                  maximize your token’s exposure to the global market. Our
                  services cover everything from strategic planning and
                  compliance documentation to negotiation with exchanges and
                  post-listing marketing. With a deep understanding of the
                  crypto landscape, our team helps you navigate the complex
                  requirements and regulatory challenges of token listings. By
                  choosing Block Intelligence, you ensure a seamless listing
                  experience, increased liquidity, and enhanced market
                  credibility for your crypto project. We aim to make your token
                  stand out in an increasingly competitive space while ensuring
                  that all legal and compliance needs are met efficiently.
                  Whether you're launching a new project or expanding an
                  existing one, we provide the expertise and connections to help
                  your token succeed.
                </div>
              </div>
            </div>
          </div>
        </section>
       

     

        <section className="whatsmartcontract py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="p2pcontent text-center">
                  <h2>
                    Why Choose Block Intelligence For{" "}
                    <span>Crypto Listing</span> Services
                  </h2>
                  <p className="text-center mt-3 mb-5">
                    Block Intelligence is a premier crypto listing agency that specializes in providing tailored services for blockchain projects. Our expert team ensures the quality, reliability, and visibility of your crypto projects, making us a trusted partner in the industry.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 px-5 py-2">
                <div className="whysmartcontract">
                  <img
                    src={require("../Images/icons/smartexpertise.webp")}
                    className=""
                    width="12%"
                    alt="crypto listing expertise"
                  />
                  <h3>Expertise</h3>
                  <p>
                    Our team consists of seasoned professionals with extensive knowledge in crypto listings and market strategies. They are proficient in various blockchain platforms and understand the nuances of effective project visibility.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 px-5 py-2">
                <div className="whysmartcontract">
                  <img
                    src={require("../Images/icons/smartquality.webp")}
                    className=""
                    width="12%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Quality</h3>
                  <p>
                    We follow a rigorous and systematic approach to crypto project listings, utilizing both manual and automated methods to ensure the highest standards.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 px-5 py-2">
                <div className="whysmartcontract">
                  <img
                    src={require("../Images/icons/smartsecurity.webp")}
                    className=""
                    width="12%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Security</h3>
                  <p>
                    We adhere to the highest standards of security and confidentiality in our crypto listing services, ensuring your project data is protected.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 px-5 py-2">
                <div className="whysmartcontract">
                  <img
                    src={require("../Images/icons/smarttranspanrency.webp")}
                    className=""
                    width="12%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Transparency</h3>
                  <p>
                    We provide you with a detailed and comprehensive listing report that contains all the necessary information.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 px-5 py-2">
                <div className="whysmartcontract">
                  <img
                    src={require("../Images/icons/smartsupport.webp")}
                    className=""
                    width="12%"
                    alt="p2p crypto exchange development company"
                  />
                  <h3>Support</h3>
                  <p>
                    We provide you with ongoing support and assistance after the
                    completion of the smart contract audit.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

     


       
        <section className="techstack2 stacz">
          <div className="container">
            <h2 className="tech-h2">Blockchain Tech Stacks</h2>
            <div className="stack">
              <p>
                Our team of blockchain developers are experts at building
                innovative blockchain solutions on the best available blockchain
                platform.
              </p>
            </div>
           <TechStackSlider/>
          </div>
        </section>
      </div>

      <FillForm />

      <Footer />
    </>
  );
}
