import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import FillForm from "../Components/FillForm";
import Contactbar from "../Components/Contactbar";

import "../Pages/Style.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import exchange from "../Images/exchange.webp";
import wallet from "../Images/wallet.webp";
import lending from "../Images/lending.webp";
import token from "../Images/token.webp";
import app from "../Images/mobile-development.webp";
import smartcontract from "../Images/smart-contract.webp";
import stake from "../Images/stake.webp";
import farm from "../Images/bitcoin-mining.webp";
import data from "./blockchain.json";
import { Helmet } from "react-helmet";
import TechStackSlider from '../Components/TechStackSlider';

export default function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [isScrolled]);



  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        const currentSlide = sliderRef.current.slickCurrentSlide();
        const totalSlides = sliderRef.current.slickGetSlick().slideCount;

        if (currentSlide === totalSlides - 1) {
          // If it's the last slide, quickly jump to the first slide
          sliderRef.current.slickGoTo(0, true); // true for instant transition
        } else {
          sliderRef.current.slickNext(); // Move to the next slide
        }
      }
    }, 2000); // Set interval for checking every 2 seconds

    return () => clearInterval(interval);
  }, [isScrolled]);

  return (
    <>
      <Helmet>
        <title>Layer2 Blockchain development - Block Intelligence </title>
        <meta
          property="title"
          content="Leading Development Company - Block Intelligence"
        />
        <link
          rel="canonical"
          href="https://www.blockintelligence.io/blockchain-development-company"
        />

        <meta
          name="description"
          content="Discover Block Intelligence, a top DeFi Development Company, specializing in creating transformative solutions for decentralized finance. Explore our DeFi services and innovative approaches today."
        />
        <meta
          name="keywords"
          content="DeFi development company, DeFi development Services, DeFi Development Solution, Decentralized finance development, DeFi development, DeFi Exchange Platform Development, DeFi Lending Platform Development, DeFi Smart Contract Development, DeFi Clone Script, Benefits of DeFi development, Features of DeFi development, Decentralized Finance Development Service."
        />
      </Helmet>
      <Header />
      <Contactbar />
      <section className="Defi">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 banner_con simple-video mb-sx-2">
              <h1 className="welcome wow fadeInUp h1tag">
                Layer 2 Blockchain development
              </h1>
              <p className="wow fadeInUp">
                We specialize in developing scalable, secure, and
                high-performance Layer 2 solutions that enable faster and more
                efficient transactions on Layer 1 blockchains like Ethereum,
                Bitcoin, and others. Our Layer 2 technologies are designed to
                solve the scalability challenges of blockchain, ensuring that
                your decentralized applications (dApps) and services can operate
                seamlessly and at scale.
              </p>
              <div className="justify-content-center mt-5">
                <a href="#contact" className="button">
                  Get Demo
                </a>
              </div>
            </div>
            <div className="col-lg-6 mx-auto mt-4 d-flex justify-content-center align-items-center">
              <div
                className="wow zoomIn"
                data-wow-duration="2s"
                data-wow-offset="100"
              >
                <div
                  className="banner_i"
                  data-wow-delay="0.2s"
                  data-wow-iteration="infinite"
                  data-wow-duration="2s"
                >
                  <img
                    src={require("../Images/defi.webp")}
                    alt="DeFi Exchange Platform Development"
                    className="defi-banner d-block mx-auto img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="whatdefi ">
        <div className="container ">
          <div className="whatdefi ">
            <div className="row">
              <div className="col-lg-6 mx-auto d-flex justify-content-center align-items-center">
                <img
                  src={require("../Images/whatdefi.webp")}
                  className="whatimg"
                  alt="Defi"
                />
              </div>
              <div className="col-lg-6">
                <h2>What is Layer 2?</h2>
                <p>
                  {" "}
                  Layer 2 refers to a secondary framework or protocol built on
                  top of an existing Layer 1 blockchain. The goal of Layer 2 is
                  to enhance the scalability and efficiency of Layer 1 networks
                  by offloading transaction processing, while maintaining the
                  security and decentralization of the underlying blockchain.
                  Layer 2 solutions such as rollups, state channels, and
                  sidechains reduce transaction costs and increase throughput,
                  making it possible to process more transactions per second
                  without overloading the Layer 1 blockchain. At Block
                  Intelligence, we specialize in creating Layer 2 protocols that
                  ensure scalability without compromising security.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="usecases">
        <div className="container">
          <h2>Our deveopment services</h2>

          <div className="row servicess">
            <div className="col-lg-3 mt-3">
              <div className="card usecase-card">
                <div className="usecard-img-tops">
                  <img
                    src={exchange}
                    alt="DeFi Exchange Platform Development"
                    className="img-fluid"
                  />
                </div>
                <div className="usetitle">
                  <h5 className="use-title">Infrastructure Development</h5>
                  <p>
                    {" "}
                    Our custom solutions ensure seamless communication between
                    various blockchain networks, supporting both public and
                    private blockchain ecosystems.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 mt-3">
              <div className="card usecase-card1">
                <div className="usecard-img-tops">
                  <img
                    src={lending}
                    alt="DeFi Lending Platform Development"
                    className="img-fluid"
                  />
                </div>
                <div className="usetitle">
                  <h5 className="use-title text-dark">
                    {" "}
                    Cross-Chain Interoperability
                  </h5>
                  <p className="text-dark">
                    {" "}
                    Our interoperability protocols enable secure and efficient
                    data exchange between blockchain platforms, facilitating
                    cross-chain transactions and DeFi operations with
                    reliability.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mt-3">
              <div className="card usecase-card">
                <div className="usecard-img-tops">
                  <img
                    src={token}
                    alt="DeFi Token Development"
                    className="img-fluid"
                  />
                </div>
                <div className="usetitle">
                  <h5 className="use-title"> Custom Blockchain Development</h5>
                  <p>
                    {" "}
                    We provide comprehensive blockchain development services,
                    creating custom Layer 0 solutions for various industries,
                    ensuring your dApps perform efficiently on a scalable
                    infrastructure.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mt-3">
              <div className="card usecase-card1">
                <div className="usecard-img-tops">
                  <img
                    src={wallet}
                    alt="DeFi Wallet Development"
                    className="img-fluid"
                  />
                </div>
                <div className="usetitle">
                  <h5 className="use-title text-dark">
                    {" "}
                    Blockchain Scalability Solutions{" "}
                  </h5>
                  <p className="text-dark">
                    Scalability is a key challenge for blockchain networks. Our
                    Layer 0 development helps businesses overcome congestion by
                    creating a multi-chain environment that enhances transaction
                    speed and reduces fees.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row servicess">
            <div className="col-lg-3 mt-3">
              <div className="card usecase-card1">
                <div className="usecard-img-tops">
                  <img
                    src={app}
                    alt="DeFi DApp Development"
                    className="img-fluid"
                  />
                </div>
                <div className="usetitle">
                  <h5 className="use-title text-dark">
                    {" "}
                    Blockchain Security Solutions
                  </h5>
                  <p className="text-dark">
                    {" "}
                    At Block Intelligence, security is paramount. We use
                    advanced encryption, consensus algorithms, and
                    zero-knowledge proofs (ZKPs) to protect your Layer 0
                    infrastructure from cyber threats.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-3 mt-3">
              <div className="card usecase-card">
                <div className="usecard-img-tops">
                  <img
                    src={smartcontract}
                    alt="DeFi Smart Contract Development"
                    className="img-fluid"
                  />
                </div>
                <div className="usetitle">
                  <h5 className="use-title">
                    Blockchain Consulting & Strategy
                  </h5>
                  <p>
                    {" "}
                    Our team provides expert blockchain consulting, offering
                    strategic guidance on how to best utilize Layer 0 technology
                    for your business. From concept to implementation, we help
                    you design a solution that meets your unique objectives
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="devservices">
        <div className="container">
          <div className="dev head">
            <h2>Layers Transformative Potential Across Industries</h2>
          </div>

          <div className="row ">
            <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/bank.webp")}
                    alt="Finance and Banking"
                    className="img-fluid serimg"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Finance and Banking</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/bitcoin.webp")}
                    alt="Cryptocurrency"
                    className="img-fluid serimg1"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Cryptocurrency</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/real-estate.webp")}
                    alt="Real Estate"
                    className="img-fluid serimg"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Real Estate</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/supply-chain.webp")}
                    alt="Supply Chain"
                    className="img-fluid serimg1"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Supply Chain</h5>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/patient.webp")}
                    alt="Healthcare"
                    className="img-fluid serimg1"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Healthcare</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/insurance.webp")}
                    alt="Insurance"
                    className="img-fluid serimg"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Insurance</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/market.webp")}
                    alt="Agriculture"
                    className="img-fluid serimg1"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Agriculture</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/creativity.webp")}
                    alt="Art and Collectibles"
                    className="img-fluid serimg"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Art and Collectibles</h5>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/joystick.webp")}
                    alt="Gaming"
                    className="img-fluid serimg"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Gaming</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/learning.webp")}
                    alt="Education"
                    className="img-fluid serimg1"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Education</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/travel (1).webp")}
                    alt="Hospitality & Travel"
                    className="img-fluid serimg"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Hospitality and Travel</h5>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6   mt-5">
              <div className="hexagon-card">
                <div className="card-img-tops">
                  <img
                    src={require("../Images/question.webp")}
                    alt="DeFi Exchange Platform Development"
                    className="img-fluid serimg1"
                  />
                </div>
              </div>
              <div className="featuretext mt-3 ">
                <h5 className="card-title">Legal Services</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="benfits-defi">
        <div className="container">
          <div className="key-feature-cex-content">
            <h2 className="h2-mobile h2-md">
              Benefits of Our  Development Services
            </h2>
          </div>
          <div className="key-feature-cex-card-row">
            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-6">
                <div className="key-feature-cex-card">
                  <img
                    src={require("../Images/secure-payment.webp")}
                    className="card-img"
                    alt="Security"
                  />
                  <p className="key-feature-cex-card-title">
                    Blockchain Expertise
                  </p>
                  <p
                    className="key-feature-cex-card-desc"
                    style={{ fontSize: "18px", paddingTop: "10px" }}
                  >
                    We are pioneers in Layer 0 blockchain development, with a
                    deep understanding of the complexities involved in creating
                    scalable and interoperable blockchain systems.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="key-feature-cex-card">
                  <img
                    src={require("../Images/transparency.webp")}
                    className="card-img"
                    alt="Transparency"
                  />
                  <p className="key-feature-cex-card-title">
                    {" "}
                    Scalable and Interoperable Solutions
                  </p>
                  <p
                    className="key-feature-cex-card-desc"
                    style={{ fontSize: "18px", paddingTop: "10px" }}
                  >
                    Scalable and Interoperable Solutions Our focus on
                    scalability ensures your blockchain network can adapt to
                    market demands and handle increased transaction volumes.
                  </p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="key-feature-cex-card">
                  <img
                    src={require("../Images/world.webp")}
                    className="card-img"
                    alt="Global Research"
                  />
                  <p className="key-feature-cex-card-title">
                    {" "}
                    Security-Driven Blockchain Solutions{" "}
                  </p>
                  <p
                    className="key-feature-cex-card-desc"
                    style={{ fontSize: "18px", paddingTop: "10px" }}
                  >
                    At Block Intelligence, we embed security in every layer of
                    our solutions, ensuring your Layer 0 architecture is
                    protected from vulnerabilities as your network expands.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="key-feature-cex-card">
                  <img
                    src={require("../Images/profits.webp")}
                    className="card-img"
                    alt="Profit"
                  />
                  <p className="key-feature-cex-card-title">
                    {" "}
                    End-to-End Blockchain Development Services{" "}
                  </p>
                  <p
                    className="key-feature-cex-card-desc"
                    style={{ fontSize: "18px", paddingTop: "10px" }}
                  >
                    We offer comprehensive services from consultation to
                    deployment delivering tailor-made Layer 0 that align
                    perfectly with your business needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sectionss">
        <div className="container">
          <h2 className="h2">Our Blockchain Development Process</h2>

          <div className="row mt-5">
            <div className="col-lg-6 pleft fade-up">
              <div className="d-flex flex-column align-items-end">
                <div class="cloud-container">
                  <img
                    src={require("../Images/idea.webp")}
                    className="process-img img-fluid"
                    alt="DeFi Development Solution"
                  />
                  /
                </div>
              </div>
            </div>

            <div className="col-lg-6 pright fade-up">
              <div className="align-items-center justify-content-start">
                <h3 className="processhead mb-3"> Consultation & Strategy</h3>
                <p className="processpara mb-0">
                  Our team then identifies how Layer 0 blockchain technology can
                  best serve your use case.
                </p>
              </div>
            </div>
          </div>

          <div class="row mt-5 processeven">
            <div class="col-lg-6 pright fade-down order-md-1">
              <div class="align-items-center justify-content-start">
                <h3 class="processhead mb-3 mt-5">Design & Development</h3>
                <p class="processpara mb-0">
                  Our experts craft smart contracts, UI/UX, and backend systems.
                </p>
              </div>
            </div>
            <div class="col-lg-6 pleft fade-down order-md-2">
              <div class="d-flex flex-column align-items-start">
                <div class="cloud-container">
                  <img
                    src={require("../Images/html-code.webp")}
                    class="process-img img-fluid"
                    alt="Design & Development"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-lg-6 pleft fade-up mt-5">
              <div className="d-flex flex-column align-items-end">
                <div class="cloud-container">
                  <img
                    src={require("../Images/testing.webp")}
                    className="process-img img-fluid"
                    alt="Testing & Auditing"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 pright fade-up">
              <div className="align-items-center justify-content-start">
                <h3 className="processhead mb-3 mt-5">Testing & Auditing</h3>
                <p className="processpara mb-0">
                  Rigorous testing and third-party audits ensure rock-solid
                  security.
                </p>
              </div>
            </div>
          </div>

          <div class="row mt-5 processeven">
            <div className="col-lg-6 pright fade-down">
              <div className="align-items-center justify-content-start">
                <h3 className="processhead mb-3 mt-5">Deployment</h3>
                <p className="processpara mb-0">
                  We launch your platform into the blockchain ecosystem.
                </p>
              </div>
            </div>
            <div className="col-lg-6 pleft fade-down">
              <div className="d-flex flex-column align-items-start">
                <div class="cloud-container">
                  <img
                    src={require("../Images/cloud.webp")}
                    className="process-img img-fluid"
                    alt="Deployment"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-5 ">
            <div className="col-lg-6 pleft fade-up">
              <div className="d-flex flex-column align-items-end">
                <div class="cloud-container">
                  <img
                    src={require("../Images/web-maintenance.webp")}
                    className="process-img img-fluid"
                    alt="Maintenance & Support"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 pright fade-up">
              <div className="align-items-center justify-content-start">
                <h3 className="processhead mb-3 mt-5">Maintenance & Support</h3>
                <p className="processpara mb-0">
                  We're with you post-launch, providing maintenance and support.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="whatdefi">
        <div className="container">
          <div className="whatdefi">
            <div className="row">
              <div className="col-lg-7 ">
                <h2>
                  Why Choose Block Intelligence for Layer2 Blockchain
                  Development?
                </h2>

                <p>
                  At Block Intelligence, we specialize in developing scalable,
                  secure, and high-performance Layer 2 blockchain solutions. Our
                  Layer 2 technologies, such as rollups, state channels, and
                  sidechains, are designed to enhance the scalability and
                  efficiency of Layer 1 blockchains like Ethereum and Bitcoin.
                  By reducing transaction costs and increasing throughput, we
                  help businesses scale their decentralized applications (dApps)
                  without compromising security or decentralization. Whether
                  you're looking to enhance DeFi platforms, enable faster
                  payments, or build custom sidechains, our tailored solutions
                  ensure your blockchain network operates seamlessly and
                  efficiently.
                </p>
              </div>
              <div className="col-lg-5 mx-auto ">
                <img
                  src={require("../Images/whyy.webp")}
                  className="whatimg chose "
                  alt="DeFi development Services"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="techstack">
        <div className="container">
          <h2 className="tech-h2">Blockchain Tech Stacks</h2>
          <div className="stack">
            <p>
              Our team of blockchain developers are experts at building
              innovative blockchain solutions on the best available blockchain
              platform.
            </p>
          </div>
         <TechStackSlider/>
        </div>
      </section>

      <FillForm />
      <Footer />
    </>
  );
}
