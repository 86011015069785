import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import FillForm from "../Components/FillForm";
import TechStackSlider from '../Components/TechStackSlider';
import Contactbar from "../Components/Contactbar";

import "../Pages/Style.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import exchange from "../Images/exchange.webp";
import wallet from "../Images/wallet.webp";
import lending from "../Images/lending.webp";
import token from "../Images/token.webp";
import app from "../Images/mobile-development.webp";
import smartcontract from "../Images/smart-contract.webp";
import stake from "../Images/stake.webp";
import farm from "../Images/bitcoin-mining.webp";
import data from "./blockchain.json";
import { Helmet } from "react-helmet";

export default function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [isScrolled]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isScrolled]);

  return (
    <>
      <Helmet>
        <title>
          Leading DeFi Staking Development Company - Block Intelligence{" "}
        </title>{" "}
        // Updated title
        <meta
          property="title"
          content="Leading DeFi Staking Development Company - Block Intelligence"
        />
        <link
          rel="canonical"
          href="https://www.blockintelligence.io/defi-staking-development-company"
        />{" "}
        // Updated canonical link
        <meta
          name="description"
          content="Discover Block Intelligence, a top DeFi Staking Development Company, specializing in creating transformative solutions for decentralized finance. Explore our DeFi staking services and innovative approaches today."
        />{" "}
        // Updated description
        <meta
          name="keywords"
          content="DeFi staking development company, DeFi staking development Services, DeFi Staking Development Solution, Decentralized finance staking development, DeFi staking, DeFi Staking Platform Development, Benefits of DeFi staking development, Features of DeFi staking development, Decentralized Finance Staking Development Service."
        />{" "}
        // Updated keywords
      </Helmet>
      <Header />
      <Contactbar />
      <section className="Defi">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 banner_con simple-video mb-sx-2">
              <h1 className="welcome wow fadeInUp h1tag">
                DeFi Staking Development
              </h1>
              <p className="wow fadeInUp">
                At Block Intelligence, we specialize in building cutting-edge
                DeFi staking platforms that empower users with seamless staking
                experiences and robust security features.
              </p>{" "}
              // Updated description
              <div className="justify-content-center mt-5">
                <a href="#contact" className="button">
                  Get Demo
                </a>
              </div>
            </div>
            <div className="col-lg-6 mx-auto mt-4 d-flex justify-content-center align-items-center">
              <div
                className="wow zoomIn"
                data-wow-duration="2s"
                data-wow-offset="100"
              >
                <div
                  className="banner_i"
                  data-wow-delay="0.2s"
                  data-wow-iteration="infinite"
                  data-wow-duration="2s"
                >
                  <img
                    src={require("../Images/defi.webp")}
                    alt="DeFi Exchange Platform Development"
                    className="defi-banner d-block mx-auto img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="whatdefi ">
        <div className="container ">
          <div className="whatdefi ">
            <div className="row">
              <div className="col-lg-6 mx-auto d-flex justify-content-center align-items-center">
                <img
                  src={require("../Images/whatdefi.webp")}
                  className="whatimg"
                  alt="DeFi Staking Development"
                />
              </div>
              <div className="col-lg-6">
                <h2>What is DeFi Staking Development?</h2>
                <p>
                  DeFi Staking Development refers to the process of creating
                  decentralized finance (DeFi) platforms that allow users to
                  stake their cryptocurrencies and digital assets. These
                  platforms empower users by providing them with full control
                  over their funds, eliminating the need for intermediaries, and
                  ensuring secure and transparent transactions. Users can lock
                  up their assets in staking contracts to earn rewards,
                  participate in network governance, and contribute to the
                  security of the blockchain. With features like yield farming
                  and liquidity pools, DeFi staking offers opportunities for
                  passive income while maintaining transparency and security
                  through smart contracts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="benfits-defi">
        <div className="container">
          <div className="key-feature-cex-content">
            <h2 className="h2-mobile h2-md">
              Benefits of Our DeFi Staking Platform Development 
            </h2>  
          </div>
          <div className="key-feature-cex-card-row">
            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-6">
                <div className="key-feature-cex-card">
                  <img
                    src={require("../Images/secure-payment.webp")}
                    className="card-img"
                    alt="Security"
                  />
                  <p className="key-feature-cex-card-title">Security</p>
                  <p
                    className="key-feature-cex-card-desc"
                    style={{ fontSize: "18px", paddingTop: "10px" }}
                  >
                    By leveraging smart contracts, automate transactions
                    securely, reducing human error and minimizing the risk of
                    hacks or fraud.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="key-feature-cex-card">
                  <img
                    src={require("../Images/transparency.webp")}
                    className="card-img"
                    alt="Transparency"
                  />
                  <p className="key-feature-cex-card-title"> Transparency</p>
                  <p
                    className="key-feature-cex-card-desc"
                    style={{ fontSize: "18px", paddingTop: "10px" }}
                  >
                    With blockchain at its core, DeFi is inherently transparent.
                    No hidden fees or agendas.
                  </p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="key-feature-cex-card">
                  <img
                    src={require("../Images/world.webp")}
                    className="card-img"
                    alt="Global Research"
                  />
                  <p className="key-feature-cex-card-title">Global Reach </p>
                  <p
                    className="key-feature-cex-card-desc"
                    style={{ fontSize: "18px", paddingTop: "10px" }}
                  >
                    DeFi is accessible to anyone, anywhere, making financial
                    inclusivity a reality.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="key-feature-cex-card">
                  <img
                    src={require("../Images/icons/ico.webp")}
                    className="card-img"
                    alt="Profit"
                  />
                  <p className="key-feature-cex-card-title"> High Returns </p>
                  <p
                    className="key-feature-cex-card-desc"
                    style={{ fontSize: "18px", paddingTop: "10px" }}
                  >
                    Yield farming, liquidity provision, and staking - DeFi
                    offers opportunities for impressive returns.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="key-feature-cex-card">
                  <img
                    src={require("../Images/profits.webp")}
                    className="card-img"
                    alt="Profit"
                  />
                  <p className="key-feature-cex-card-title"> Liquidity </p>
                  <p
                    className="key-feature-cex-card-desc"
                    style={{ fontSize: "18px", paddingTop: "10px" }}
                  >
                    DeFi exchanges incentivize users to contribute liquidity
                    through liquidity pools, ensuring faster trades and enabling
                    more efficient markets.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="key-feature-cex-card">
                  <img
                    src={require("../Images/kyc.webp")}
                    className="card-img"
                    alt="Profit"
                  />
                  <p className="key-feature-cex-card-title"> Passive Income </p>
                  <p
                    className="key-feature-cex-card-desc"
                    style={{ fontSize: "18px", paddingTop: "10px" }}
                  >
                    Features like yield farming, staking, and lending allow
                    users to earn passive income by locking up their assets,
                    boosting user engagement
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="key-feature-cex-card">
                  <img
                    src={require("../Images/world.webp")}
                    className="card-img"
                    alt="Profit"
                  />
                  <p className="key-feature-cex-card-title">
                    {" "}
                    Lower transaction
                  </p>
                  <p
                    className="key-feature-cex-card-desc"
                    style={{ fontSize: "18px", paddingTop: "10px" }}
                  >
                    Without middlemen involved, our DeFi platforms help reduce
                    transaction fees, making trading more cost-effective for
                    users.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" Finance-Development">
        <div className="container">
          <h2> Blockchain We Work on for Decentralized Finance Development </h2>
          <div className="row mt-5 justify-content-center">
            {data.map((screenshot, index) => (
              <div className="col-lg-1 col-md-2 col-sm-3">
                <div className="finance-card shadow">
                  <div className="card-img-fiance">
                    <img
                      src={`https://transfer.swft.pro/swft-v3/images/coins/${screenshot.chain}.png`}
                      alt={screenshot.name}
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="featuretext mt-3 ">
                  <h5 className="card-title">{screenshot.name}</h5>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="whatdefi">
        <div className="container">
          <div className="whatdefi">
            <div className="row">
              <div className="col-lg-7 ">
                <h2>
                  Why Choose Block Intelligence for DeFi Staking Development?
                </h2>

                <p>
                  We Block Intelligence are the architects of financial
                  transformation in the decentralized world. We don't just build
                  DeFi platforms; we engineer financial freedom. Our team
                  combines technical prowess with a passion for innovation,
                  ensuring that your DeFi project stands out in a crowded space.
                  From crafting robust smart contracts to creating user-friendly
                  interfaces, we leave no stone unturned in making your vision a
                  reality. We are the driving force behind countless successful
                  DeFi ventures, and when you choose us, you're not just
                  choosing a development partner; you're choosing a journey to
                  redefine finance. Join us, and let's build the future of
                  finance together, one block at a time.
                </p>
              </div>
              <div className="col-lg-5 mx-auto ">
                <img
                  src={require("../Images/whyy.webp")}
                  className="whatimg chose "
                  alt="DeFi development Services"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="techstack">
        <div className="container">
          <h2 className="tech-h2">Blockchain Tech Stacks</h2>
          <div className="stack">
            <p>
              Our team of blockchain developers are experts at building
              innovative blockchain solutions on the best available blockchain
              platform.
            </p>
          </div>
          <TechStackSlider />
        </div>
      </section>

      <FillForm />
      <Footer />
    </>
  );
}
