import React from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

export default function Page_Not_found() {
  return (
    <>
      <Header />
      <section className="not-found">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="not-found-error">
                <div className="error_h2">
                <img
                  src={require("../Images/404-white.webp")}
                  className="bitcoin"
                  alt="Crypto Bitcoin"
                />
                </div>
                <div className="error_title_p" >
                  <p>Page Not Found</p>
                </div>
                <div  className="error_desc_p" >
                <p>The page you are looking for does not seem to exist </p>
              </div>
              <div  className="error_button" >
                <button
                  className="main-btn"
                  onClick={(event) => (window.location.href = "/")}
                >
                  Go To Home
                </button>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
