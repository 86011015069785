import React from "react";

export default function Footer() {
  return (
    <>
      <section className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 foot">
              <div className="first">
                <div className="logoo">
                  <a href="#">
                    <img
                      src={require("../Images/logo.webp")}
                      height="55"
                      alt="CoolBrand"
                    />
                  </a>
                  <p>
                  Block Intelligence, a leading blockchain development company, offers superior quality blockchain and AI/ML solutions. Benefit from dedicated teams, transparency,  advanced in technology, prompt delivery, and exceptional support. Connect with our team!
                  </p>
                </div>
                <div className="email">
                  <div className="email-logo">
                    <img
                      src={require("../Images/email-logo.webp")}
                      height="22"
                      alt="Email"
                    />
                  </div>
                  <div className="logospace">
                    <p>connect@blockchain.ai.in</p>
                  </div>
                </div>
                <div className="phne email">
                  <div className="email-logo">
                    <img
                      src={require("../Images/phone.webp")}
                      className="phone"
                      height="22"
                      alt="Phone"
                    />
                  </div>
                  <div className="Mobile">
                    <p>+91 77384 79381</p>
                  </div>
                </div>
                <div className="phne email">
                  <div className="email-logo">
                    <img
                      src={require("../Images/location.webp")}
                      className="phone"
                      height="22"
                      alt="Location"
                    />
                  </div>
                  <div className="logospace">
                    
                    <p>1st Floor, Chris Arcade, Kanakia Mira Rd, opp. M.G.L CNG Pump, Unique Gardens, Beverly Park, Mira Road East, Thane, Maharashtra 401107</p>
                  </div>
                </div>
                <div className="phne email">
                  <div className="email-logo">
                    <img
                      src={require("../Images/location.webp")}
                      className="phone"
                      height="22"
                      alt="Location"
                    />
                  </div>
                  <div className="logospace">
                   
                    <p>Second Floor, 5/24, Bharathiyar 7th St, Tagore Nagar, New Ellis Nagar, S S Colony, Madurai, Tamil Nadu 625016</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 foot">
              <div className="middle">
                <h3>Services</h3>
                <div className="row middle-row">
                  <div className="col-lg-6 col-md-6 footr">
                  <a href="/centralized-crypto-exchange-development-company">Centralized Exchange Development</a>
                    <br />
                    <a href="/nft-marketplace-development-company">NFT MarketPlace Development</a>
                    <br />
                    <a href="/dex-development-company">DEX Development</a>
                    <br />
                    <a href="/defi-development-company">DeFi Development</a>
                    <br />
                    <a href="/p2p-crypto-exchange-development">P2P Crypto Exchange Development</a>
                    <br />
                    <a href="/crypto-token-development">Token Development</a>
                    <br />
                    <a href="/smart-contract-audit-company">Smart Contract Auditing</a>
                    <br />
                    <a href="/metaverse-development-company">Metaverse Development</a>
                    <br />
                  </div>
                  <div className="col-lg-6 col-md-6 footr">
                    <a href="#">Web3 Solutions</a>
                    <br />
                    <a href="#">Crypto Token Listing</a>
                    <br />
                    <a href="#">P2E Gaming</a>
                    <br />
                    <a href="#">Incubation & VC Funding</a>
                    <br />
                    <a href="#">Blockchain Web3 Consultancy</a>
                    <br />
                    <a href="#">Blockchain Marketing</a>
                    <br />
                    <a href="#">Graphics and Branding</a>
                    <br />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 foot">
              {/* <div className="last">
                <h3>Email Subscription</h3>
                <form>
                  <div className="sub">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email Address"
                    />
                    <button>Subscribe</button>
                  </div>
                </form>
              </div> */}
              <div className="row social-media">
                <h3>Social Media</h3>
                <div className="col-lg-12 col-md-12 social foot">
                <ul className="social-media1">
                  <li>
                    <a href="https://www.facebook.com/blockintelligence/" target="_blank">
                      <i class="ri-facebook-circle-line"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://wa.me/7738479381" target="_blank">
                      <i class="ri-whatsapp-line"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/BlockchainAI_In" target="_blank">
                      <i class="ri-twitter-line"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://join.skype.com/invite/tm3uaTeSeHpF" target="_blank">
                      <i class="ri-skype-line"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/blockintelligence/" target="_blank">
                      <i class="ri-linkedin-box-line"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/blockintelligence/" target="_blank">
                      <i class="ri-instagram-line"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://in.pinterest.com/block_intelligence/" target="_blank">
                      <i class="ri-pinterest-line"></i>
                    </a>
                  </li>
                </ul>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="copyrights">
        <div>
        <hr />
        <p>© Copyright 2023 Block Intelligence | All Rights Reserved </p>
        </div>
      </section>
    </>
  );
}
