import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import FillForm from "../Components/FillForm";
import Contactbar from "../Components/Contactbar";

import "../Pages/Style.css";
import TechStackSlider from '../Components/TechStackSlider';
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";

export default function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [isScrolled]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isScrolled]);

  return (
    <>
      <Helmet>
        <title>Opensea clone script</title>
        <meta property="title" content="Opensea clone script" />
        <meta
          name="description"
          content="Launch your own NFT Marketplace like opensea with opensea clone script offered by Block Intelligence. We offer a ready-made and customizable solution of NFT Marketplace development."
        />
        <link
          rel="canonical"
          href="https://www.blockintelligence.io/opensea-clone-script"
        />
      </Helmet>
      <Header />
      <Contactbar />
      <div className="opensea_head">
        <section className="p2p Opensea">
          <div className="container">
            <div className="row pt-5">
              <div className="col-lg-6 mt-5 banner_con simple-video mb-sx-2">
                <h1 className="wow mt-5 fadeInUp text-black h1tag">
                  <span>P2E Game development</span>
                </h1>
                <p className="wow fadeInUp text-white mt-4">
                  Launch your own Play-to-Earn (P2E) game with Block
                  Intelligence, the leading P2E game development company. Our
                  P2E game development service allows you to create a
                  customized, secure, and engaging gaming experience that
                  empowers players to earn rewards while playing. With our
                  expertise, you can develop a user-friendly platform that
                  integrates blockchain technology, ensuring transparency and
                  security for all transactions. Transform your gaming vision
                  into reality with our comprehensive P2E game development
                  solutions.{" "}
                </p>
                <div className="p2pbt mt-5">
                  <a href="#contact" className="button">
                    Get Demo
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract whatopensea py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-5 mx-auto d-flex justify-content-center align-items-center">
                <img
                  src={require("../Images/openseaa2.webp")}
                  width="90%"
                  alt="p2p crypto exchange development company"
                />
              </div>
              <div className="col-lg-7 mt-5">
                <div className="p2pcontent">
                  <h2>
                    Why Choose Block Intelligence for{" "}
                    <span>P2E Game development</span>?
                  </h2>
                  <p>
                    At Block Intelligence, we offer unparalleled expertise in
                    Play-to-Earn (P2E) game development, combining innovation
                    with a deep understanding of blockchain technology. Our team
                    crafts immersive gaming experiences that integrate seamless
                    blockchain features, enabling players to earn rewards and
                    own in-game assets securely. With a focus on user
                    engagement, advanced security, and decentralized systems, we
                    ensure your P2E platform thrives in the competitive gaming
                    industry. Partner with us for cutting-edge solutions that
                    cater to both gaming enthusiasts and blockchain communities,
                    driving success for your P2E ventures..
                    <div className="mt-3">
                      <a
                        href="https://wa.me/7738479381"
                        target="_blank"
                        className="button"
                      >
                        Talk with expert
                      </a>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whatsmartcontract whatopensea py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-7 mt-5">
                <div className="p2pcontent">
                  <h2>
                    What is <span>P2E (Play-to-Earn) Game development</span>?
                  </h2>
                  <p>
                    P2E (Play-to-Earn) is a gaming model that allows players to
                    earn real-world value, typically in the form of
                    cryptocurrency or NFTs (Non-Fungible Tokens), while playing
                    video games. Unlike traditional games where rewards are
                    limited to in-game currency or achievements, P2E games
                    integrate blockchain technology to offer tangible assets
                    that players can trade, sell, or use across different
                    platforms. In a P2E ecosystem, players often receive tokens
                    for completing tasks, winning battles, or reaching specific
                    milestones, which can later be exchanged for other
                    cryptocurrencies or fiat currency. This model is popular
                    within the growing decentralized gaming sector as it creates
                    financial opportunities for gamers while enhancing
                    engagement and participation. P2E games are reshaping how
                    people perceive gaming, transforming it from a hobby into a
                    potential income-generating activity.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 mx-auto d-flex justify-content-center align-items-center">
                <img
                  src={require("../Images/openseaa3.webp")}
                  width="90%"
                  alt="p2p crypto exchange development company"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract whatopensea py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-12 my-5">
                <div className="p2pcontent">
                  <h2 className="text-center">
                    Benefits of <span>P2E Game development</span>
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 px-4 my-3">
                  <div className="openseacard">
                    <img
                      src={require("../Images/cards/customize.webp")}
                      className="my-3 mx-auto d-block"
                      width="20%"
                    />
                    <h5> Monetization Opportunities</h5>
                    <p className="text-center my-3">
                    Play-to-Earn (P2E) games offer players real-world financial rewards through cryptocurrency or NFTs, increasing user engagement and opening new revenue streams for developers.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 px-4 my-3">
                  <div className="openseacard">
                    <img
                      src={require("../Images/cards/customize.webp")}
                      className="my-3 mx-auto d-block"
                      width="20%"
                    />
                    <h5>  Decentralization and Ownership</h5>
                    <p className="text-center my-3">
                    Blockchain-based P2E games allow players to own and trade in-game assets, enhancing the value and engagement in gaming ecosystems.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 px-4 my-3">
                  <div className="openseacard">
                    <img
                      src={require("../Images/cards/customize.webp")}
                      className="my-3 mx-auto d-block"
                      width="20%"
                    />

                    <h5>Increased User Retention</h5>
                    <p className="text-center my-3">
                    By offering real rewards, P2E games provide incentives for players to remain active longer, resulting in a highly engaged player base..
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 px-4 my-3">
                  <div className="openseacard">
                    <img
                      src={require("../Images/cards/customize.webp")}
                      className="my-3 mx-auto d-block"
                      width="20%"
                    />

                    <h5> Global Reach:</h5>
                    <p className="text-center my-3">
                    P2E games cater to a global audience, particularly in regions with a growing interest in crypto, expanding market potential
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 px-4 my-3">
                  <div className="openseacard">
                    <img
                      src={require("../Images/cards/customize.webp")}
                      className="my-3 mx-auto d-block"
                      width="20%"
                    />

                    <h5> Community-Driven Growth</h5>
                    <p className="text-center my-3">
                    The decentralized nature of P2E gaming fosters strong community support, with players contributing to the game's growth and success through governance mechanisms.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 px-4 my-3">
                  <div className="openseacard">
                    <img
                      src={require("../Images/cards/customize.webp")}
                      className="my-3 mx-auto d-block"
                      width="20%"
                    />

                    <h5>Cost-Effectiveness</h5>
                    <p className="text-center my-3">
                      Save development time and costs with a ready-to-deploy
                      solution, accelerating your entry into the NFT market.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

    


       

        <section className="techstack2 stacz">
          <div className="container">
            <h2 className="tech-h2">Blockchain Tech Stacks</h2>
            <div className="stack">
              <p>
                Our team of blockchain developers are experts at building
                innovative blockchain solutions on the best available blockchain
                platform.
              </p>
            </div>
            <TechStackSlider/>
          </div>
        </section>
      </div>

      <FillForm />

      <Footer />
    </>
  );
}
