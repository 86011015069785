import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import FillForm from "../Components/FillForm";
import Contactbar from "../Components/Contactbar";
import "../Pages/Style.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet"
import TechStackSlider from '../Components/TechStackSlider';;

export default function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [isScrolled]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isScrolled]);

  return (
    <>
      <Helmet>
        <title>P2P Crypto Exchange Software Development Company</title>
        <meta
          property="title"
          content="P2P Crypto Exchange Software Development Company"
        />
        <meta
          name="description"
          content="Block intelligence offers an expert P2P crypto exchange development solution and P2P cryptocurrency exchange development services with best features"
        />
        <meta
          name="keywords"
          content="p2p crypto exchange development company,
p2p cryptocurrency exchange development company,
p2p crypto exchange development,
p2p crypto exchange software development,
p2p exchange development,
p2p cryptocurrency exchange development,
p2p exchange development company,
p2p cryptocurrency exchange software,
crypto p2p exchange application development,
p2p crypto exchange development solutions,
p2p crypto exchange development services,
"
        />
        <link
          rel="canonical"
          href="https://www.blockintelligence.io/p2p-crypto-exchange-development"
        />
      </Helmet>
      <Header />
      <Contactbar />
      <div className="p2p_head">
        <section className="p2p ">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 banner_con simple-video mb-sx-2 mt-5">
                <h1 className="wow fadeInUp mt-5 h1tag">
                  P2P Crypto Exchange <br />
                  Development Company
                </h1>
                <p className="wow fadeInUp mt-4">
                  Launch your top-tier P2P crypto exchange platform with high
                  security and privacy. Block Intelligence develops your P2P
                  crypto exchange trading platform with best trading experience
                  and advanced features.
                </p>
                <div className="p2pbt mt-5">
                  <a href="#contact" className="button">
                    Get Demo
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whatp2p">
          <div className="container-md whatp">
            <div className="row">
              <div className="col-lg-4 mx-auto mt-4 d-flex justify-content-center align-items-center">
                <img
                  src={require("../Images/p2pphone.webp")}
                  className="p2pimg"
                  alt="p2p crypto exchange development company"
                />
              </div>
              <div className="col-lg-8 ">
                <div className="p2pcontent">
                  <h2>What is P2P Crypto Exchange?</h2>
                  <p>
                    P2P (Peer-to-Peer) crypto exchange is a decentralized form
                    of cryptocurrency exchange platform where the users can
                    enable direct crypto transactions. It is completely
                    transparent without any intermediaries. In P2P crypto
                    exchange, every trader will trade their cryptos
                    independently as they have the control over their assets. It
                    is a distributed ledger technology, in which no one can
                    modify or alter the data, transaction history or information
                    stored. P2P cryptocurrency exchange platform offers a wide
                    range of payment options and facilitates high speed
                    transactions. Integrating P2P crypto exchange to business
                    can level up the process of secure transaction. In recent
                    times, P2P crypto exchange is gaining a great hype between
                    the businesses to make secure crypto payment. If you are
                    looking to upgrade your business, it will be a write time to
                    create your best in class P2P exchange platform.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="firstp2p">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 ">
                <h2>First Class P2P Crypto Exchange Development</h2>
                <p>
                  Block Intelligence is a top-notch P2P crypto exchange
                  development company. We are specialized in developing secure,
                  user-centric, and fully customized peer-to-peer cryptocurrency
                  exchange platforms. Our P2P crypto exchange development
                  solution is highly secure with a smart contract and escrow
                  system. Our developers are well experienced in developing a
                  high speed and trustworthy P2P crypto exchange platform
                  development. Kick start your crypto exchange with Block
                  Intelligence and lead the way in the world of P2P
                  cryptocurrency trading.
                </p>
              </div>
              <div className="col-lg-5">
                <img
                  src={require("../Images/p2pban.webp")}
                  className="firstp2pimg d-block mx-auto"
                  alt="p2p cryptocurrency exchange development company"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="p2p-process">
          <div className="ben-head">
            <h2>Process of P2P Crypto Exchange Development</h2>
            <p className="pblock">
              {" "}
              At Block Intelligence, crafting a P2P cryptocurrency exchange
              platform development process involves innovative technology with a
              user-centric approach. Our P2P crypto exchange development
              includes the following steps.
            </p>
          </div>
          <div className="container-fluid">
            <div className="row Process_of_P2P justify-content-around mt-5">
              <div className="col-lg-2 col-sm-6 proodd fade-up delay-1">
                <div className="process-card-para ">
                  <img
                    src={require("../Images/target.webp")}
                    className="process-img-p2p d-block mx-auto"
                    alt="Vision & Strategy"
                  />
                  <h5>Vision and Strategy</h5>
                  <p className="pro">
                    We start by understanding your requirements and business
                    goals. This involves assessing the target audience, markets,
                    and specific requirements.
                  </p>
                </div>
              </div>

              <div className="col-lg-2 col-sm-6 proeven  fade-up delay-2">
                <div className="process-card-para mt-5 ">
                  <img
                    src={require("../Images/design-thinking.webp")}
                    className="process-img-p2p d-block mx-auto"
                    alt="Design and Architecture"
                  />
                  <h5>Design and Architecture</h5>
                  <p className="pro">
                    Our expert designers will create an intuitive and visually
                    appealing UI for you. Simultaneously, our architects lay the
                    foundation for a robust and scalable exchange platform.
                  </p>
                </div>
              </div>

              <div className="col-lg-2 col-sm-6 proodd fade-up delay-3">
                <div className="process-card-para ">
                  <img
                    src={require("../Images/smart3.webp")}
                    className="process-img-p2p d-block mx-auto"
                    alt="Smart Contract Development"
                  />
                  <h5> Smart Contract Development</h5>
                  <p className="pro">
                    We develop smart contracts that automate and secure the
                    execution of transactions, ensuring trust and transparency.
                  </p>
                </div>
              </div>
              <div className="col-lg-2 col-sm-6 proeven fade-up delay-4">
                <div className="process-card-para install mt-5">
                  <img
                    src={require("../Images/install.webp")}
                    className="process-img-p2p d-block mx-auto"
                    alt="Coin installation"
                  />

                  <h5>Coin installation</h5>
                  <p className="pro">
                    We install the various cryptocurrencies, coins and tokens
                    for the P2P cryptocurrency exchange platform for trading.
                  </p>
                </div>
              </div>
              <div className="col-lg-2 col-sm-6  fade-up process-delay-5">
                <div className="process-card-para ">
                  <img
                    src={require("../Images/release.webp")}
                    className="process-img-p2p d-block mx-auto"
                    alt="Testing and Deployment"
                  />

                  <h5>Testing and Deployment</h5>
                  <p className="pro">
                    We conduct testing processes that include load testing,
                    security audits, and more. And finally deploy the P2P
                    exchange with a reliable and scalable infrastructure.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="p2p-keyfeatures">
          <div className="container">
            <div>
              <h2 className="text-white">
                {" "}
                Incredible features of Our P2P Cryptocurrency Exchange Software
                Development
              </h2>
              <div className="row mt-5">
                <div class="col-lg-4 mb-5 col-sm-12 firstrow">
                  <div class="keyfeature-card-p2p">
                    <p class="keyfeature-card-p2ptitle">Admin panel</p>
                    <p class="keyfeature-card-para">
                      {" "}
                      Our Admin panel is elegant with better UI, trading
                      functionalities, better transaction, and with other modern
                      features.
                    </p>
                  </div>
                </div>
                <div class="col-lg-4  col-sm-12 firstrow">
                  <div class="keyfeature-card-p2p">
                    <p class="keyfeature-card-p2ptitle">Trading options</p>
                    <p class="keyfeature-card-para">
                      We Provide a range of trading choices to your users. Our
                      platform supports a variety of trading preferences, from
                      limit orders to market orders.
                    </p>
                  </div>
                </div>
                <div class="col-lg-4  col-sm-12 firstrow">
                  <div class="keyfeature-card-p2p">
                    <p class="keyfeature-card-p2ptitle">Escrow System </p>
                    <p class="keyfeature-card-para">
                      {" "}
                      With an escrow security system, users can trade assets
                      securely. The transaction will be executed only when both
                      the parties obligations are met.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div class="col-lg-4 mb-5 col-sm-12 firstrow">
                  <div class="keyfeature-card-p2p">
                    <p class="keyfeature-card-p2ptitle">Optimal Match Engine</p>
                    <p class="keyfeature-card-para">
                      P2P crypto exchange platform integrated with high speed
                      optimal match engine in which buy and sell order matches
                      at short time.
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-12 firstrow">
                  <div class="keyfeature-card-p2p">
                    <p class="keyfeature-card-p2ptitle">
                      Multi layer Security{" "}
                    </p>
                    <p class="keyfeature-card-para">
                      We develop P2P exchange with multiple layers of security
                      features with advanced encryption, DDoS protection, and
                      more.
                    </p>
                  </div>
                </div>
                <div class="col-lg-4  col-sm-12 firstrow">
                  <div class="keyfeature-card-p2p">
                    <p class="keyfeature-card-p2ptitle">
                      Multiple currency support{" "}
                    </p>
                    <p class="keyfeature-card-para">
                      Our platform supports a wide variety of cryptocurrencies
                      in order to appeal to a wide audience and guarantee a wide
                      range of trading options.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row mt-5">
                <div class="col-lg-4  col-sm-12 firstrow">
                  <div class="keyfeature-card-p2p">
                    <p class="keyfeature-card-p2ptitle">
                      Multi Lingual support{" "}
                    </p>
                    <p class="keyfeature-card-para">
                      {" "}
                      For global users, we develop a P2P crypto exchange
                      platform with multiple language support, so that users
                      worldwide can access.
                    </p>
                  </div>
                </div>
                <div class="col-lg-4  col-sm-12 firstrow">
                  <div class="keyfeature-card-p2p">
                    <p class="keyfeature-card-p2ptitle"> Dispute Resolution </p>
                    <p class="keyfeature-card-para">
                      Disputes can arise, but our software has it covered. It
                      features a robust dispute resolution system that ensures
                      fair and impartial conflict resolution.
                    </p>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-12 firstrow">
                  <div class="keyfeature-card-p2p">
                    <p class="keyfeature-card-p2ptitle"> Atomic swap </p>
                    <p class="keyfeature-card-para">
                      Experience the future of decentralized trading with atomic
                      swaps. Our software facilitates secure P2P cryptocurrency
                      swaps without the need for a mediator.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="features-p2p">
          <div className="container">
            <div className="feature-content pb-3">
              <h2>
                Key Security Features of Our P2P Crypto Exchange Development
              </h2>
            </div>
            <div className="feature-card-row">
              <div className="row ">
                <div className="col-lg-4 col-md-4">
                  <div className="feature-card-p2p">
                    <img
                      src={require("../Images/third-party2.webp")}
                      className="feature-img-p2p"
                      alt="Escrow System"
                    />
                    <p className="feature-card-p2ptitle">Escrow System</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="feature-card-p2p">
                    <img
                      src={require("../Images/authentication1.webp")}
                      className="feature-img-p2p"
                      alt="Two Factor Authentication"
                    />
                    <p className="feature-card-p2ptitle">
                      Two Factor Authentication
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="feature-card-p2p">
                    <img
                      src={require("../Images/kyc.webp")}
                      className="feature-img-p2p"
                      alt="KYC and AML Verification"
                    />
                    <p className="feature-card-p2ptitle">
                      KYC and AML Verification
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="feature-card-row">
              <div className="row ">
                <div className="col-lg-4 col-md-4">
                  <div className="feature-card-p2p">
                    <img
                      src={require("../Images/encrypted-data.webp")}
                      className="feature-img-p2p"
                      alt="Advanced Encryption"
                    />
                    <p className="feature-card-p2ptitle">Advanced Encryption</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="feature-card-p2p">
                    <img
                      src={require("../Images/api.webp")}
                      className="feature-img-p2p"
                      alt="DeFi development Services"
                    />
                    <p className="feature-card-p2ptitle">
                      Secure API integration
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4">
                  <div className="feature-card-p2p">
                    <img
                      src={require("../Images/shield3.webp")}
                      className="feature-img-p2p"
                      alt="DeFi development Services"
                    />
                    <p className="feature-card-p2ptitle">SSRF Protection</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="Benfit-p2p">
          <div className="container">
            <h2>Benefits of P2P Cryptocurrency Exchange Development</h2>

            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="Benfit-card-p2psecond">
                  <div className="benfit-img-p2p2">
                    <img
                      src={require("../Images/block.webp")}
                      className="benfit-img-p2p2"
                      alt="Decentralization & Trust"
                    />
                  </div>
                  <h5 className="benfeature-card-p2ptit">
                    Decentralization & Trust
                  </h5>
                  <p className="benfeature-card-p">
                    {" "}
                    Trade directly, trust in the blockchain.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="Benfit-card-p2psecond">
                  <div className="benfit-img-p2p2">
                    <img
                      src={require("../Images/global-network.webp")}
                      className="benfit-img-p2p2"
                      alt="Global Accessibility"
                    />
                  </div>
                  <h5 className="benfeature-card-p2ptit">
                    Global Accessibility
                  </h5>
                  <p className="benfeature-card-p"> Crypto knows no borders.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="Benfit-card-p2psecond">
                  <div className="benfit-img-p2p2">
                    <img
                      src={require("../Images/security-shield.webp")}
                      className="benfit-img-p2p2"
                      alt="Enhanced Privacy"
                    />
                  </div>
                  <h5 className="benfeature-card-p2ptit">Enhanced Privacy</h5>
                  <p className="benfeature-card-p">
                    {" "}
                    Your transactions, your business.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="Benfit-card-p2psecond">
                  <div className="benfit-img-p2p2">
                    <img
                      src={require("../Images/flexibility 1.webp")}
                      className="benfit-img-p2p2"
                      alt="Flexibility & Variety"
                    />
                  </div>
                  <h5 className="benfeature-card-p2ptit">
                    Flexibility & Variety
                  </h5>
                  <p className="benfeature-card-p">
                    Diverse crypto choices at your fingertips.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="Benfit-card-p2psecond">
                  <div className="benfit-img-p2p2">
                    <img
                      src={require("../Images/money-transfer.webp")}
                      className="benfit-img-p2p2"
                      alt="Fast Transactions"
                    />
                  </div>
                  <h5 className="benfeature-card-p2ptit">Fast Transactions</h5>

                  <p className="benfeature-card-p">
                    {" "}
                    Swift responses to market changes.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="Benfit-card-p2psecond">
                  <div className="benfit-img-p2p2">
                    <img
                      src={require("../Images/third-party (1).webp")}
                      className="benfit-img-p2p2"
                      alt="Escrow Services"
                    />
                  </div>
                  <h5 className="benfeature-card-p2ptit">Escrow Services</h5>
                  <p className="benfeature-card-p">
                    {" "}
                    Peace of mind in every trade.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="Benfit-card-p2psecond">
                  <div className="benfit-img-p2p2">
                    <img
                      src={require("../Images/liquidity2.webp")}
                      className="benfit-img-p2p2"
                      alt="Liquidity & Market Depth"
                    />
                  </div>
                  <h5 className="benfeature-card-p2ptit">
                    Liquidity & Market Depth
                  </h5>
                  <p className="benfeature-card-p">
                    {" "}
                    A deep pool of trading opportunities.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="Benfit-card-p2psecond">
                  <div className="benfit-img-p2p2">
                    <img
                      src={require("../Images/reduce-cost.webp")}
                      className="benfit-img-p2p2"
                      alt="Reduced Fees"
                    />
                  </div>
                  <h5 className="benfeature-card-p2ptit">Reduced Fees</h5>
                  <p className="benfeature-card-p"> Save on trading costs.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="Benfit-card-p2psecond">
                  <div className="benfit-img-p2p2">
                    <img
                      src={require("../Images/secured.webp")}
                      className="benfit-img-p2p2"
                      alt="Security Redefined"
                    />
                  </div>
                  <h5 className="benfeature-card-p2ptit">Security Redefined</h5>
                  <p className="benfeature-card-p">
                    {" "}
                    Hacks are history with blockchain security.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="how-p2p">
          <div className="container">
            <div>
              <h2> How P2P Crypto Exchange Works?</h2>
            </div>

            <div className="row">
              <div className="col-lg-5">
                <div class="keyfeature">
                  <div class="keyfeature-image">
                    <img
                      src={require("../Images/round.webp")}
                      class="round-image"
                      alt="1"
                    />
                  </div>
                  <div class="keyfeature-content">
                    <h3 className="title2"> Registration and Verification</h3>
                  </div>
                </div>
              </div>

              <div className="col-lg-1  d1none my-4">
                <h3 className="title2">:</h3>
              </div>
              <div className="col-lg-6">
                <div className="how-content">
                  <p>Users sign up and complete KYC verification.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5">
                <div class="keyfeature">
                  <div class="keyfeature-image">
                    <img
                      src={require("../Images/round2.webp")}
                      class="round-image"
                      alt="2"
                    />
                  </div>
                  <div class="keyfeature-content">
                    <h3 className="title2">Buyer's Order</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-1  d1none my-4">
                <h3 className="title2">:</h3>
              </div>
              <div className="col-lg-6">
                <div className="how-content">
                  <p>
                    Buyers place purchase orders for specific crypto assets.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5">
                <div class="keyfeature">
                  <div class="keyfeature-image">
                    <img
                      src={require("../Images/round3.webp")}
                      class="round-image"
                      alt="3"
                    />
                  </div>
                  <div class="keyfeature-content">
                    <h3 className="title2">Wallet Generation</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-1  d1none my-4">
                <h3 className="title2">:</h3>
              </div>
              <div className="col-lg-6">
                <div className="how-content">
                  <p>A secure wallet address is created for the buyer.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5">
                <div class="keyfeature">
                  <div class="keyfeature-image">
                    <img
                      src={require("../Images/round4.webp")}
                      class="round-image"
                      alt="4"
                    />
                  </div>
                  <div class="keyfeature-content">
                    <h3 className="title2">Matching Engine</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-1  d1none my-4">
                <h3 className="title2">:</h3>
              </div>
              <div className="col-lg-6">
                <div className="how-content">
                  <p>
                    An advanced engine matches buyers with suitable sellers.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5">
                <div class="keyfeature">
                  <div class="keyfeature-image">
                    <img
                      src={require("../Images/round5.webp")}
                      class="round-image"
                      alt="5"
                    />
                  </div>
                  <div class="keyfeature-content">
                    <h3 className="title2"> Negotiation and Agreement</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-1  d1none my-4">
                <h3 className="title2">:</h3>
              </div>
              <div className="col-lg-6">
                <div className="how-content">
                  <p>
                    Buyers and sellers communicate and agree on trade terms.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5">
                <div class="keyfeature">
                  <div class="keyfeature-image">
                    <img
                      src={require("../Images/round6.webp")}
                      class="round-image"
                      alt="6"
                    />
                  </div>
                  <div class="keyfeature-content">
                    <h3 className="title2"> Smart Contract Escrow</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-1  d1none my-4">
                <h3 className="title2">:</h3>
              </div>
              <div className="col-lg-6">
                <div className="how-content">
                  <p>
                    Smart contracts securely hold the seller's assets during the
                    transaction.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5">
                <div class="keyfeature">
                  <div class="keyfeature-image">
                    <img
                      src={require("../Images/round7.webp")}
                      class="round-image"
                      alt="7"
                    />
                  </div>
                  <div class="keyfeature-content">
                    <h3 className="title2">Payment and Confirmation</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-1  d1none my-4">
                <h3 className="title2">:</h3>
              </div>
              <div className="col-lg-6">
                <div className="how-content">
                  <p>Buyers make payments, and sellers confirm receipt.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5">
                <div class="keyfeature">
                  <div class="keyfeature-image">
                    <img
                      src={require("../Images/round8.webp")}
                      class="round-image"
                      alt="8"
                    />
                  </div>
                  <div class="keyfeature-content">
                    <h3 className="title2"> Escrow Release</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-1  d1none my-4">
                <h3 className="title2">:</h3>
              </div>
              <div className="col-lg-6">
                <div className="how-content">
                  <p>The escrow smart contract releases assets to the buyer.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5">
                <div class="keyfeature">
                  <div class="keyfeature-image">
                    <img
                      src={require("../Images/round9.webp")}
                      class="round-image"
                      alt="9"
                    />
                  </div>
                  <div class="keyfeature-content">
                    <h3 className="title2"> Optional Cold Wallet Transfer</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-1  d1none my-4">
                <h3 className="title2">:</h3>
              </div>
              <div className="col-lg-6">
                <div className="how-content">
                  <p>
                    Buyers can choose to transfer assets to a cold wallet for
                    added security.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="firstp2p">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 mx-auto">
                <img
                  src={require("../Images/p2pbann.webp")}
                  className="firstp2pimg d-block mx-auto"
                  alt="p2p crypto exchange development company"
                />
              </div>
              <div className="col-lg-7 ">
                <h2>Top Rated P2P Crypto Exchange development Company </h2>

                <p>
                  Block Intelligence is a top-rated P2P crypto exchange software
                  development company with a team of experienced blockchain
                  developers. With our P2P crypto exchange platform development
                  service, you can upgrade your business and generate huge ROI.
                  We offer an end-to-end fully customizable P2P crypto exchange
                  platform with advanced security features and specifications.
                  We offer pre and post tech support. We have a proven track
                  record of successful blockchain project development. We're
                  here to elevate your vision, amplify your success, and
                  redefine the future of crypto exchange. Welcome to the
                  top-rated revolution.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="techstack2 stacz">
          <div className="container">
            <h2 className="tech-h2">Blockchain Tech Stacks</h2>
            <div className="stack">
              <p>
                Our team of blockchain developers are experts at building
                innovative blockchain solutions on the best available blockchain
                platform.
              </p>
            </div>
          <TechStackSlider/>
          </div>
        </section>
      </div>

      <FillForm />

      <Footer />
    </>
  );
}
