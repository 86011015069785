import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import CEXDev from "../Components/CEX-Dev";
import FillForm from "../Components/FillForm";
import Contactbar from "../Components/Contactbar";

import { Helmet } from "react-helmet";

export default function CentralizedCex() {
  const [cryptoData, setCryptoData] = useState([])

 
    
  return (
    <>
      <Helmet>
        <title>Paxful Clone Script - BlockIntelligence</title>
        <meta
          property="title"
          content="Premier Centralized Crypto Exchange Development Company - Block Intelligence"
        />
        <meta
          name="description"
          content="Explore the future of cryptocurrency trading with Block Intelligence, a leading Centralized Crypto Exchange Development Company. Explore our advanced solutions and expertise in the crypto exchange arena."
        />
        <meta
          name="keywords"
          content="Paxful clone script, centralized crypto exchange development, peer-to-peer trading platform, cryptocurrency exchange development service"
        />
        <link rel="canonical" href="/" />
      </Helmet>
      <Header />
      <Contactbar />
      <section className="centralized">
        <div className="centralized-cex">
          <div className="cex-linear">
            <div className="hero-content">
              <div className="container">
                <div className="row ">
                  <div className="col-lg-12">
                    <div className="hero-title1">
                      <h1 className="h1tag">
                        <span style={{ color: "gold" }}>Paxful</span> Clone Script
                      </h1>
                      <p>
                        At Block Intelligence, we offer a highly customizable
                        Paxful Clone Script to help you launch your own peer-to-peer
                        cryptocurrency exchange platform, similar to Paxful. Our
                        clone script is designed to offer the same features as Paxful,
                        including secure trading, multi-currency support, and a user-friendly
                        interface. With advanced trading options, your exchange can cater
                        to a wide range of traders.
                      </p>
                      <button>Explore Now</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whatis">
        <div className="whats">
          <div className="container">
            <div className="what">
              <div className="row ">
                <div className="col-lg-4 col-md-5 what-left">
                  <img
                    src={require("../Images/coinnbase.jpeg")}
                    className="whychoosen"
                    alt="Centralized cryptoexchange development company"
                  />
                </div>
                <div className="col-lg-8 col-md-7 what-right">
                  <div className="top">
                    <h2 className="h2-md">What is a Paxful Clone Script?</h2>
                    <p className="p-md">
                      A Paxful Clone Script is a ready-made software solution
                      that replicates the features and functionalities of the
                      Paxful exchange. It allows entrepreneurs to launch their
                      own peer-to-peer cryptocurrency exchange platform with similar
                      capabilities, including secure trading, escrow services, and
                      a user-friendly interface. The Paxful Clone Script is designed
                      to provide high liquidity and robust security features, enabling
                      users to trade various cryptocurrencies seamlessly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     
      <section>
       
      </section>
      <section className="why-bin">
        <div className="container">
          <div className="whys">
            <div className="row ">
              <div className="col-lg-7 col-md-6 whybi-left">
                <div className="top">
                  <h2 className="h2-mobile h2-md">
                    <span style={{ color: "gold" }}>Paxful</span> Clone script 
                  </h2>
                  <p className="p-mobile p-md">
                    Block Intelligence offers a trusted partnership for
                    centralized crypto exchange development and unlock a world
                    of possibilities, security, and success. Experience the
                    difference that our expertise, innovation, and unwavering
                    commitment can make for your trading endeavors. Together,
                    let's shape the future of peer-to-peer crypto exchange!
                  </p>
                </div>
                <div className="why-mobile-center">
                  <div className="row down">
                    <div className="col-lg-6 col-md-6 why-tick-card">
                      <img
                        src={require("../Images/tick.webp")}
                        className="whychoosen"
                        alt="Why Block Intelligence"
                        height="20"
                      />
                      <p className="p-md">Expertise and Innovation</p>
                    </div>
                    <div className="col-lg-6 col-md-6 why-tick-card">
                      <img
                        src={require("../Images/tick.webp")}
                        className="whychoosen"
                        alt="Centralized crypto exchange development company"
                        height="20"
                      />
                      <p className="p-md">Advanced Security Features</p>
                    </div>
                  </div>
                  <div className="row down">
                    <div className="col-lg-6 col-md-6 why-tick-card">
                      <img
                        src={require("../Images/tick.webp")}
                        className="whychoosen"
                        alt="Centralized crypto exchange development service"
                        height="20"
                      />
                      <p className="p-md">Seamless User Experience</p>
                    </div>
                    <div className="col-lg-6 col-md-6 why-tick-card">
                      <img
                        src={require("../Images/tick.webp")}
                        className="whychoosen"
                        alt="Centralized crypto exchange development"
                        height="20"
                      />
                      <p className="p-md">Cutting edge Technology</p>
                    </div>
                  </div>
                  <div className="row down">
                    <div className="col-lg-6 col-md-6 col why-tick-card">
                      <img
                        src={require("../Images/tick.webp")}
                        className="whychoosen"
                        alt="Centralized cryptoexchange development company"
                        height="20"
                      />
                      <p className="p-md">Enhanced Liquidity</p>
                    </div>
                    <div className="col-lg-6 col-md-6 why-tick-card">
                      <img
                        src={require("../Images/tick.webp")}
                        className="whychoosen"
                        alt="Centralized cryptoexchange development company"
                        height="20"
                      />
                      <p className="p-md">Dedicated Support</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 whybi-right">
                <img
                  src={require("../Images/thinking.webp")}
                  className="whychoosen"
                  alt="Centralized cryptoexchange development company"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    
    
    
      <section className="benefits-cex-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-5 bg-img-benefits">
              <img
                src={require("../Images/cex-sec-bene-img.webp")}
                className="whychoosen"
                alt="Centralized crypto exchange development company"
              />
            </div>
            <div className="col-lg-7 col-md-7">
              <div className="key-feature-cex-content p-tag">
                <h2 className="h2-mobile h2-md">
                  Benefits of <br />
                  Paxful Clone script 
                </h2>
                <p className="p-mobile p-md">
                  At Block Intelligence, we bring you the exceptional benefits
                  of centralized crypto exchange development, empowering you to
                  unlock the full potential of the digital asset market. Our
                  expert team combines innovative technology, industry
                  knowledge, and a customer-centric approach to deliver
                  centralized exchanges that are secure, scalable, and
                  feature-rich. Explore the advantages that await you as we
                  delve into the world of Paxful clone development.
                </p>
              </div>
              <div className="benefits-cex">
                <div className="benefits">
                  <i class="ri-checkbox-fill"></i>
                  <p className="p-md">User-friendly interface</p>
                </div>

                <div className="benefits">
                  <i class="ri-checkbox-fill"></i>
                  <p className="p-md">High Liquidity</p>
                </div>

                <div className="benefits">
                  <i class="ri-checkbox-fill"></i>
                  <p className="p-md">
                   Multi-Currency Support:
                  </p>
                </div>

                <div className="benefits">
                  <i class="ri-checkbox-fill"></i>
                  <p className="p-md">Security Protocols</p>
                </div>

                <div className="benefits">
                  <i class="ri-checkbox-fill"></i>
                  <p className="p-md">Customizable Admin Panel</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FillForm />
    
      <Footer />
      
    </>
  );
}
