import React from "react";

const TechStackSlider = () => {
  // Inline styles for the slide track
  const slideTrackStyle = {
    display: 'flex',
    animation: 'scroll 20s linear infinite', // Adjust duration for speed
    whiteSpace: 'nowrap', // Prevent wrapping of slides
  };

  // Keyframes for the animation
  const keyframes = `
    @keyframes scroll {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-50%); /* Move left by half the total width */
      }
    }
  `;

  // Create a style element for keyframes
  const styleSheet = document.createElement("style");
  styleSheet.type = "text/css";
  styleSheet.innerText = keyframes;
  document.head.appendChild(styleSheet);

  // Array of tech stack items
  const techStacks = [
    { src: require("../Images/solidity-logo.webp"), alt: "Solidity", label: "Solidity" },
    
    { src: require("../Images/eth-logo.webp"), alt: "Ethereum", label: "Ethereum" },
    { src: require("../Images/solana-logo.webp"), alt: "Solona", label: "Solona" },
    { src: require("../Images/Elastic-logo.webp"), alt: "Elixir", label: "Elixir" },
    { src: require("../Images/Go_Logo_Blue.svg.png"), alt: "GO", label: "GO" },
    { src: require("../Images/js.png"), alt: "JS", label: "Javascript" },
    { src: require("../Images/rust-programming-language-icon.png"), alt: "Rust", label: "Rust" },
    { src: require("../Images/docker.png"), alt: "docker", label: "Docker" },
  ];

  return (
    <div className="row tech">
      <div className="col-lg-12 techstacks">
        <div className="slider">
          <div className="slide-track" style={slideTrackStyle}>
            {/* ... existing slide items ... */}
            {techStacks.map((item, index) => (
              <div key={index} className="slide">
                <img
                  src={item.src}
                  className="bitcoin"
                  alt={item.alt}
                  style={item.label === "Rust" ? { filter: 'invert(100%)' } : {}} // Apply filter for Rust image
                />
                <p>{item.label}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechStackSlider;
