import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import FillForm from "../Components/FillForm";
import Contactbar from "../Components/Contactbar";
import TechStackSlider from '../Components/TechStackSlider';

import "../Pages/Style.css";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { Helmet } from "react-helmet";

export default function Home() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setIsScrolled(scrollTop > 100);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isScrolled && sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, [isScrolled]);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isScrolled && sliderRef.current) {
        sliderRef.current.slickNext();
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isScrolled]);

  return (
    <>
      <Helmet>
        <title>Opensea clone script</title>
        <meta property="title" content="Opensea clone script" />
        <meta
          name="description"
          content="Launch your own NFT Marketplace like opensea with opensea clone script offered by Block Intelligence. We offer a ready-made and customizable solution of NFT Marketplace development."
        />
        <link
          rel="canonical"
          href="https://www.blockintelligence.io/opensea-clone-script"
        />
      </Helmet>
      <Header />
      <Contactbar />
      <div className="opensea_head">
        <section className="p2p Opensea">
          <div className="container">
            <div className="row pt-5">
              <div className="col-lg-6 mt-5 banner_con simple-video mb-sx-2">
                <h1 className="wow mt-5 fadeInUp text-black h1tag">
                  <span>Graphic Design and Branding</span>
                </h1>
                <p className="wow fadeInUp text-white mt-4">
                  Launch your own Graphic Design and Branding service with Block
                  Intelligence, the leading graphic design and branding company. Our
                  graphic design and branding service allows you to create a
                  customized, secure, and engaging brand experience that
                  empowers clients to stand out in the market. With our
                  expertise, you can develop a user-friendly platform that
                  integrates innovative design solutions, ensuring visibility and
                  impact for all your branding needs. Transform your brand vision
                  into reality with our comprehensive graphic design and branding
                  solutions.{" "}
                </p>
                <div className="p2pbt mt-5">
                  <a href="#contact" className="button">
                    Get Demo
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract whatopensea py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-5 mx-auto d-flex justify-content-center align-items-center">
                <img
                  src={require("../Images/openseaa2.webp")}
                  width="90%"
                  alt="p2p crypto exchange development company"
                />
              </div>
              <div className="col-lg-7 mt-5">
                <div className="p2pcontent">
                  <h2>
                    Why Choose Block Intelligence for{" "}
                    <span>Graphic Design and Branding</span>?
                  </h2>
                  <p>
                    At Block Intelligence, we offer unparalleled expertise in
                    graphic design and branding, combining creativity with a deep understanding of design principles. Our team crafts visually stunning and impactful brand experiences that resonate with your target audience. With a focus on user engagement, advanced design, and brand consistency, we ensure your brand thrives in the competitive market. Partner with us for cutting-edge solutions that drive brand recognition and customer loyalty.
                    <div className="mt-3">
                      <a
                        href="https://wa.me/7738479381"
                        target="_blank"
                        className="button"
                      >
                        Talk with expert
                      </a>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="whatsmartcontract whatopensea py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-7 mt-5">
                <div className="p2pcontent">
                  <h2>
                    What is <span>Graphic Design and Branding</span>?
                  </h2>
                  <p>
                    Graphic Design and Branding is a service that allows businesses to create a visually appealing and memorable brand identity. It involves developing a brand's visual identity, including logo design, color scheme, typography, and other design elements. The goal is to create a consistent and impactful brand experience that resonates with your target audience.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 mx-auto d-flex justify-content-center align-items-center">
                <img
                  src={require("../Images/openseaa3.webp")}
                  width="90%"
                  alt="p2p crypto exchange development company"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="whatsmartcontract whatopensea py-5">
          <div className="container py-5">
            <div className="row">
              <div className="col-lg-12 my-5">
                <div className="p2pcontent">
                  <h2 className="text-center">
                    Benefits of <span>Graphic Design and Branding</span>
                  </h2>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 px-4 my-3">
                  <div className="openseacard">
                    <img
                      src={require("../Images/cards/customize.webp")}
                      className="my-3 mx-auto d-block"
                      width="20%"
                    />
                    <h5> Brand Visibility</h5>
                    <p className="text-center my-3">
                    Graphic Design and Branding help businesses stand out in a crowded market by creating a unique and memorable brand identity.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 px-4 my-3">
                  <div className="openseacard">
                    <img
                      src={require("../Images/cards/customize.webp")}
                      className="my-3 mx-auto d-block"
                      width="20%"
                    />
                    <h5>  Brand Consistency</h5>
                    <p className="text-center my-3">
                    Graphic Design and Branding ensure that your brand's visual identity is consistent across all platforms, creating a cohesive and recognizable brand.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 px-4 my-3">
                  <div className="openseacard">
                    <img
                      src={require("../Images/cards/customize.webp")}
                      className="my-3 mx-auto d-block"
                      width="20%"
                    />

                    <h5>Increased Brand Recognition</h5>
                    <p className="text-center my-3">
                    By creating a strong and memorable brand identity, Graphic Design and Branding help businesses build brand recognition and loyalty.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 px-4 my-3">
                  <div className="openseacard">
                    <img
                      src={require("../Images/cards/customize.webp")}
                      className="my-3 mx-auto d-block"
                      width="20%"
                    />

                    <h5> Global Reach:</h5>
                    <p className="text-center my-3">
                    Graphic Design and Branding services cater to a global audience, particularly in regions with a growing interest in design, expanding market potential
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 px-4 my-3">
                  <div className="openseacard">
                    <img
                      src={require("../Images/cards/customize.webp")}
                      className="my-3 mx-auto d-block"
                      width="20%"
                    />

                    <h5> Community-Driven Growth</h5>
                    <p className="text-center my-3">
                    The community-driven nature of Graphic Design and Branding fosters strong support, with clients contributing to the brand's growth and success through design and marketing initiatives.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 px-4 my-3">
                  <div className="openseacard">
                    <img
                      src={require("../Images/cards/customize.webp")}
                      className="my-3 mx-auto d-block"
                      width="20%"
                    />

                    <h5>Cost-Effectiveness</h5>
                    <p className="text-center my-3">
                      Save development time and costs with a ready-to-deploy
                      solution, accelerating your entry into the market.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

    


       

        <section className="techstack2 stacz">
          <div className="container">
            <h2 className="tech-h2">Blockchain Tech Stacks</h2>
            <div className="stack">
              <p>
                Our team of designers are experts at building
                visually stunning and impactful designs using the best available design tools and platforms.
              </p>
            </div>
            <TechStackSlider />
          </div>
        </section>
      </div>

      <FillForm />

      <Footer />
    </>
  );
}
