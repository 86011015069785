import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Darklogo from "../Images/logo.webp";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  megaMenu: {
    position: "fixed",
    top: theme.spacing(9),
    left: 0,
    width: "100%",
    maxHeight: "calc(100vh - 76px)",
    minHeight: "calc(100vh - 76px)",

    overflowY: "auto",
    backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[2],
    zIndex: 555,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    boxSizing: "border-box",
    "& > div": {
      marginBottom: theme.spacing(2),
    },
  },
  mobileMenuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  mobileMenu: {
    width: 300,
  },
  accordion: {
    marginBottom: theme.spacing(1),
  },
  headerFixed: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 555,
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [headerFixed, setHeaderFixed] = useState(false);

  const handleMenuOpen = (event) => {
    if (anchorEl === event.currentTarget) {
      handleMenuClose();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  const handleDrawerOptionClick = () => {
    setMobileMenuOpen(false);
  };

  const handleScroll = () => {
    const isFixed = window.pageYOffset > 0;
    setHeaderFixed(isFixed);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [activeTab, setActiveTab] = useState("home");

  const handleTabHover = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <div className="header_new_mega">
      <div className={classes.root}>
        <AppBar
          position={headerFixed ? "fixed" : "static"}
          className={`${classes.headerFixed} ${headerFixed ? "customClassName" : ""
            }`}
        >
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              <div className="logo_new_sectio">
                <a href="/" className="logo-brand">
                  <img src={Darklogo} className="img-fluid " alt="logo" />
                </a>
              </div>
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              className={`${classes.mobileMenuButton} mobiele_icon`}
              onClick={handleMobileMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <div className="menu_new_typr">
              <a
                href="https://portfolio.blockintelligence.io/client"
                color="inherit"
                className="contact_button"
                target="_blank"
              >
                Clients
              </a>
              <a
                href="https://portfolio.blockintelligence.io/"
                color="inherit"
                className="contact_button"
                target="_blank"
              >
                Portfolio
              </a>

              <a href="#" className="contact_button" onClick={handleMenuOpen}>
                Services
                <span>
                  <i class="ri-arrow-down-s-fill"></i>
                </span>
              </a>

              <Button color="inherit" className="nav-btn">
                <a href="#contact">Contact Us</a>
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          anchor="left"
          open={mobileMenuOpen}
          onClose={handleMobileMenuClose}
        >
          <div className={classes.mobileMenu}>
            <div className="logo_new_sectio mobile_menu_icon">
              <a href="/" className="logo-brand">
                {" "}
                <img src={Darklogo} className="img-fluid m-3" alt="logo" />
              </a>
            </div>

            <div className="menu_statis">
              <ListItem
                button
                onClick={handleDrawerOptionClick}
                className="accordio_menu_button "
              >
                <a href="https://portfolio.blockintelligence.io/client" target="_blank">Clients</a>
              </ListItem>

              <ListItem
                button
                onClick={handleDrawerOptionClick}
                className="accordio_menu_button "
              >
                <a href="https://portfolio.blockintelligence.io/" target="_blank" >Portfolio</a>
              </ListItem>

              <ListItem
                button
                onClick={handleDrawerOptionClick}
                className="accordio_menu_button "
              >
                <a href="#contact">Contact Us</a>
              </ListItem>
            </div>

            <List>
              <Accordion className={`${classes.accordion} acrdion`}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className="inner_text_accoridion"
                >
                  <p>Crypto Solutions</p>
                </AccordionSummary>
                <AccordionDetails className="display_accodion">
                  <List>
                    <ListItem button className="accordio_menu_button">
                      <a href="/crypto-exchange-development-company">
                        Cryptocurrency Exchange Development
                      </a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="/centralized-crypto-exchange-development-company">
                        Centralized Crypto Exchange Development
                      </a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="/dex-development-company">
                        Decentralized Crypto Exchange Development
                      </a>
                    </ListItem>

                    <ListItem button className="accordio_menu_button">
                      <a href="/p2p-crypto-exchange-development">
                        P2P Crypto Exchange Development
                      </a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="/crypto-paymentgateway-development-company">
                        Crypto Payment Gateway Development
                      </a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="/soon">OTC Trading</a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="#">White label crypto exchange</a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="/crypto-wallet-development-company">Crypto Wallet Development</a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="#">Binance Clone</a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="#">Coinbase Clone</a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="#">Paxful Clone</a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="#">Wazirx Clone</a>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>

              <Accordion className={`${classes.accordion} acrdion`}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className="inner_text_accoridion"
                >
                  <p>Defi Solutions</p>
                </AccordionSummary>
                <AccordionDetails className="display_accodion">
                  <List>
                    <ListItem button className="accordio_menu_button">
                      <a href="/defi-development-company">
                        Defi Development Service
                      </a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="#">Defi Exchaneg Development </a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="#">Defi Staking Platform Development </a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="#">Defi Lending Platform Development </a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="">Pancake Swap Clone Script</a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="/uniswap-clone-script">Uniswap Clone Script</a>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>

              <Accordion className={`${classes.accordion} acrdion`}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className="inner_text_accoridion"
                >
                  <p>NFT marketplace</p>
                </AccordionSummary>
                <AccordionDetails className="display_accodion">
                  <List>
                    <ListItem button className="accordio_menu_button">
                      <a href="/nft-marketplace-development-company">
                        NFT MarketPlace Development
                      </a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="#">NFT Gaming Development</a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="#">
                        Metaverse NFT Development
                      </a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="/opensea-clone-script">OpenSea Clone Script</a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="/rarible-clone-script">Rarible Clone Script</a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="/Betfury">Betfury Clone Script</a>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>

              <Accordion className={`${classes.accordion} acrdion`}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className="inner_text_accoridion"
                >
                  <p>Token Development</p>
                </AccordionSummary>
                <AccordionDetails className="display_accodion">
                  <List>
                    <ListItem button className="accordio_menu_button">
                      <a href="/crypto-token-development">
                        Crypto Token development
                      </a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="/Ico-token">ICO token development</a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="/STO-token">STO</a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="/Ioe-token">IEO</a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="/ERC20-token">ERC20</a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="/BEP20-token">BEP20</a>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
              <Accordion className={`${classes.accordion} acrdion`}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className="inner_text_accoridion"
                >
                  <p>Web3 Solutions</p>
                </AccordionSummary>
                <AccordionDetails className="display_accodion">
                  <List>
                    <ListItem button className="accordio_menu_button">
                      <a href="/web3-game-development-company">Web3 Development</a>
                    </ListItem>

                    <ListItem button className="accordio_menu_button">
                      <a href="/metaverse-development-company">
                        Web3 Game Development
                      </a>
                    </ListItem>

                    <ListItem button className="accordio_menu_button">
                      <a href="/metaverse-development-company">
                        Metaverse Development
                      </a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="/metaverse-development-company">
                        Blockchain Development Company
                      </a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="/Layer-0-Blockchain-Development-Company">
                       Layer0  Blockchain Development 
                      </a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="/Layer-1-Blockchain-Development-Company">
                       Layer1  Blockchain Development 
                      </a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="/Layer-2-Blockchain-Development-Company">
                       Layer2  Blockchain Development 
                      </a>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
              <Accordion className={`${classes.accordion} acrdion`}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className="inner_text_accoridion"
                >
                  <p>Other Services</p>
                </AccordionSummary>
                <AccordionDetails className="display_accodion">
                  <List>
                    <ListItem button className="accordio_menu_button">
                      <a href="/crypto-trading-bot-development">
                        Crypto Trading Bot Development
                      </a>
                    </ListItem>

                    <ListItem button className="accordio_menu_button">
                      <a href="/smart-contract-audit-company">
                        Smart Contract Auditing
                      </a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="/crypto-token-development">Crypto Token Listing</a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="/Blockchainn-Market">Blockchain marketing</a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="/Graphic-design">Graphic design and branding</a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="Incubation & Fundin">Incubation and vc funding</a>
                    </ListItem>
                    <ListItem button className="accordio_menu_button">
                      <a href="#">PR service</a>
                    </ListItem>
                    
                    <ListItem button className="accordio_menu_button">
                      <a href="/P2E-games">P2E Game Development</a>
                    </ListItem>
                  </List>
                </AccordionDetails>
              </Accordion>
            </List>
          </div>
        </Drawer>
        {anchorEl && (
          <div className={classes.megaMenu} onMouseLeave={handleMenuClose}>
            <div className="container-fluid">
              <div>
                <div className="row">
                  <div className="col-lg-3">
                    <ul className="nav nav-tabs header_menu1">
                      <li
                        className={activeTab === "home" ? "active" : ""}
                        onMouseEnter={() => handleTabHover("home")}
                      >
                        <a data-toggle="tab" href="#home">
                          Crypto Solutions{" "}
                          <span>
                            <i class="ri-arrow-right-s-fill"></i>
                          </span>
                        </a>
                      </li>

                      <li
                        className={activeTab === "menu1" ? "active" : ""}
                        onMouseEnter={() => handleTabHover("menu1")}
                      >
                        <a data-toggle="tab" href="#menu1">
                          NFT Marketplace{" "}
                          <span>
                            <i class="ri-arrow-right-s-fill"></i>
                          </span>
                        </a>
                      </li>
                      {/* <li
                        className={activeTab === "menu7" ? "active" : ""}
                        onMouseEnter={() => handleTabHover("menu7")}
                      >
                        <a data-toggle="tab" href="#menu7">
                          Clone Script
                          <span>
                            <i class="ri-arrow-right-s-fill"></i>
                          </span>
                        </a>
                      </li> */}
                      <li
                        className={activeTab === "menu3" ? "active" : ""}
                        onMouseEnter={() => handleTabHover("menu3")}
                      >
                        <a data-toggle="tab" href="#menu3">
                          Defi Solutions
                          <span>
                            <i class="ri-arrow-right-s-fill"></i>
                          </span>
                        </a>
                      </li>
                      {/* <li
                        className={activeTab === "menu4" ? "active" : ""}
                        onMouseEnter={() => handleTabHover("menu4")}
                      >
                        <a data-toggle="tab" href="#menu4">
                          Blockchain Solutions
                          <span>
                            <i class="ri-arrow-right-s-fill"></i>
                          </span>
                        </a>
                      </li> */}

                      <li
                        className={activeTab === "menu2" ? "active" : ""}
                        onMouseEnter={() => handleTabHover("menu2")}
                      >
                        <a data-toggle="tab" href="#menu2">
                          Token Development{" "}
                          <span>
                            <i class="ri-arrow-right-s-fill"></i>
                          </span>
                        </a>
                      </li>

                      <li
                        className={activeTab === "menu6" ? "active" : ""}
                        onMouseEnter={() => handleTabHover("menu6")}
                      >
                        <a data-toggle="tab" href="#menu6">
                          Web3 Solutions
                          <span>
                            <i class="ri-arrow-right-s-fill"></i>
                          </span>
                        </a>
                      </li>
                      <li
                        className={activeTab === "menu5" ? "active" : ""}
                        onMouseEnter={() => handleTabHover("menu5")}
                      >
                        <a data-toggle="tab" href="#menu5">
                          Other Services
                          <span>
                            <i class="ri-arrow-right-s-fill"></i>
                          </span>
                        </a>
                      </li>
                      {/* <li
                        className={activeTab === "AI" ? "active" : ""}
                        onMouseEnter={() => handleTabHover("AI")}
                      >
                        <a data-toggle="tab" href="#AI">
                          AI and ML Solutions{" "}
                          <span>
                            <i class="ri-arrow-right-s-fill"></i>
                          </span>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                  <div className="col-lg-9">
                    <div className="tab-content">
                      <div
                        id="AI"
                        className={`tab-pane fade ${activeTab === "AI" ? "in active show" : ""
                          }`}
                      >
                        <div className="row">
                          <div className="col-lg-3">
                            <a href="/soon" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/ai.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>AI And ML development solutions</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/soon" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/Businessforcasting.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Business Forecasting</p>
                              </div>
                            </a>
                          </div>
                          {/* <div className="col-lg-3">
                            <a
                              href="/soon"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/Intelligentdocument.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Intelligent Document Processing</p>
                              </div>
                            </a>
                          </div> */}
                          <div className="col-lg-3">
                            <a href="/soon" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/MarketingAutomation.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Marketing Automation</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/soon" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/WorkerSafetyDetection.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Worker Safety Detection</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/soon" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/Productcounting.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Product Counting</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/soon" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/VehicleTracking.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Vehicle Tracking</p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* <div
                        id="Listing"
                        className={`tab-pane fade ${activeTab === "Listing" ? "in active show" : ""
                          }`}
                      >
                        <div className="row">
                          <div className="col-lg-3">
                            <a
                              href="/soon"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                              <img
                                  src={require("../Images/icons/cryptolisting.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>  Crypto Listing Agency</p>
                              </div>
                            </a>
                          </div>

                        </div>
                      </div> */}
                      {/* <div
                        id="Consulting"
                        className={`tab-pane fade ${activeTab === "Consulting" ? "in active show" : ""
                          }`}
                      >
                        <div className="row">
                          <div className="col-lg-3">
                            <a
                              href="/soon"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                              <img
                                  src={require("../Images/icons/vara.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>VARA License Consultant</p>
                              </div>
                            </a>
                          </div>

                        </div>
                      </div> */}
                      <div
                        id="home"
                        className={`tab-pane fade ${activeTab === "home" ? "in active show" : ""
                          }`}
                      >
                        <div className="row">
                          <div className="col-lg-3">
                            <a
                              href="/crypto-exchange-development-company"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/crypto-ex-dev.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Crypto Exchange Development</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a
                              href="/centralized-crypto-exchange-development-company"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/cex.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>
                                  Centralized Cryptocurrency Exchange
                                  Development
                                </p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a
                              href="/dex-development-company"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/dex.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Decentralized Crypto Exchange Development</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a
                              href="/p2p-crypto-exchange-development"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/p2p.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>P2P Crypto Exchange Development</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a
                              href="/crypto-paymentgateway-development-company"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/paymentgateway.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Crypto Payment Gateway Development</p>
                              </div>
                            </a>
                          </div>
                          {/* <div className="col-lg-3">
                            <a
                              href="/soon"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                              <img
                                  src={require("../Images/icons/cex-clone.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Cryptocurrency Exchange Clone Development</p>
                              </div>
                            </a>
                          </div> */}
                          {/* <div className="col-lg-3">
                            <a
                              href="/soon"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                              <img
                                  src={require("../Images/icons/lanchpad.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Crypto Launchpad Development</p>
                              </div>
                            </a>
                          </div> */}
                          {/* <div className="col-lg-3">
                            <a
                              href="/soon"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                              <img
                                  src={require("../Images/icons/crypto-mlm.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Cryptocurrency MLM Software Development</p>
                              </div>
                            </a>
                          </div> */}
                          <div className="col-lg-3">
                            <a href="/Otc-development" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/otc.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>OTC Platform Development</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a
                              href="/crypto-wallet-development-company"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/Group 3.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Crypto Wallet Development</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/White-crypto" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/whitelab.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>White label Crypto Exchange</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/Binance-clone-script" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/binance.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Binance Clone Script</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/coinbase-clone-script" className="header_menu coinbase-link">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/coinbase.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Coinbase Clone script development</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/Paxful-clone-script" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/paxful.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Paxful Clone Script Development</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/wazirx-clone-script" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/wazirx.png")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>WazirX Clone Script Development</p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        id="menu1"
                        className={`tab-pane fade ${activeTab === "menu1" ? "in active show" : ""
                          }`}
                      >
                        <div className="row">
                          <div className="col-lg-3">
                            <a
                              href="/nft-marketplace-development-company"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/nftmarket.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>NFT MarketPlace Development</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/nft-games" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/nftgame.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>NFT Gaming Development </p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/Metaverse" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/metaverse.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Metaverse NFT Development </p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a
                              href="/opensea-clone-script"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/opensea.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>OpenSea Clone Script</p>
                              </div>
                            </a>
                          </div>

                          <div className="col-lg-3">
                            <a
                              href="/rarible-clone-script"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/rarible.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Rarible Clone Script</p>
                              </div>
                            </a>
                          </div>

                          <div className="col-lg-3">
                            <a href="/Betfury" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/betfury.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Betfury Clone Script Development</p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        id="menu2"
                        className={`tab-pane fade ${activeTab === "menu2" ? "in active show" : ""
                          }`}
                      >
                        <div className="row">
                          <div className="col-lg-3">
                            <a
                              href="/crypto-token-development"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/tokendev.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Crypto Token Development </p>
                              </div>
                            </a>
                          </div>

                          <div className="col-lg-3">
                            <a href="/Ico-token" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/ico.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>ICO Token Development</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="#" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/ieo.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>IEO Token Development</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/STO-token" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/sto.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>STO Token Development </p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/BEP20-token" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/centralbank.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>BEP - 20 </p>
                              </div>
                            </a>
                          </div>

                          <div className="col-lg-3">
                            <a href="/ERC20-token" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/stable.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p> ERC - 20</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/Memecoin development" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/memecoin.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Meme Coin Development Company </p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        id="menu3"
                        className={`tab-pane fade ${activeTab === "menu3" ? "in active show" : ""
                          }`}
                      >
                        <div className="row">
                          <div className="col-lg-3">
                            <a
                              href="/defi-development-company"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/defi.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Defi Development Service </p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/defi-exchange" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/defi.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>DeFi Exchange development </p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/defi-stacking" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/staking.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Defi Staking Platform Development</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/defi-lending" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/otc.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Defi Lending Platform Development</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/Pancakeswap-clone-script" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/pancake.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Pancake Swap Clone Script</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/uniswap-clone-script" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/pancake.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Uniswap Clone Script</p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      {/* <div
                        id="menu4"
                        className={`tab-pane fade ${activeTab === "menu4" ? "in active show" : ""
                          }`}
                      >
                        <div className="row">
                          <div className="col-lg-3">
                            <a
                              href="/soon"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                              <img
                                  src={require("../Images/icons/co2.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>
                                  Blockchain Based Carbon Credit Platform
                                  Development
                                </p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a
                              href="/soon"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                              <img
                                  src={require("../Images/icons/dapp.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>dApp Development Company</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a
                              href="/soon"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                              <img
                                  src={require("../Images/icons/minning.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Bitcoin Mining Software Development</p>
                              </div>
                            </a>
                          </div>

                          <div className="col-lg-3">
                            <a
                              href="/soon"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                              <img
                                  src={require("../Images/icons/ownblockchain.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Own Blockchain Development </p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a
                              href="/game-development-company"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                              <img
                                  src={require("../Images/icons/gaming.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Blockchain Game Development Company </p>
                              </div>
                            </a>
                          </div>

                         
                          <div className="col-lg-3">
                            <a
                              href="/soon"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                              <img
                                  src={require("../Images/icons/dao.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>DAO Development Company</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a
                              href="/soon"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                              <img
                                  src={require("../Images/icons/cardona.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Cardano Blockchain Development Company</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a
                              href="/soon"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                              <img
                                  src={require("../Images/icons/socialmedia.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>
                                  Blockchain Social Media Platform Development
                                </p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div> */}
                      <div
                        id="menu5"
                        className={`tab-pane fade ${activeTab === "menu5" ? "in active show" : ""
                          }`}
                      >
                        <div className="row">
                          <div className="col-lg-3">
                            <a
                              href="/crypto-trading-bot-development"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/arbitrage.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Crypto Trading Bot Development</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a
                              href="/smart-contract-audit-company"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/smartcontract.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Smart Contract Audit</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/Crypto-list" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/cryptolisting.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p> Crypto Listing Agency</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/Blockchainn-Market" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/socialmedia.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Blockchain Marketing</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/Graphic-design" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/metaverse.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Graphic design and branding</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/Incubation & Funding" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/ownblockchain.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Incubation and vc funding</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a href="/P2E-games" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/gaming.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>P2E Gaming Development</p>
                              </div>
                            </a>
                          </div>

                          {/* <div className="col-lg-3">
                            <a
                              href="/soon"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                              <img
                                  src={require("../Images/icons/otc-1.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>OTC Platform Development</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a
                              href="/soon"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                              <img
                                  src={require("../Images/icons/routing.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Smart Order Routing Development</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a
                              href="/soon"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                              <img
                                  src={require("../Images/icons/marketmaking.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Crypto Market Making Development</p>
                              </div>
                            </a>
                          </div> */}
                        </div>
                      </div>
                      <div
                        id="menu6"
                        className={`tab-pane fade ${activeTab === "menu6" ? "in active show" : ""
                          }`}
                      >
                        <div className="row">
                          <div className="col-lg-3">
                            <a href="/Web3-development" className="header_menu">
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/web3.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Web3 Development</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a
                              href="/web3-game-development-company"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/nftgame.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Web3 Game Development</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a
                              href="/metaverse-development-company"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/metaverse.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Metaverse Development Company</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a
                              href="/Layer-0-Blockchain-Development-Company
"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/smartcontract.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Layer 0 Blockchain Development
</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a
                              href="/Layer-1-Blockchain-Development-Company
"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/smartcontract.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Layer 1 Blockchain Development
</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a
                              href="/Layer-2-Blockchain-Development-Company
"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/smartcontract.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p>Layer 2 Blockchain Development
</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-3">
                            <a
                              href="/Blockchain-Development-Company
"
                              className="header_menu"
                            >
                              <div className="image_icon_link">
                                <img
                                  src={require("../Images/icons/smartcontract.webp")}
                                  className="icon_serveice"
                                  alt="Launchpad platform Development"
                                />
                              </div>
                              <div className="title_header_menu">
                                <p> Blockchain Development Company
</p>
                              </div>
                            </a>
                          </div>
                         
                         
                        </div>
                      </div>
                      <div
                        id="menu7"
                        className={`tab-pane fade ${activeTab === "menu7" ? "in active show" : ""
                          }`}
                      >
                        <div className="row"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
